import React from 'react'
import './UserList.css'
import { Space, Table, Tag,Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const UserList = () => {

    const navigate = useNavigate();

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
            align: 'center'
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
            align: 'center'
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            align: 'center'
        },
        {
            title: 'Tags',
            key: 'tags',
            dataIndex: 'tags',
            align: 'center',
            render: (_, { tags }) => (
                <>
                    {tags.map((tag) => {
                        let color = tag.length > 5 ? 'geekblue' : 'green';
                        if (tag === 'loser') {
                            color = 'volcano';
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
    ];
    const data = [
        {
            userId : 1,
            key: '1',
            name: 'John Brown',
            age: 32,
            address: 'New York No. 1 Lake Park',
            tags: ['nice', 'developer'],
        },
        {
            userId : 2,
            key: '2',
            name: 'Jim Green',
            age: 42,
            address: 'London No. 1 Lake Park',
            tags: ['loser'],
        },
        {
            userId : 3,
            key: '3',
            name: 'Joe Black',
            age: 32,
            address: 'Sydney No. 1 Lake Park',
            tags: ['cool', 'teacher'],
        },
    ];


    const navigateAnalytic = (record) => {
        const userId = record.userId;
        console.log(userId, 'userId')
        navigate(`/analytics/${userId}`)
    }

  return (
    <div className='userListMainDiv'>
        <h2>Analytics - Users</h2>
        <Table
        onRow={( record ) => ({
           onClick:() => navigateAnalytic(record) 
        })}
        columns={columns} dataSource={data} />
    </div>
  )
}

export default UserList