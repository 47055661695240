// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userReminderClass{
    display: flex;
}
.userReminderClass p{
    margin: 0 0 0 8px;
    font-size: 15px;
    font-weight: 500;
}
.setReminderDiv{
    border: 1px solid #bfbfbf;
    border-radius: 10px;
    padding: 0px 0px 14px 18px;
    margin-top: 10px;
}
.addTrainingModal .ant-modal-content{
    height: 77vh;
    overflow-y: scroll;
}
.addTrainingModal ::-webkit-scrollbar{
    width: 5px;
    background: transparent;
}
.addTrainingModal ::-webkit-scrollbar-thumb {
    background-color: #1677ff;
    border-radius: 8px;
}
.addTrainingModal .ant-modal-header{
    border-bottom: 1px solid #bfbfbf;
}
.requiredStar{
    color: red;
}
.ModalButtons{
    display: flex;
    margin: 20px 0px;
    justify-content: flex-end
}
.updateModalButton{
    margin-left: 10px;
}
.updateModalButton {
    margin-left: 10px;
    background: #1677ff;
    color: #fff;
    border: 1px solid #1677ff;
}
.endDateCardClass{
    margin: 8px 0 0 0;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/TrainingModule/TrainingModule.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,0BAA0B;IAC1B,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,uBAAuB;AAC3B;AACA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,gCAAgC;AACpC;AACA;IACI,UAAU;AACd;AACA;IACI,aAAa;IACb,gBAAgB;IAChB;AACJ;AACA;IACI,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;IACX,yBAAyB;AAC7B;AACA;IACI,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".userReminderClass{\n    display: flex;\n}\n.userReminderClass p{\n    margin: 0 0 0 8px;\n    font-size: 15px;\n    font-weight: 500;\n}\n.setReminderDiv{\n    border: 1px solid #bfbfbf;\n    border-radius: 10px;\n    padding: 0px 0px 14px 18px;\n    margin-top: 10px;\n}\n.addTrainingModal .ant-modal-content{\n    height: 77vh;\n    overflow-y: scroll;\n}\n.addTrainingModal ::-webkit-scrollbar{\n    width: 5px;\n    background: transparent;\n}\n.addTrainingModal ::-webkit-scrollbar-thumb {\n    background-color: #1677ff;\n    border-radius: 8px;\n}\n.addTrainingModal .ant-modal-header{\n    border-bottom: 1px solid #bfbfbf;\n}\n.requiredStar{\n    color: red;\n}\n.ModalButtons{\n    display: flex;\n    margin: 20px 0px;\n    justify-content: flex-end\n}\n.updateModalButton{\n    margin-left: 10px;\n}\n.updateModalButton {\n    margin-left: 10px;\n    background: #1677ff;\n    color: #fff;\n    border: 1px solid #1677ff;\n}\n.endDateCardClass{\n    margin: 8px 0 0 0;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
