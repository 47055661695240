// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Users-main{
    margin-top: 30px;
    padding: 25px;
}
.Edit{
    cursor: pointer;
    font-size: 20px;
}
.EditInput{
    margin-bottom: 20px;
    height: 50px;
    width: 100%;
    /* margin-left: 50px; */
}
.NameTxt{
    text-transform: capitalize; 
}
.Title{
    /* margin-left: 50px; */
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 12px;
}

.custom-row {
    height: 20px !important; 
}
.searchInput{
    height: 36px;
    padding-left: 22px;
    border-radius: 6px;
}
.inputSearch{
    float: right;
    position: absolute;
    top: 9px;
    right: 14px;
    font-size: 16px;
    color: #949494;
}`, "",{"version":3,"sources":["webpack://./src/Components/Masters/Users/Users.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;AACjB;AACA;IACI,eAAe;IACf,eAAe;AACnB;AACA;IACI,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,uBAAuB;AAC3B;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,eAAe;IACf,cAAc;AAClB","sourcesContent":[".Users-main{\n    margin-top: 30px;\n    padding: 25px;\n}\n.Edit{\n    cursor: pointer;\n    font-size: 20px;\n}\n.EditInput{\n    margin-bottom: 20px;\n    height: 50px;\n    width: 100%;\n    /* margin-left: 50px; */\n}\n.NameTxt{\n    text-transform: capitalize; \n}\n.Title{\n    /* margin-left: 50px; */\n    margin-top: 0px;\n    margin-bottom: 0px;\n    font-size: 12px;\n}\n\n.custom-row {\n    height: 20px !important; \n}\n.searchInput{\n    height: 36px;\n    padding-left: 22px;\n    border-radius: 6px;\n}\n.inputSearch{\n    float: right;\n    position: absolute;\n    top: 9px;\n    right: 14px;\n    font-size: 16px;\n    color: #949494;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
