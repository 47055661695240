import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Select,Pagination } from 'antd'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BaseUrl } from '../Constants/Constants';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const TrainingMapping = () => {

    const { Meta } = Card;

    const navigate = useNavigate();

    const [course, setCourse] = useState([]);
    const [transformedData, settransformedData] = useState([])
    const [Title, setTitle] = useState('');
    const [selectedValue, setSelectedValue] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(12);
    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
      };
      
      useEffect(() => {
          axios.get(`${BaseUrl}trainings/getAllTraining`)
          .then((res) => {
              console.log(res.data.data, 'unAssign');
              setCourse(res.data.data)
              settransformedData(res.data.data)
              setTitle("All")
            })
            .catch((err) => {
                console.log(err.message)
            })
        }, [])
        const paginatedCourses = transformedData.slice((currentPage - 1) * pageSize, currentPage * pageSize);  

    let type = selectedValue.toLocaleLowerCase();
    console.log(type, 'selectedValue')
    const handleCard = (e) => {


        console.log(e, 'trainingCardDetails')
        navigate(`/assigntrainings/${type}/${e.training_id}`)
    }

    const handleChange = (e) => {
        console.log(e)
        if (e == "Unassigned") {
            setTitle(e)
            let arr = []

            let mappedcourse = course.map((i) => {

                if (i.team_id != null && i.team_id != "") {
                } else {
                    let obj = {

                        course_img: i.course_img,
                        course_title: i.course_title,
                        course_id: i.course_id
                    }
                    arr.push(obj)

                }
                settransformedData(arr)
                // console.log(mappedcourse,"mapp")
                console.log(arr, "array")
            })
        }
        if (e == "Assigned") {
            setTitle(e)
            let arr = []

            let mappedcourse = course.map((i) => {

                if (i.team_id != null && i.team_id != "") {
                    let obj = {

                        course_img: i.training_img,
                        course_title: i.training_title,
                        course_id: i.training_id
                    }
                    arr.push(obj)

                }
                settransformedData(arr)
                console.log(arr, "array")
            })
        }
        if (e == "All") {
            setTitle(e)
            settransformedData(course)
        }
    }




    return (
        <div className='courseMappingMain'style={{ padding: "25px 25px 25px 110px"}}>
            <p>
                No of Trainings : {transformedData.length}
            </p>
            <h2>{Title} <></>Trainings</h2>
            <Row className="manageChatbotRow">




                {transformedData.length != 0 ? (
                    <>
                        {paginatedCourses.map((item) => {

                            return (
                                <div className="Course-card">
                                    <Col span={6} offset={2} className="manageBotCardCol">
                                        <Card
                                            onClick={() => handleCard(item)}
                                            hoverable
                                            className="cards"
                                            style={{
                                                width: 200,
                                                padding: 2,
                                            }}
                                        // cover={


                                        // }
                                        >

                                            <Meta
                                                title={
                                                    item.training_title != "" || item.training_title === undefined
                                                        ? item.training_title
                                                        : `Training`
                                                }
                                            />
                                            <img
                                                alt="Course Image"
                                                src={item.training_img === '' || item.training_img === null ? "icons/CourseIcons.png" : item.training_img}
                                                height={130}
                                                style={{ width: "100%" }}
                                            />

                                        </Card>
                                    </Col>
                                </div>
                            );
                        })}
                    </>
                ) : (
                    <h3>No items</h3>
                )}
            </Row>
            <Pagination
              onChange={handlePaginationChange}
              current={currentPage}
              pageSize={pageSize}
              showSizeChanger={false}
              total={transformedData.length}
              style={{ position: 'absolute', bottom: '0px', left: '45%' }}
            />
        </div>
    )
}

export default TrainingMapping