import { Button, Col, DatePicker, Input, Row, Table } from 'antd';
import moment from 'moment';
import React, { useState } from 'react'
import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom'

const { RangePicker } = DatePicker;

const dataSource = [
    { key: '1', username: 'John Brown', score: 32, address: 'New York No. 1 Lake Park' },
    { key: '2', username: 'Jim Green', score: 42, address: 'London No. 1 Lake Park' },
    { key: '3', username: 'Joe Black', score: 32, address: 'Sidney No. 1 Lake Park' },
    { key: '4', username: 'Prasanth Black Forest', score: 32, address: 'Sidney No. 1 Lake Park' },
    { key: '5', username: 'Sarath White Forest', score: 32, address: 'Sidney No. 1 Lake Park' },
    { key: '6', username: 'Srini Black', score: 32, address: 'Sidney No. 1 Lake Park' },
];

const UserReport = () => {

    const [betweenDates, setBetweenDates] = useState([]);
    const [userData, setUserData] = useState([]);
    const [searchMail, setSearchMail] = useState('');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [filteredData, setFilteredData] = useState(dataSource);

    const navigate = useNavigate();

    const columns = [
        {
            title: 'S.No',
            dataIndex: 'key',
            key: 'key',
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'username',
            key: 'username',
            align: 'center',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            align: 'center',
        },
        // {
        //   title: 'Email',
        //   dataIndex: 'email',
        //   key: 'email',
        //   align: 'center',
        // },
        // {
        //   title: 'No. of Questions',
        //   dataIndex: 'no_of_question',
        //   key: 'no_of_question',
        //   align: 'center',
        // },
        // {
        //   title: 'Score',
        //   dataIndex: 'score',
        //   key: 'score',
        //   align: 'center',
        //   sorter: (a, b) => a.score - b.score,
        // },
        // {
        //   title: 'Course Title',
        //   dataIndex: 'course_title',
        //   key: 'course_title',
        //   align: 'center',
        // },
        // {
        //   title: 'Date',
        //   dataIndex: 'date',
        //   key: 'date',
        //   align: 'center',
        //   sorter: (a, b) => new Date(a.date) - new Date(b.date),
        // },
    ];

    

    const hasSelected = selectedRowKeys.length > 0;

    // const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    //     const mergedRows = [...selectedRowKeys];
    
    //     newSelectedRows.forEach((row) => {
    //       if (!mergedRows.some((selectedRow) => selectedRow.key === row.key)) {
    //         mergedRows.push(row);
    //       }
    //     });
    
    //     setSelectedRowKeys(mergedRows);
    //   };

    const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
        // Create a new array that merges the previous and new selections
        let mergedKeys = [...selectedRowKeys, ...newSelectedRowKeys];
        
        // Remove duplicates
        mergedKeys = mergedKeys.filter((key, index) => mergedKeys.indexOf(key) === index);
    
        // Filter out the keys that were deselected
        const finalSelectedKeys = mergedKeys.filter(key => newSelectedRowKeys.includes(key) || !selectedRowKeys.includes(key));
    
        setSelectedRowKeys(finalSelectedKeys);
      };

    // const onSelectChange = (newSelectedRowKeys) => {
    //     // Merge the previously selected keys with the newly selected ones, removing duplicates
    //     const mergedKeys = Array.from(new Set([...selectedRowKeys, ...newSelectedRowKeys]));
    
    //     // Remove the keys that are deselected
    //     const finalSelectedKeys = mergedKeys.filter(key => newSelectedRowKeys.includes(key));
    
    //     setSelectedRowKeys(finalSelectedKeys);
    //   };
    
    
      const rowSelection = {
        onChange: onSelectChange,
      };
      
      console.log(filteredData, '<--------filteredData', selectedRowKeys)


    const handleSearch = (value) => {
        const filtered = dataSource.filter(item =>
          Object.keys(item).some(key =>
            String(item[key]).toLowerCase().includes(value.toLowerCase())
          )
        );
        setFilteredData(filtered);
        setSearchMail(value);
      };


      const downloadData = selectedRowKeys.map(key => {
        return dataSource.find(item => item.key === key);
      });

    const getDatesBetween = (startDate, endDate) => {
        let dates = [];
        let currentDate = moment(startDate);
        const end = moment(endDate);

        while (currentDate <= end) {
            dates.push(currentDate.format('YYYY-MM-DD'));
            currentDate = currentDate.add(1, 'days');
        }

        return dates;
    };

    const handleDateRangeChange = (dates, dateString) => {
        const [startDate, endDate] = dateString;
        const datesBetween = getDatesBetween(startDate, endDate);
        // console.log(datesBetween, 'dateString')
        setBetweenDates(datesBetween);
    };

    const handleDownload = () => {
        const columnNames = ['Course Title', 'Username', 'Email', 'No of Questions', 'Score', 'Date'];
        // const csvContent = "data:text/csv;charset=utf-8," +
        //   userData.map(row => Object.values(row).join(",")).join("\n");
        const csvContent = `data:text/csv;charset=utf-8,${[columnNames.join(',')].concat(userData.map(row => Object.values(row).join(","))).join("\n")}`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Reports.csv");
        document.body.appendChild(link);
        link.click();
    };

    const handleRoute = (e) => {
        navigate(e)
    }

    return (
        <div className='scoresMainDiv'>
            <div className='reportsSideHeading'>
                <h4 className='nonActiveHeading' onClick={() => handleRoute('/coursesactivityreports')} >Courses Activity Report</h4>
                <h4 className='nonActiveHeading' onClick={() => handleRoute('/trainingreports')} >Training Report</h4>
                <h4 className='nonActiveHeading'
                //  onClick={() => handleRoute('/trainingnoncompletionreports')}
                  >
                    Training Non-compliance Report</h4>
                <h4 className='activeHeading' style={{cursor:'not-allowed'}}
                //  onClick={() => handleRoute('/userreports')}
                  >User Report</h4>
                <h4 className='nonActiveHeading' onClick={() => handleRoute('/reports')} >Courses Report</h4>
            </div>
            <h2>User Reports</h2>
            <Row style={{ marginBottom: 10 }}>
                <Col span={6} offset={5} >
                    <RangePicker style={{ height: '36px' }} onChange={handleDateRangeChange} />
                </Col>
                <Col span={8} offset={1}>
                    <Input placeholder='Search by name' className='searchInput'
                        // onChange={(e) => setSearchMail(e.target.value)}
                        onChange={(e) => handleSearch(e.target.value) }
                    />
                </Col>
                <Col offset={1} span={3}>
                    <Button style={{ width: '100%' }} type="primary" 
                    onClick={handleDownload}
                    >
                        Download Report
                    {/* <CSVLink data={downloadData} filename={"selected_data.csv"}>
          Download Selected
        </CSVLink> */}
                    </Button>
                </Col>
            </Row>
            <p
                style={{
                    marginLeft: 8,
                    marginTop: 23
                }}
            >
                {hasSelected ? `(Selected ${filteredData.length} user)` : ''}
            </p>
            <Table
                columns={columns}
                dataSource={filteredData}
                rowSelection={rowSelection}
                pagination={{
                    position: ['bottomCenter'],
                    showSizeChanger: false,
                  }} 
            />
        </div>
    )
}

export default UserReport