// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NameTxtTeam{
    color: #1677FF;
    cursor: pointer;
}
.back{
    color: #1677FF;
    cursor: pointer; 
    text-decoration: underline;
}
.Loader{
    display: flex;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/Masters/Teams/Teams.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,cAAc;IACd,eAAe;IACf,0BAA0B;AAC9B;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".NameTxtTeam{\n    color: #1677FF;\n    cursor: pointer;\n}\n.back{\n    color: #1677FF;\n    cursor: pointer; \n    text-decoration: underline;\n}\n.Loader{\n    display: flex;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
