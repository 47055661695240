import React, { useState, useEffect } from 'react';
import { Card, Input, Button, Upload, message, Modal, Progress } from 'antd';
import { PlusOutlined, DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { AssesmentUrl, BaseUrl, REACT_APP_ACESS_KEY_ID, REACT_APP_SECRET_ACESS_KEY } from '../../Constants/Constants';
import { EditOutlined } from '@mui/icons-material';
import AWS from 'aws-sdk';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const { Dragger } = Upload;

const TrainingEditor = () => {


  const profileData = JSON.parse(localStorage.getItem('Profile'));
  const userEmail = profileData.email;
  console.log(userEmail, 'profileData');

  const [parts, setParts] = useState([
    {
      id: 1,
      name: '',
      number: 'Part 1',
      modules: [
        {
          id: 1,
          name: 'Module 1',
          trainingModule: "",
          trainingModuleType: "",
          trainingModuleDuration: ""
        },
      ],
    },
  ]);

  const [courseName, setCourseName] = useState("");
  const [titleModal, setTitleModal] = useState(false);
  const [updateCourse, setUpdateCourse] = useState('');
  const [arrayFiles, setarrayFiles] = useState([]);
  const [file, setfile] = useState([]);
  const [Percent, setPercent] = useState('0');
  const [vectorError, setVectorError] = useState("");
  const [loading, setLoading] = useState(false);
  const [AssesmentUrls, setAssesmentUrls] = useState({});
  const [session_id, setSessionId] = useState("")

  const { trainingId } = useParams();


  const navigate = useNavigate();
  useEffect(() => {

    let body = {
      trainingId: trainingId
    }

    axios.post(`${BaseUrl}trainings/findOneTraining`, body)
      .then((res) => {
        console.log(res.data, 'findOneResponse');
        if (res.data.data.length != 0) {
          setCourseName(res.data.data[0].training_title);
        }
      })
      .catch((err) => {
        console.log(err.message, 'findOne API Error')
      })
  }, [])



  const addPart = () => {
    setParts((prevParts) => [
      ...prevParts,
      { id: prevParts.length + 1, number: `Part ${prevParts.length + 1}`, modules: [] },
    ]);
  };

  const addModule = (partId) => {
    // Find the part with the specified id
    const targetPart = parts.find((part) => part.id === partId);

    if (targetPart) {
      // Increment the module count within the target part
      const moduleCount = targetPart.modules.length + 1;

      // Add a new module to the specified part with the incremented count
      const updatedParts = parts.map((part) =>
        part.id === partId
          ? {
            ...part,
            modules: [...part.modules, { id: moduleCount, name: '', trainingModule: "", trainingModuleType: "", trainingModuleDuration: "" }],
          }
          : part
      );

      setParts(updatedParts);
    }
  };

  const deletePart = (partId) => {
    // Delete the specified part
    const updatedParts = parts.filter((part) => part.id !== partId);
    setParts(updatedParts);
  };

  const deleteModule = (partId, moduleId) => {
    // Delete the specified module from the specified part
    const updatedParts = parts.map((part) =>
      part.id === partId
        ? {
          ...part,
          modules: part.modules.filter((module) => module.id !== moduleId),
        }
        : part
    );
    setParts(updatedParts);
  };


  AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.Credentials({
      accessKeyId: REACT_APP_ACESS_KEY_ID,
      secretAccessKey: REACT_APP_SECRET_ACESS_KEY,
      s3ForcePathStyle: true,
    }),
  });

  const s3 = new AWS.S3();
  useEffect(() => {


    const fileNames = file.name;

    const FilenameList = file.map((i) => `https://trainingnexus.s3.amazonaws.com/DLP/${trainingId}/${i.name}`);
    setAssesmentUrls(FilenameList)

    setarrayFiles(fileNames);
  }, [file]);


  console.log(arrayFiles, 'uploadedFiles')

  const uploadFileToS3 = (filess, partId, moduleId) => {
    if (filess.length === 0) {
      setVectorError('Please Upload Document!');
      return;
    }
    console.log("reached", partId, "part", moduleId)
    setLoading(true);
    setPercent('0')

    const uploadPromises = filess.map((file) => {
      // const fileName = `https://trainingnexus.s3.amazonaws.com/DLP/${trainingId}/${file.name}`
      const params = {
        Bucket: 'trainingnexus',
        Key: `DLP/${trainingId}/${file.name}`,
        Body: file,
      };

      return new Promise((resolve, reject) => {
        s3.upload(params, { partSize: 5 * 1024 * 1024, queueSize: 1 })
          .on('httpUploadProgress', (progress) => {
            const uploadedPercentage = Math.round((progress.loaded / progress.total) * 100);
            setPercent(uploadedPercentage);
          })
          .send((err, data) => {
            if (err) {
              reject(err);
            } else {
              resolve(data);
              const updatedParts = parts.map((part) =>
                part.id === partId
                  ? {
                    ...part,
                    modules: part.modules.map((module) => {
                      let type = data.Location.split('.').slice(-1);
                      console.log(type, 'videoType')
                      return (

                        module.id === moduleId ? { ...module, trainingModule: data.Location, trainingModuleType: type[0] } : module
                      )
                    }
                    ),
                  }
                  : part
              );
              setParts(updatedParts);
              // console.log(data.Location,"chan")
            }
          });
      });
    });

    Promise.all(uploadPromises)
      .then(() => {
        console.log("reached1")
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "upload err");
        setLoading(false);
      });


  };


  const props = {
    name: 'file',
    multiple: false,
    // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
      console.log(info, "info")
      const fileObj = info.fileList.map((item) => item.originFileObj);

      console.log(fileObj, 'fileObj');
      uploadFileToS3(fileObj)
      setfile(fileObj)


    },
  };

  const closeTitleModal = () => {
    setTitleModal(false);
  }

  const titleUpdate = () => {

    let body = {
      "trainingId": trainingId,
      "trainingTitle": updateCourse
    }

    axios.post(`${BaseUrl}trainings/update`, body)

      .then((res) => {
        console.log(res.data, 'updateResponse');

        if (res.data.statuscode === 200) {
          setCourseName(updateCourse);
          setTitleModal(false);
          message.success('Course name changed successfully!')
        } else {
          setTitleModal(false);
          message.error(`Can't change the Training name`)
        }
      })

  }


  const handleCreateParts = () => {

    const postData = {
      trainingPartsTraining: parts.map((part) => ({
        trainingId: trainingId,
        partName: part.name,
        partNumber: part.number,
        training: part.modules.map((module) => ({
          trainingFileName: module.name,
          trainingFileDuration: module.trainingModuleDuration,
          trainingFileType: module.trainingModuleType,
          trainingFile: module.trainingModule
        })),
      })),
    };



    axios.post(`${BaseUrl}training-parts/add-training-parts`, postData)

      .then((res) => {
        console.log(res, 'postDataRes');
        if (res.data.statuscode === 200) {
          navigate('/assigntrainings');
          message.success('Course parts created successfully!')
        } else {
          message.error('Error creating parts!')
        }
      }).catch((err) => {
        console.log(err)
      })

    console.log(postData, 'postData'); // Display the transformed data in the console


    let body = {
      course_id: trainingId,
      url: AssesmentUrls,
      mailid: userEmail
    }

    axios
      .post(`${AssesmentUrl}content_extraction`, body)
      .then((res) => {
        setSessionId(res.data.session_id)
        console.log(res)
        console.log(session_id, "session_id")
      })
      .catch((err) => {
        console.log(err, "error")
      })

  }

  return (
    <div>
      <p className='back'>
        <span onClick={(e) => navigate(`/managetrainings`)} style={{ color: '#4096FF', textDecoration: "underline" }}>
          Trainings
        </span>
        <span>
          <ArrowForwardIosIcon className='arrow' />
        </span>
        <span style={{ color: '#454545', textDecoration: "" }}>
          {courseName}
        </span>


      </p>
      <Modal open={titleModal} onOk={titleUpdate} onCancel={closeTitleModal}>
        <h3>Change Training Name - {courseName}</h3>
        <Input value={updateCourse} onChange={(e) => setUpdateCourse(e.target.value)} />
      </Modal>
      <div className='newCourseTitleDiv'>
        <h3>Training Title - <span className='courseTitleInCourseEditor'>{courseName}</span> </h3>
        <EditOutlined className='editIconForCourseTitle' onClick={() => setTitleModal(true)} />
      </div>

      <Button className='addPartButton' block type='primary' icon={<PlusOutlined />} onClick={addPart}>
        Add Module
      </Button>
      {parts.map((part) => (
        <Card key={part.id} className='addModuleCard' style={{ width: '100%' }}>
          <div style={{ display: 'flex' }}>
            <h3>{part.number} - </h3>
            <Input value={part.name}
              style={{ marginLeft: '1%', marginTop: '8px' }}
              onChange={(e) => {
                // Update the module name when input changes
                const updatedParts = parts.map((p) =>
                  p.id === part.id
                    ? {
                      ...p,
                      // modules: p.modules.map((m) =>
                      //   m.id === module.id ? { ...m, name: e.target.value } : m
                      // ),
                      name: e.target.value
                    }
                    : p
                );
                setParts(updatedParts);
              }}
            />
          </div>
          <div className='trainingModuleButtons'>
            <Button type='primary' icon={<PlusOutlined />} onClick={() => addModule(part.id)}>
              Add Topic
            </Button>
            <Button
              type='primary'
              danger
              icon={<DeleteOutlined />}
              onClick={() => deletePart(part.id)}
            >
              Delete Module
            </Button>
          </div>

          {part.modules.map((module) => (
            <div className='trainingModuleDiv' key={module.id}>
              <h4 className='moduleTitles trainingModuleNameClass'>Topic</h4>
              <Input
                value={module.name}
                onChange={(e) => {
                  // Update the module name when input changes
                  const updatedParts = parts.map((p) =>
                    p.id === part.id
                      ? {
                        ...p,
                        modules: p.modules.map((m) =>
                          m.id === module.id ? { ...m, name: e.target.value } : m
                        ),
                      }
                      : p
                  );
                  setParts(updatedParts);
                }}
              />

              <h4 className='moduleTitles'>Add Topic Content</h4>
              <Dragger className='moduleUploadDragger'
                name='file'
                action=''
                // {...props} 
                onChange={(info) => {
                  console.log(info, "info")
                  const fileObj = info.fileList.map((item) => item.originFileObj);

                  console.log(fileObj, 'fileObj');
                  uploadFileToS3(fileObj, part.id, module.id)
                  console.log(module, "module")
                  setfile(fileObj)
                }}
                customRequest={({ onSuccess }) => onSuccess("ok")}
              // onChange={ () => file ? handleFileUpload( part.modules.partId ) : null }
              >
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>
                  Click or drag file to this area to upload
                </p>
                <p className='ant-upload-hint'>
                  Note: Upload one file per topic.  Supports .docx, .pdf. .mp3, .wav files
                </p>
              </Dragger>
              <Button
                className='deleteModuleButtons'
                type='primary'
                danger
                icon={<DeleteOutlined />}
                onClick={() => deleteModule(part.id, module.id)}
              >
                Delete Module
              </Button>
            </div>
          ))}
        </Card>
      ))}
      <Button onClick={handleCreateParts} className='SaveBtn' block type='primary'>
        {
          loading == true ?
            <Progress strokeColor={'#fff'} className="progress" percent={Percent} type="circle" />
            :
            <>
              Save
            </>

        }
      </Button>
    </div>
  );
};

export default TrainingEditor