// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analyticsMainDiv{
    margin-top: 70px;
    padding: 25px;
}
.analyticsImg{
    width: 100%;
}
.Course-card-head {
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    margin: 0px;
}
.Course-graph-head{
    font-weight: 500;
    font-size: 15px;
}
.Course-score {
    font-size: 70px;
    margin: 0px;
    text-align: center;
}
/* .recharts-wrapper {
    width: 0px !important;
    height: 0px !important;
} */
.thirdrowCard{
    height: 200px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Masters/Analytics/Analytics.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;AACjB;AACA;IACI,WAAW;AACf;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,eAAe;IACf,WAAW;IACX,kBAAkB;AACtB;AACA;;;GAGG;AACH;IACI,aAAa;AACjB","sourcesContent":[".analyticsMainDiv{\n    margin-top: 70px;\n    padding: 25px;\n}\n.analyticsImg{\n    width: 100%;\n}\n.Course-card-head {\n    font-weight: 500;\n    font-size: 15px;\n    text-align: center;\n    margin: 0px;\n}\n.Course-graph-head{\n    font-weight: 500;\n    font-size: 15px;\n}\n.Course-score {\n    font-size: 70px;\n    margin: 0px;\n    text-align: center;\n}\n/* .recharts-wrapper {\n    width: 0px !important;\n    height: 0px !important;\n} */\n.thirdrowCard{\n    height: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
