import React, { useState } from 'react';
import { Col, Row, Input, Button, notification, Form } from 'antd';
import OtpInput from 'react-otp-input';
import axios from 'axios';
import debounce from 'lodash.debounce';
import "./ForgotPassword.css";
import { BaseUrl } from '../Constants/Constants';
import { Popover } from 'antd';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const [Email, setEmail] = useState("");
    const [OtpVerify, setOtpVerify] = useState(false);
    const [otp, setOtp] = useState('');
    const [passwordPage, setPasswordPage] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [Password, setPassword] = useState("");
    const [ConfirmPass, setConfirmPass] = useState("");
    const [pwdStatus, setPwdStatus] = useState('');
    const [CpwdpopStatus, setPwdpopStatus] = useState('');
    const [CpwdStatus, setCPwdStatus] = useState('');
    const [loadings, setLoadings] = useState([]);
    const navigate = useNavigate();

    const openNotificationWithIcon = (type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    };

    const handleSubmit = async () => {
        setLoadings([true]);
        try {
            const response = await axios.post(`${BaseUrl}forgot-password/send-otp`, { email: Email });
            openNotificationWithIcon('success', "Success", "OTP has been sent to your email.");
            setOtpVerify(true);
        } catch (error) {
            openNotificationWithIcon('error', "Error", error.response?.data?.message || "Failed to send OTP.");
        } finally {
            setLoadings([false]);
        }
    };

    const handleClick = async () => {
        setLoadings([true]);
        try {
            const response = await axios.post(`${BaseUrl}forgot-password/verify-otp`, { email: Email, otp: otp });
            openNotificationWithIcon('success', "Success", "OTP verified successfully.");
            setPasswordPage(true);
        } catch (error) {
            openNotificationWithIcon('error', "Error", error.response?.data?.message || "Failed to verify OTP.");
        } finally {
            setLoadings([false]);
        }
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);

        const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
        const isValidPassword = passwordRegex.test(value);
        setPwdStatus(isValidPassword ? 'success' : 'error');

        const hasMinLength = value.length >= 8;
        const hasNumber = /\d/.test(value);
        const hasSpecialChar = /[^\w\s]/.test(value);
    
        setPwdpopStatus({
            overall: isValidPassword ? 'success' : 'error',
            minLength: hasMinLength ? 'success' : 'error',
            number: hasNumber ? 'success' : 'error',
            specialChar: hasSpecialChar ? 'success' : 'error',
        });
    };

    const handleConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmPass(value);

        const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
        const isValidPassword = passwordRegex.test(value);
        setCPwdStatus(isValidPassword ? 'success' : 'error');
    };

    const handleNewPass = async () => {
        setLoadings([true]);
        try {
            const response = await axios.post(`${BaseUrl}forgot-password/change-password`, {
                email: Email,
                newPassword: Password
            });
            openNotificationWithIcon('success', "Success", "Password changed successfully.");
            // Clear state after success
            setEmail("");
            setOtp("");
            setOtpVerify(false);
            setPasswordPage(false);
            setPassword("");
            setConfirmPass("");
            setTimeout(() => {
                navigate('/login')
              }, 500);
            
        } catch (error) {
            openNotificationWithIcon('error', "Error", error.response?.data?.message || "Failed to change password.");
        } finally {
            setLoadings([false]);
        }
    };

    const passwordValidationContent = (
        <ul className="validationList" style={{ listStyle: 'none', padding: 0, margin: 0, fontSize: '12px', color: '#333' }}>
            <li style={{ color: CpwdpopStatus.minLength === 'success' ? '#33b249' : 'red' }}>
                • Minimum 8 characters
            </li>
            <li style={{ color: CpwdpopStatus.number === 'success' ? '#33b249' : 'red' }}>
                • At least one number
            </li>
            <li style={{ color: CpwdpopStatus.specialChar === 'success' ? '#33b249' : 'red' }}>
                • At least one special character
            </li>
        </ul>
    );

    return (
        <div className='Forgotpass'>
            {contextHolder}
            <Row className='ForgotpassRowMain'>
                
            {/* <Col xs={24} sm={4} md={4}>
            <img src="/Login/forgotpassword.jpg" alt="" className='forgotPasswordImg'/>
            </Col> */}
            <Col xs={24} sm={22} md={22}>
            <Row className='ForgotpassRow'>
                <Col>
                <div className='forgotdiv'>
              <div className='LogoDiv'>
                <img src='/Logo/Logo Svg OG 3.png' className='loginImage' alt='Logo' />
              </div>
{/*                     
                        <div className='forgotiv1'>
                            <h3 className='LoginH1'>
                                Forgot Password
                            </h3>
                        </div> */}
                        {!OtpVerify ? (
                            <>
                             <div className='forgotiv1'>
                            <h3 className='LoginH2'>
                                Forgot Password
                            </h3>
                        </div>
                            <label className='forgotinputLabel'>Email ID<span className='requiredspan'>*</span></label>
                                <Input
                                    className='forgotInput'
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email ID"
                                />
                                <div className='SubmitButton1'>
                                    <Button
                                        className='ForgotButton'
                                        disabled={!Email}
                                        loading={loadings[0]}
                                        onClick={handleSubmit}
                                    >
                                        Send OTP
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <div className='otpInput'>
                                {!passwordPage ? (
                                    <>
                                        <h3 style={{ marginBottom: 10, marginLeft: 11}}>
                                            Enter OTP
                                        </h3>
                                        <div className='otpBoxInput'>
                                            <OtpInput
                                                value={otp}
                                                onChange={setOtp}
                                                numInputs={6}
                                                renderInput={(props) => <input {...props} />}
                                            />
                                        </div>
                                        <div className='SubmitButton1'>
                                            <Button
                                                className='ForgotButton'
                                                disabled={otp.length !== 6}
                                                loading={loadings[0]}
                                                onClick={handleClick}
                                            >
                                                Verify
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                     <div className='changeforgot'> <h3 style={{ marginBottom: 10, marginLeft: 11}}>
                                            Change Password
                                        </h3>
                                        <Form.Item className='PasswordForm' hasFeedback validateStatus={pwdStatus}>
                                       
        <Popover 
            content={passwordValidationContent} 
            placement="left" 
            trigger="focus"
            style={{ marginLeft: '10px' }} // Adjust if needed
        >
            <Input.Password
                status={!ConfirmPass ? "" : Password !== ConfirmPass ? "error" : ""}
                onChange={handlePasswordChange}
                className='loginInput'
                placeholder="Password"
            />
        </Popover>
    
                                        </Form.Item>
                                        <Form.Item className='PasswordForm' hasFeedback validateStatus={CpwdStatus}>
                                            <Input.Password
                                                status={!ConfirmPass ? "" : Password !== ConfirmPass ? "error" : ""}
                                                onChange={handleConfirmPasswordChange}
                                                className='loginInput'
                                                placeholder="Confirm Password"
                                            />
                                        </Form.Item>
                                        <div className='SubmitButton1'>
                                            <Button
                                                disabled={Password !== ConfirmPass || pwdStatus === 'error' || CpwdStatus === 'error'}
                                                className='ForgotButton'
                                                loading={loadings[0]}
                                                onClick={handleNewPass}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    
                    </div>
                </Col>
            </Row>
            </Col>

                </Row>
            
        </div>
    );
};

export default ForgotPassword;
