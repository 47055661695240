import React, { useEffect, useState } from 'react'
import { Button, Col, DatePicker, Input, Modal, Row, Select, Space, Table, message } from 'antd'
import axios from 'axios';
import { BaseUrl, FrontendUrl } from '../../Constants/Constants';
import { EditOutlined } from '@mui/icons-material';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment';


const { RangePicker } = DatePicker;

const userColumn = [
    {
        title: 'User Name',
        dataIndex: 'username',
        key: 'username',
        align: 'center',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        align: 'center',
    },
    {
        title: 'Team',
        dataIndex: 'team',
        key: 'team',
        align: 'center',
    },
];

const nonLinkedColumn = [
    {
        title: 'User Name',
        dataIndex: 'username',
        key: 'username',
        align: 'center',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        align: 'center',
    },
    {
        title: 'Team',
        dataIndex: 'team',
        key: 'team',
        align: 'center',
    },
];

const TraininguserMapping = () => {
    const { trainingId } = useParams();
    const navigate = useNavigate();


    const [teamOption, setTeamOption] = useState([]);
    // const [selectedTeam, setSelectedTeam] = useState('d8d3b0cb-02f4-4fe2-9ef6-afc0a34ef8c3');
    const [selectedTeam, setSelectedTeam] = useState('');
    // const [nonLinkedUserTeam, setNonLinkedUserTeam] = useState('d8d3b0cb-02f4-4fe2-9ef6-afc0a34ef8c3');
    const [nonLinkedUserTeam, setNonLinkedUserTeam] = useState('');
    const [userData, setUserData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [linkedRowKeys, setLinkedRowKeys] = useState([]);
    const [nonLinkedData, setNonLinkedData] = useState([]);
    const [searchUnmapped, setSearchUnmapped] = useState('');
    const [searchMapped, setSearchMapped] = useState('');
    const [unMappedUsers, setUnmappedUsers] = useState([]);
    const [mappedUsers, setMappedUsers] = useState([]);
    const [dateModal, setDateModal] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [userReminderDate, setUserReminderDate] = useState('')
    const [TrainingData, setTrainingData] = useState([])
    const [selectedUsersData, setselectedUsersData] = useState([])

    const { Search } = Input;
    

    const dateModalOpen = () => {
        if (hasSelected) {
            setDateModal(true);
        } else {
            message.warning('Please select any user to assign Training!')
        }
    }

    const startDateChange = (date, dateString) => {
        const timestampStr = moment(dateString).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        console.log(timestampStr != "", 'dateString');
        setStartDate(timestampStr);
    }

    const endDateChange = (date, dateString) => {
        const timestampStr = moment(dateString).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        // console.log(timestampStr, 'dateString');
        setEndDate(timestampStr);
    }

    useEffect(() => {
        axios.get(`${BaseUrl}teams`)
            .then((res) => {
                let teams = res.data.map((item) => {
                    let teamsObj = {
                        value: item.team_id,
                        label: item.Team_name
                    }
                    return teamsObj;
                })
                setTeamOption(teams);

            })
            .catch((err) => {
                console.log(err.message)
            })
    }, [])
    useEffect(() => {
        let body = {
            training_id: trainingId
        }
        axios
            .post(`${BaseUrl}trainings/findOneTraining`, body)
            .then((res) => {
                console.log(res, "chanres")
                if (res.data.statuscode === 200)
                    setTrainingData(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [trainingId])





    useEffect(() => {
        if (searchMapped === '') { setMappedUsers(userData); return; }

        const filteredEmails = userData.filter((item) => item.email.toLowerCase().includes(searchMapped.toLowerCase()))
        setMappedUsers(filteredEmails);
        console.log(userData, 'searchVal')

    }, [searchMapped, userData])



    useEffect(() => {

        nonLinkedUsersFunc();

    }, [searchUnmapped, nonLinkedData]);

    const nonLinkedUsersFunc = () => {
        if (searchUnmapped === '') { setUnmappedUsers(nonLinkedData); return; }

        const filteredEmails = nonLinkedData.filter((item) => item.email.toLowerCase().includes(searchUnmapped.toLowerCase()))
        setUnmappedUsers(filteredEmails);
    }
    console.log(nonLinkedData, 'searchVal')




    const fetchAllCourseUsers = () => {
        let training = {
            trainingId: trainingId
        }

        axios.post(`${BaseUrl}training-mapping/getAllUsers`, training)
            .then((res) => {
                let responseUsers = res.data.data.map(item => item.user_id)
                // console.log(response, 'getAllusers');
                // let courseMappedUsers = res.data.data;
                let bodyUsers = {
                    userId: responseUsers
                }
                if (res.data.statuscode === 200) {
                    axios.post(`${BaseUrl}users/courseMappedUsers`, bodyUsers)
                        .then((response) => {
                            // console.log(response.data.data, 'getAllusers');
                            let linkedUsersArray = response.data.data.linkedUsers;
                            let nonLinkedUsersArray = response.data.data.notLinkedUsers;
                            if (linkedUsersArray.length != 0) {
                                if (selectedTeam === '' ||
                                    selectedTeam === null ||
                                    selectedTeam === '33f51844-7153-4e3f-86c8-eb5a017d1a9d' ||
                                    selectedTeam === 'd8d3b0cb-02f4-4fe2-9ef6-afc0a34ef8c3' ||
                                    selectedTeam === 'bf24ff2d-7984-4835-9b17-e7e2edf6c6b4'
                                ) {

                                    let users = linkedUsersArray.map((item) => {
                                        let userObj = {
                                            key: item.user_id,
                                            username: item.username,
                                            email: item.email,
                                            team: item.team_id.Team_name,
                                            teamManager: item.team_id.manager_username,
                                            managerEmail: item.team_id.manager_mail
                                        }
                                        return userObj
                                    })
                                    setUserData(users);
                                }
                                else {
                                    let filteredUsers = linkedUsersArray.filter(i => i.team_id.team_id === selectedTeam);

                                    let users = filteredUsers.map((item) => {
                                        let userObj = {
                                            key: item.user_id,
                                            username: item.username,
                                            email: item.email,
                                            team: item.team_id.Team_name,
                                            teamManager: item.team_id.manager_username,
                                            managerEmail: item.team_id.manager_mail
                                        }
                                        return userObj
                                    })

                                    console.log(users, 'filteredUsers');
                                    setUserData(users);
                                }
                            } else {
                                setUserData([]);
                            }

                            if (nonLinkedUsersArray.length != 0) {
                                if (nonLinkedUserTeam === '' ||
                                    nonLinkedUserTeam === null ||
                                    nonLinkedUserTeam === '33f51844-7153-4e3f-86c8-eb5a017d1a9d' ||
                                    nonLinkedUserTeam === 'd8d3b0cb-02f4-4fe2-9ef6-afc0a34ef8c3' ||
                                    nonLinkedUserTeam === 'bf24ff2d-7984-4835-9b17-e7e2edf6c6b4'
                                ) {

                                    let users = nonLinkedUsersArray.map((item) => {
                                        let userObj = {
                                            key: item.user_id,
                                            username: item.username,
                                            email: item.email,
                                            team: item.team_id.Team_name,
                                            teamManager: item.team_id.manager_username,
                                            managerEmail: item.team_id.manager_mail
                                        }
                                        return userObj
                                    })
                                    setNonLinkedData(users);
                                }
                                else {
                                    let filteredUsers = nonLinkedUsersArray.filter(i => i.team_id.team_id === nonLinkedUserTeam);

                                    let users = filteredUsers.map((item) => {
                                        let userObj = {
                                            key: item.user_id,
                                            username: item.username,
                                            email: item.email,
                                            team: item.team_id.Team_name,
                                            teamManager: item.team_id.manager_username,
                                            managerEmail: item.team_id.manager_mail
                                        }
                                        return userObj
                                    })

                                    setNonLinkedData(users);
                                }
                            } else {
                                setNonLinkedData([]);
                            }

                        })
                        .catch((err) => {
                            console.log(err.message)
                        })
                }
            })
            .catch((err) => {
                console.log(err.message)
            })
    }

    useEffect(() => {

        fetchAllCourseUsers();

    }, [selectedTeam, nonLinkedUserTeam])

    console.log(selectedTeam, 'filteredUsers')


    const onSelectChange = (newSelectedRowKeys, e) => {
        console.log(e, 'selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
        setselectedUsersData(e)
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onLinkedSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setLinkedRowKeys(newSelectedRowKeys);
    };

    const linkedrowSelection = {
        linkedRowKeys,
        onChange: onLinkedSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;
    const linkedSelected = linkedRowKeys.length > 0;

    console.log(linkedRowKeys, 'linkedSelected')
    const handleRemove = () => {

        if (linkedSelected) {


            let body = {
                trainingId: trainingId,
                userId: linkedRowKeys
            }


            console.log(body, 'selectedBody')

            axios.post(`${BaseUrl}training-mapping/removeUserFromTraining`, body)
                .then((res) => {
                    let response = res.data;
                    console.log(response, 'DELETE')

                    if (response.success === true) {

                        message.success(`Removed ${linkedRowKeys.length} user`);
                        fetchAllCourseUsers();

                    }
                    else {
                        message.error(`Can't remove users!`)
                    }


                })
                .catch((err) => {
                    console.log(err.message)
                })
        }
        else {
            message.warning('Please select any user to Remove!')
        }
    }


    console.log(nonLinkedData, 'startDateState')



    console.log(selectedRowKeys, 'hasSelected');

    // let emailBody = selectedRowKeys.map((i) => {
    //     const selectedUserDetails  = nonLinkedData.find(item => item.key === i );
    //     return {
    //       companyId: "b741fee7-80cb-4422-82e6-c0df5bcb288f",
    //       trainingId: trainingId,
    //       userId: i,
    //       userEmail: selectedUserDetails.email,
    //       userName: selectedUserDetails.username,
    //       userSendDate: "",
    //       managerEmail: selectedUserDetails.managerEmail,
    //       managerName: selectedUserDetails.teamManager,
    //       managerSendDate: "",
    //       trainingStartDate: startDate,
    //       trainingEndDate: endDate
    //     };
    //   });

    console.log(endDate, 'emailBody')

    console.log(TrainingData, "TrainingData")

    const [SendBulk, setSendBulk] = useState(false)
    const [SupportEmail, setSupportEmail] = useState('')

    useEffect(() => {
        axios
            .get(`${BaseUrl}email-service`)
            .then((res) => {
                // console.log(res.data[0].admin_email,"adminemail")
                setSupportEmail(res.data[0].admin_email)
            })
            .catch((err) => {

            })
    }, [])

    useEffect(() => {
        if (SendBulk) {


            const formatDate = (isoString) => {
                const date = new Date(isoString);
                return date.toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    timeZoneName: 'short',
                    hour12: true
                });
            };

            const formattedEndDate = formatDate(TrainingData.training_end_date);

            let trainingEndDate = TrainingData.training_end_date.split('T')[0]
            console.log(TrainingData, "tariningDataa")

            const body = selectedUsersData.map((i) => ({
                email: i.email,
                template: `<!DOCTYPE html>
<html>
<head>
  <style>
    body {
      font-family: Arial, sans-serif;
      background-color: #f4f4f4;
      margin: 0;
      padding: 0;
    }
    .container {
      background-color: #f4f4f4;
      margin: 20px auto;
      padding: 20px;
      max-width: 600px;
    }
    .card {
      background-color: #ffffff;
      padding: 20px;
      border-radius: 8px;
      border: 2px solid #0073e6; /* Border for the card */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    .header {
      background-color: #0073e6;
      padding: 10px;
      border-radius: 8px 8px 0 0;
      text-align: center;
      color: #ffffff;
    }
    .content {
      padding: 20px;
    }
    .content p {
      margin: 0 0 10px 0;
      line-height: 1.5;
      color: #555555;
    }
    .footer {
      padding: 10px;
      text-align: center;
      color: #777777;
      font-size: 12px;
    }
    .button {
      background-color: #0073e6;
      color: #ffffff;
      padding: 10px 20px;
      border-radius: 5px;
      text-decoration: none;
      display: inline-block;
      transition: background-color 0.3s ease;
    }
    .button:hover {
      background-color: #3399ff;
    }
  </style>
</head>
<body>
  <div class="container">
    <div class="card">
      <div class="header">
        <h1>New Training Assignment</h1>
      </div>
      <div class="content">
        <p>Hi <strong>${i.username}</strong>,</p>
        <p>You have been assigned with a new training today. Please take the training before the end date to ensure you adhere to project compliance and organization policies. Here are the training details:</p>
        <p><strong>Training Name:</strong> ${TrainingData.training_title}</p>
        <p><strong>Description:</strong> ${TrainingData.training_description}</p>
        <p><strong>Additional Instructions:</strong> ${TrainingData.training_instruction}</p>
        <p><strong>Training URL:</strong> <a href="${FrontendUrl}${TrainingData.training_id}/${TrainingData.course_id.course_id}">${FrontendUrl}${TrainingData.training_id}/${TrainingData.course_id.course_id}</a></p>
        <p><strong>Complete it before:</strong> ${trainingEndDate}</p>
        <p>Please reach out to <a href="mailto:${SupportEmail}">${SupportEmail}</a> for any help or support.</p>
        <p>Thank you,</p>
        <p>Training & Compliance Team</p>
      </div>
    </div>
  </div>
</body>
</html>`,
                subject: `New Mandatory Training Assigned – Complete by ${trainingEndDate} `
            }));

            axios
                .post(`${BaseUrl}email-service/bulk-upload`, body)
                .then((res) => {
                    console.log(res, "email");
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, [SendBulk]);

    const handleAssign = () => {

        // if (startDate === "Invalid date" || endDate === "Invalid date") {
        //     message.warning('Please select start date & end date to assign!')
        // } else {

        if (hasSelected) {

            setSendBulk(false)

            let body = selectedRowKeys.map((i) => (
                {
                    trainingId: trainingId,
                    userId: i,
                    // startDate: startDate,
                    // endDate: endDate
                }
            ))

            console.log(body, 'selectedBody')

            axios.post(`${BaseUrl}training-mapping/create`, body)
                .then((res) => {

                    let response = res.data;

                    if (response.statuscode === 200) {
                        let updateBody = [{
                            training_Id: trainingId,
                            teamId: selectedTeam
                        }]
                        axios.post(`${BaseUrl}trainings/update`, updateBody)

                            .then((res) => {
                                console.log(res.data, 'updateCourse');
                                message.success(`Training assigned to ${selectedRowKeys.length} user`);
                                fetchAllCourseUsers();
                                nonLinkedUsersFunc();
                                setDateModal(false);
                                setSendBulk(true)
                                // setTimeout(() => {
                                //     navigate('/assigncourse');
                                // }, 1000)
                            })
                            .catch((err) => {
                                console.log(err.message);
                                message.error(`Error Updating the team to the Training!`);
                            })
                    }

                    else {
                        message.error(`Can't assign Trainings!`)
                    }


                })
                .catch((err) => {
                    console.log(err.message)
                })
                .finally(() => {
                    // fetchAllCourseUsers();
                })
        }
        else {
            message.warning('Please select any user to assign Training!')
        }
        // }
    }

    const handleTeamChange = (value) => {
        setSelectedTeam(value);  // For mapped users
        setNonLinkedUserTeam(value);  // For unmapped users
    };



    const today = moment();

    console.log(today, 'todayMoment');

    const disabledDate = current => {
        // Disable dates before today
        return current && current < moment().startOf('day');
    };

    // function convertTimeToEndOfDay(dateTimeString) {
    //     const dateTime = new Date(dateTimeString);
    //     dateTime.setHours(23, 59, 59, 999);
    //     const formattedDateTime = dateTime.toISOString().split('T')[0] + ' ' + '23:59:59';

    //     return formattedDateTime;
    // }

    function convertTimeToEndOfDay(dateTimeString) {

        console.log(dateTimeString, 'dateTimeString')

        if (dateTimeString === 'Invalid date') {
            return null;
        }


        const dateTime = new Date(dateTimeString);

        // if (isNaN(dateTime.getTime())) {
        //   throw new Error('Invalid dateTimeString format');
        // }

        dateTime.setHours(23, 59, 59, 999); // Set to end of day
        const formattedDateTime = dateTime.toISOString().split('T')[0] + ' 23:59:59'; // Format as YYYY-MM-DD 23:59:59

        return formattedDateTime;
    }

    const datePickerOnChange = (date, dateString) => {

        if (!dateString || dateString.length === 0) {
            // Handle the case when range picker is cleared
            setStartDate(null);
            setEndDate(null);
            return;
        }

        console.log(dateString, 'dateString');
        const startDateString = moment(dateString[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endDateString = moment(dateString[1]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const convertedEndDate = convertTimeToEndOfDay(endDateString);
        const ReminderDate = moment(convertedEndDate).subtract(2, 'days').endOf('day');
        const ReminderDateString = moment(ReminderDate).startOf('day').format('YYYY-MM-DD HH:mm:ss')

        setStartDate(startDateString);
        setEndDate(convertedEndDate);


        setUserReminderDate(ReminderDateString);


        console.log(ReminderDateString, 'endDateString--->', convertedEndDate)

    }









    console.log(selectedTeam, 'selectedUserId')

    return (
        <div className='userMappingMain'>
            <p className='back'>
                <span onClick={(e) => navigate(`/assigntrainings`)} style={{ color: '#4096FF', textDecoration: "underline" }}>
                    Training Mapping
                </span>
                <span>
                    <ArrowForwardIosIcon className='arrow' />
                </span>
                {/* <span  style={{ color: '#454545',textDecoration:""}}>
                 {selectedTeam}
                </span> */}

            </p>


            {/* <Modal open={dateModal} onOk={handleAssign} onCancel={() => setDateModal(false)} title='Set duration for this training' className=''>
                <RangePicker onChange={datePickerOnChange} disabledDate={disabledDate} />
            </Modal> */}
            <h2>Assign Trainings</h2>

            <div className='selectAndAssignButton'>
            <Select style={{ width: 250 }}
    placeholder="Select Team" 
    onChange={handleTeamChange} 
    value={selectedTeam}
>
    {teamOption.map(option => (
        <Select.Option key={option.value} value={option.value}>
            {option.label}
        </Select.Option>
    ))}
</Select>
                <div>
                    <Button className='addCourseButton'
                        onClick={handleAssign}
                    >Assign Users</Button>
                    <Button className='addCourseButton'
                        onClick={handleRemove}
                    >Remove</Button>
                </div>
            </div>
            <div className='Tableset'>
                <div>
                <Search
                    placeholder='Search Mapped Users by email'
                    onChange={(e) => setSearchMapped(e.target.value)}
                    value={searchMapped}
                    style={{ marginBottom: 16, width: 300 }}
                />
                    <h2>Mapped Users</h2>
                    <Table rowSelection={linkedrowSelection} columns={userColumn} dataSource={mappedUsers} />

                    <div className='selectAndAssignButton'>
                        {/* <Select style={{ width: 250 }}
                    value={nonLinkedUserTeam}
                    placeholder='Select Team' onSelect={(value) => {
                        console.log()
                        if (value != '') {
                            setNonLinkedUserTeam(value)
                        } else {
                            setNonLinkedUserTeam('d8d3b0cb-02f4-4fe2-9ef6-afc0a34ef8c3');
                        }
                    }} options={teamOption} /> */}
                        {/* <Button className='addCourseButton'
                    onClick={handleAssign}
                >Assign Users</Button> */}
                    </div>
                </div>
                <div>
                    <Search placeholder='Search UnMapped Users by email' onChange={(e) => setSearchUnmapped(e.target.value)} value={searchUnmapped} style={{ marginBottom: 16, width: 300 }}/>
                    <div style={{ display: 'flex' }}>
                        <h2>Unmapped Users</h2>
                        <p
                            style={{
                                marginLeft: 8,
                                marginTop: 23
                            }}
                        >
                            {hasSelected ? `(Selected ${selectedRowKeys.length} user)` : ''}
                        </p>
                    </div>
                    <Table rowSelection={rowSelection} columns={nonLinkedColumn} dataSource={unMappedUsers} />
                </div>
            </div>
        </div>
    )
}

export default TraininguserMapping