import React from 'react'
import './Footer.css'
import { Avatar, Col, Row, Input } from 'antd'

const Footer = () => {
  return (
    <footer className='footer'>
        <div>
        <Row>
          <Col span={6}>
          <img width={180} height={45} className='headerLogoImg' src='/Logo/Training Nexus 2.jpg' alt='' />
          </Col>
          </Row>
        </div>
    </footer>
  )
}

export default Footer