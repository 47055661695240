import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import AssessmentIcon from '@mui/icons-material/Assessment';
import ScoreIcon from '@mui/icons-material/Score';
import SchoolIcon from '@mui/icons-material/School';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import QuizIcon from '@mui/icons-material/Quiz';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import "./Sider.css";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem("Manage Users", "sub1", <GroupAddIcon />),
  getItem("Manage Courses", "sub2", <SchoolIcon />),
  getItem("Manage Trainings", "sub3", <ModelTrainingIcon />),
  getItem("Manage Teams", "sub5", <GroupsIcon />),
  getItem("Configure Assessment", "sub6", <AssessmentIcon />),
  getItem("Reports", "sub4", <ScoreIcon />, '', 'coursesactivityreports'),
  getItem("Assign Course", "sub7", <SettingsSuggestIcon />, '', 'courses/mapping'),
  getItem("Assign Trainings", "sub9", <QuizIcon />, '', 'trainings/mapping'),
];

const rootSubmenuKeys = ["sub1", "sub2", "sub3", "sub4", "sub5", "sub6", "sub7", "sub8"];

const Courses = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const storedOpenKeys = localStorage.getItem('selectedMenu');
  const storedSelectedKey = localStorage.getItem('selectedKey');

  const [openKeys, setOpenKeys] = useState(storedOpenKeys ? [storedOpenKeys] : ['sub1']);
  const [selectedKeys, setSelectedKeys] = useState(storedSelectedKey ? [storedSelectedKey] : ['sub1']);
  console.log(selectedKeys,"selectedkeys")

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    const path = location.pathname;

    if (path === "" || path === "/") {
      setOpenKeys(["sub1"]);
      setSelectedKeys(["sub1"]);
      localStorage.setItem('selectedMenu', "sub1");
      localStorage.setItem('selectedKey', "sub1");
    } else {
      const selectedMenuItem = items.find((i) => i.label.toLowerCase() === path.split('/')[1]);
      if (selectedMenuItem !== undefined) {
        setOpenKeys([selectedMenuItem.key]);
        setSelectedKeys([selectedMenuItem.key]);
        localStorage.setItem('selectedMenu', selectedMenuItem.key);
        localStorage.setItem('selectedKey', selectedMenuItem.key);
      } else {
        setOpenKeys([]);
        // setSelectedKeys([]);
        localStorage.setItem('selectedMenu', []);
        // localStorage.setItem('selectedKey', []);
      }
    }
  }, [location.pathname]);

  const handleChange = (e) => {
    let foundMenu = items.find((i) => i.key === e.key);
    if (foundMenu) {
      localStorage.setItem('selectedMenu', foundMenu.key);
      localStorage.setItem('selectedKey', foundMenu.key);
      setSelectedKeys([foundMenu.key]);

      let route = foundMenu.label.toLowerCase().trim();
      let trimmedRoute = route.replace(/\s/g, "");
      if (foundMenu.key === 'sub4') {
        navigate(`/${foundMenu.type}`);
      } else {
        navigate(`/${trimmedRoute}`);
      }
    }
  };

  return (
    <div className="Sider-main">
      <Menu
        mode="inline"
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        onOpenChange={onOpenChange}
        onClick={handleChange}
        style={{
          marginTop: 70,
        }}
        items={items}
      />
    </div>
  );
};

export default Courses;
