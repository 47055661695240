import React, { useState, useEffect } from 'react'
import { Col, Row, Input, Button, message, Form, notification } from 'antd'
import './Login.css'
import { useNavigate } from 'react-router';
import axios from 'axios';
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import { BaseUrl } from '../Constants/Constants';

const Login = () => {

  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [EmailStatus, setEmailStatus] = useState('');
  const [pwdStatus, setPwdStatus] = useState('');


  let url = window.location.href;
  let splittedUrl = url.split('/')[3];
  console.log(splittedUrl, "url")

  const openNotificationWithIconSuccess = (type) => {
    console.log('getsSuccess')
    api['success']({
      message: "Success!!!",
      description:
        type,
    });
  };

  const handleForgotPass = () => {
    navigate("/forgotpassword")
  }

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(value);

    // Update email status based on validation
    setEmailStatus(isValidEmail ? 'success' : 'error');
  };
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    // Password validation regex pattern
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
    const isValidPassword = passwordRegex.test(value);

    // Update password status based on validation
    setPwdStatus(isValidPassword ? 'success' : 'error');
    console.log(isValidPassword, "isValidPassword")
  };

  const openNotificationWithIconWarning = (type) => {
    api['warning']({
      message: "Warning!!!",
      description:
        type,
    });
  };
  const handleLogin = () => {
    let Body = {
      "username": email,
      "password": password,
      "email": email.toLowerCase()
    }
    if (email && password && pwdStatus == "success") {
      axios
        .post(`${BaseUrl}auth/login`, Body)
        .then((res) => {
          console.log(res, 'loginData')
          if (res.data.status === 204) {
            openNotificationWithIconWarning('Invalid Credentials');
          }
          else {
            if (res.data.role === 'admin' || res.data.role === 'manager' ) {

              openNotificationWithIconSuccess('Logged in Sucessfully')
              localStorage.setItem("IsAuthenticated", "true");
              localStorage.setItem("Profile", JSON.stringify(res.data));
              localStorage.setItem("companyId", res.data.company_id.company_id);

              setTimeout(() => {
                if (res.data.newuser) {
                    navigate('/PasswordChange');
                } else {
                    navigate('/', { state: { message: 'Logged in Successfully' } });
                }
            }, 500);
            }

            else {
              openNotificationWithIconWarning('No Admin Access');
            }
          }
        })
        .catch((err) => {
          // openNotificationWithIconWarning('Invalid Credentials');
          console.log(err, "log")
        })
    }
    else {

      openNotificationWithIconWarning('Please enter valid Credentials');
    }
  }

  const handleSignup = () => {
    navigate("/signup")
  }



  return (

    <div >
      {contextHolder}
      <Row className='LoginRow'>
        <Col
        //  span={{ xs: 8, sm: 16, md: 24, lg: 12 }}
        >
          <div className='Login'>
            <div className='logindiv'>
              <div className='LogoDiv'>
                <img src='/Logo/Logo Svg OG 3.png' className='loginImage' alt='Logo' />

              </div>
              <p className='LoginH1'>
                Admin Portal
              </p>

              <Input
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    document.getElementById('passwordInputField').focus();
                  }
                }}
                className='loginInput'
                placeholder='Email ID'
                onChange={handleEmailChange}
                status={!email ? EmailStatus : ''}
              />
              <Form.Item className='PasswordForm' hasFeedback validateStatus={pwdStatus}>

                <Input.Password
                  id='passwordInputField'
                  onKeyDown={(e) => {
                    if (e.key == 'Enter') handleLogin();
                  }}
                  className='loginInput'
                  placeholder='Password'
                  type='password'
                  onChange={handlePasswordChange}
                  status={!password ? pwdStatus : ''}
                />
              </Form.Item>

              <div className='forgotpassDiv'>
              <Button
                onClick={handleForgotPass}
                className='forgotpassword' size='small' type='link'
              >
                Forgot Password?
              </Button>
            </div>


            </div>
            <div className='forgotpassDiv'>

              <Button
                onClick={handleLogin}
                className='signUpButton'>
                Login
              </Button>
            </div>
            <div>
              {/* <p className='ortag'>------------ or ------------</p> */}

              {/* <Button
                onClick={handleSignup}
                className='signUpButton'>
                Signup
              </Button> */}



            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Login