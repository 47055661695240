import { Button } from 'antd'
import React from 'react'
import { PlusOutlined, InboxOutlined, DeleteOutlined } from '@ant-design/icons'

const AddTrainingModule = () => {
  return (
    <div>
        <Button icon={<PlusOutlined />} type='primary'
        // onClick={}
        >Add Topic</Button>
    </div>
  )
}

export default AddTrainingModule