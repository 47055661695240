// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  font-family: roboto;
}
body{
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
}
.app-container {
  display: flex;
  flex-direction: column;
  flex: 1 1; /* Allow the flex container to take up all available space */
}
.flex-container {
  flex: 1 1; 
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,SAAS;EACT,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAO,EAAE,4DAA4D;AACvE;AACA;EACE,SAAO;AACT","sourcesContent":["*{\n  font-family: roboto;\n}\nbody{\n  margin: 0;\n  padding: 0;\n  display: flex;\n  flex-direction: column;\n  /* min-height: 100vh; */\n}\n.app-container {\n  display: flex;\n  flex-direction: column;\n  flex: 1; /* Allow the flex container to take up all available space */\n}\n.flex-container {\n  flex: 1; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
