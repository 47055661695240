import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { BaseUrl } from '../../../Constants/Constants'
import { Col, Row, Card, Input } from 'antd'
import { useNavigate } from 'react-router-dom'
import './Courses.css'

const Courses = () => {

  const { Meta } = Card;
  const { Search } = Input;
  const navigate = useNavigate();
  const [CourseData, setCourseData] = useState([])
  const [courses, setCourses] = useState([])
  const [Coursesarr, setCoursesarr] = useState([])
  const [Loading, setLoading] = useState(false)


  const handleCard = (e) => {
    navigate(`/scores/${e.course_id}`)
  }
  useEffect(() => {
    courseData()
  }, [])

  const courseData = () => {
    setLoading(true)
    axios
      .get(`${BaseUrl}courses/findAll`)
      .then((res) => {

        console.log(res.data.data, "res.data.data")
        setCourseData(res.data.data)
        setCourses(res.data.data);
        setCoursesarr(res.data.data)
        setLoading(false)
      })
      .catch((err) => {

      })
  }
  const handleChange = (e) => {
    const value = e.target.value;
    console.log(value, "inputval")
    // Check if the input is empty
    if (value.trim() === '') {
      setCoursesarr(courses);
    } else {
      // If not empty, filter the array based on the search term
      const filteredResults = courses.filter((item) =>
        item.course_title.toLowerCase().includes(value.toLowerCase())

      );
      setCoursesarr(filteredResults);
      console.log(filteredResults, "filtered")
    }
  };


  return (
    <div className='scoresMainDiv'>
      <Search
        placeholder="Search Course"
        onChange={(e) => handleChange(e)}
        className="SearchInput"
        allowClear
      />
      <h2>Scores-Courses</h2>
      {
        Loading ?
          <div className='Loader' >

            <img src='Loader\Ellipsis-2.9s-200px.svg'>
            </img>
          </div>
          :
          <>
      <Row className='manageChatbotRow'>
        {
          courses.length != 0 ? <>
            {
              Coursesarr.map((item) => {
                return (
                  <div className='Course-card'>
                    <Col span={6} className='manageBotCardCol' >
                      <Card
                        onClick={() => handleCard(item)}
                        hoverable
                        className='cards'
                        style={{
                          width: 270,
                          padding: 2
                        }}
                        cover={<img alt="Bot Image"
                          // src={item.chat_icon == "" ? "icons\CourseIcons.png" : item.chat_icon} 
                          src='icons\CourseIcons.png'
                          height={180} />}
                      >
                        <Meta title={item.course_title != 'Default' ? item.course_title : `Course ${item.num}`}
                        // title={'Bot' + " " + (key + 1)}
                        />
                      </Card>
                    </Col>
                  </div>
                )
              })
            }
          </>
            :
            <>
              No items
            </>
        }
      </Row>
      </> }
    </div>
  )
}

export default Courses