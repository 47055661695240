import React, { useState, useEffect } from 'react'
import './Scores.css'
import { Table } from 'antd'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { BaseUrl } from '../../Constants/Constants'

const Scores = () => {
    
    const { id } = useParams();
    const [courseName, setCourseName] = useState("");
    const [CourseData, setCourseData] = useState({})


    const course = [
      {
        id: "0001",
        name: "Hackthon ML"
      },
      {
        id: "0002",
        name: "Hackthon AI/ML"
      },
      {
        id: "0003",
        name: "Industry Session"
      },
      {
        id: "0004",
        name: "Python ML"
      },
      {
        id: "0005",
        name: "Radiology "
      },
      {
        id: "0006",
        name: "Introduction to radiology"
      },
      {
        id: "0007",
        name: "Fundamendals of radiology"
      },
      {
        id: "0008",
        name: "Human Anatomy"
      },
      {
        id: "0009",
        name: "Python ML"
      },
      {
        id: "0010",
        name: "Radiology "
      },
  ];

  const columns = [
      {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          align : 'center'
      },
      {
          title: 'Course',
          dataIndex: 'course',
          key: 'course',
          align : 'center'
      },
      {
          title: 'Creation Date',
          dataIndex: 'creationDate',
          key: 'creationDate',
          align : 'center'
      },
      {
          title: 'Grade',
          dataIndex: 'grade',
          key: 'grade',
          align : 'center'
      },
      {
          title: 'Scores',
          dataIndex: 'scores',
          key: 'scores',
          align : 'center'
      },
  ];

  const dataSource = [
      {
          key: 1,
          name : 'User 1',
          course : courseName,
          creationDate: '2024-04-16', // Dummy creation date
          grade : 'A',
          scores : "9.2/10"
      },
      {
          key: 2,
          name : 'User 2',
          course : courseName,
          creationDate: '2024-04-15', // Dummy creation date
          grade: 'B',
          scores : "8.7/10"
      },
      // Other dummy data...
  ];
 
    // const columns = [
    //     {
    //         title: 'Name',
    //         dataIndex: 'name',
    //         key: 'name',
    //         align : 'center'
    //     },
    //     {
    //         title: 'Course',
    //         dataIndex: 'course',
    //         key: 'course',
    //         align : 'center'
    //     },
    //     // {
    //     //     title: 'Assigned Courses',
    //     //     dataIndex: 'total_courses',
    //     //     key: 'total_courses',
    //     //     align : 'center'
    //     // },
    //     // {
    //     //     title: 'Completed Courses',
    //     //     dataIndex: 'completed_courses',
    //     //     key: 'completed_courses',
    //     //     align : 'center'
    //     // },
    //     {
    //         title: 'Grade',
    //         dataIndex: 'grade',
    //         key: 'grade',
    //         align : 'center'
    //     },
    //     {
    //         title: 'Scores',
    //         dataIndex: 'scores',
    //         key: 'scores',
    //         align : 'center'
    //     },
    // ];

    // const dataSource = [
    //     {
    //         key: 1,
    //         name : 'User 1',
    //         course : courseName,
    //         // total_courses : 12,
    //         // completed_courses : 7,
    //         grade : 'A',
    //         scores : "9.2/10"
    //     },
    //     {
    //         key: 2,
    //         name : 'User 2',
    //         course : courseName,
    //         // total_courses : 15,
    //         // completed_courses : 5,
    //         grade: 'B',
    //         scores : "8.7/10"
    //     },
    //     {
    //         key: 3,
    //         name : 'User 3',
    //         course : courseName,
    //         total_courses : 10,
    //         completed_courses : 7,
    //         grade: 'B',
    //         scores : "8.5/10"
    //     },
    //     {
    //         key: 4,
    //         name : 'User 4',
    //         course : courseName,
    //         // total_courses : 12,
    //         // completed_courses : 7,
    //         grade : 'D',
    //         scores : "6.2/10"
    //     },
    //     {
    //         key: 5,
    //         name : 'User 5',
    //         course : courseName,
    //         // total_courses : 18,
    //         // completed_courses : 14,
    //         grade : 'C',
    //         scores : "7.7/10"
    //     },
    // ]


  //   useEffect(() => {
  //       const foundItem = course.find(item => item.id === id);
  //       console.log(foundItem,'foundItem')
  //       if (foundItem) {
  //           setCourseName(foundItem.name)
  //       } else {
  //           setCourseName("Course not available!")
  //       }
  //   }, [])

  //   const courseData = () => {
  //     axios
  //         .get(`${BaseUrl}courses/findAll`)
  //         .then((res) => {
  //             console.log(res.data, "courseData")
  //             const transformnedArray = res.data.data.map(item => ({
  //                 value: item.course_id,
  //                 label: item.course_title
  //             }))
  //             console.log(transformnedArray, "transformnedArray")
  //             setCourseData(transformnedArray)
  //         })
  //         .catch((err) => {

  //         })
  // }

  return (
    <div className='scoresMainDiv'>
        <h2>Scores - {courseName}</h2>
        <Table
         pagination={{
          position: ['bottomCenter'],
          showSizeChanger: false,
        }}  columns={columns} dataSource={dataSource} />
    </div>
  )
}

export default Scores