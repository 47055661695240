import { Card, Col, DatePicker, Input, message, Modal, Row, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { BaseUrl, AssesmentUrl } from '../../../Constants/Constants';
import { Select, Checkbox, Button,Popover } from 'antd';
import './ScoreUsers.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const { RangePicker } = DatePicker;


const ScoreUsers = () => {

  const [userData, setUserData] = useState([]);
  // const [selectedTeam, setSelectedTeam] = useState('d8d3b0cb-02f4-4fe2-9ef6-afc0a34ef8c3');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [teamOption, setTeamOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [sortbyScore, setSortByScore] = useState("");
  const [searchMail, setSearchMail] = useState('');
  const [searchEmployee, setSearchEmployee] = useState('');
  const [clearTableData, setClearTableData] = useState([]);
  const [previousSelectedRowKeys, setPreviousSelectedRowKeys] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [betweenDates, setBetweenDates] = useState([]);
  const [ viewReason, setViewReason ] = useState([]);
  const [ viewReasonModal, setViewReasonModal ] = useState(false);


  const navigate = useNavigate();

  const handleMainCheckboxChange = (e) => {
    // If the main checkbox is checked, set the selected row keys to all row keys
    // If the main checkbox is unchecked, clear the selected row keys
    setSelectedRowKeys(e.target.checked ? dataSource.map(item => item.key) : []);
  };
  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     setSelectedRowKeys(selectedRowKeys);
  //   },
  // };

  const onSelectChange = (selectedRowKeys) => {
    // const updatedPreviousSelectedRowKeys = previousSelectedRowKeys.filter(key => selectedRowKeys.includes(key));
    // setPreviousSelectedRowKeys(updatedPreviousSelectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };



  const handleSortChange = (value) => {
    if (value === 'score') {
      setSortByScore(true);
    } else {
      setSortByScore(false);
    }
  };


  const sortedDataSource = sortbyScore ? [...allData].sort((a, b) => b.score - a.score) : allData;

  const dataSource = allData?.map((data, index) => ({
    key: index + 1,
    serial: index + 1,
    username: data.username,
    email: data.email,
    no_of_question: data.no_of_question,
    score: `${data.max_score}/${data.no_of_question}`,
    course_title: data.course_title,
    date: data.created_dt,
  }))


  console.log(allData, 'allData');

  const hasSelected = selectedRowKeys.length > 0;

  console.log(hasSelected, 'hasSelected');

  const getDatesBetween = (startDate, endDate) => {
    let dates = [];
    let currentDate = moment(startDate);
    const end = moment(endDate);

    while (currentDate <= end) {
      dates.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'days');
    }

    return dates;
  };

  const handleDateRangeChange = (dates, dateString) => {

    const [startDate, endDate] = dateString;
    const datesBetween = getDatesBetween(startDate, endDate);

    console.log(datesBetween, 'dateString')
    setBetweenDates(datesBetween);



  };

  const filterData = () => {
    let filteredData = [...allData];

    // Filter by email
    if (searchMail !== '') {
      filteredData = filteredData.filter(data =>
        data.course_title.toLowerCase().includes(searchMail.toLowerCase())
      );
    }

    // Filter by user name
    if (searchEmployee !== '') {
      filteredData = filteredData.filter(data =>
          data.username.toLowerCase().includes(searchEmployee.toLowerCase())
      );
  }

    if (betweenDates.length != 0) {
      const dateOfDates = ['2024-06-11', '2024-06-12', '2024-06-13', '2024-06-14', '2024-06-15', '2024-06-16', '2024-06-17', '2024-06-18', '2024-06-19', '2024-06-20', '2024-06-21', '2024-06-22', '2024-06-23', '2024-06-24', '2024-06-25', '2024-06-26', '2024-06-27', '2024-06-28', '2024-06-29']
      filteredData = filteredData.filter(item => {
        let formattedDate = item.created_dt.split('T')[0]
        return (
          betweenDates.includes(formattedDate)
        )
      })
    }

    console.log(filteredData, 'filteredData')

    // Map to format required by clearTableData
    const formattedData = filteredData.map((data, index) => {
      let time = data.created_dt.split('T')[1];
      let correctedTime = time.split('.')[0];
      let onlyDate = data.created_dt.split('T')[0];
      let formattedDateStep = onlyDate.split('-');
      let formattedDate = `${formattedDateStep[2]}-${formattedDateStep[1]}-${formattedDateStep[0]}`;
      let createdAtFormattedDate = data.created_dt.replace("T", " ")
      return ({
        key: index + 1,
        serial: index + 1,
        username: data.username,
        email: data.email,
        no_of_question: data.no_of_question,
        score: `${data.score}/${data.no_of_question}`,
        course_title: data.course_title,
        date: formattedDate + " " + correctedTime,
        created_dt : createdAtFormattedDate,
        user_id : data.user_id,
        assessment_id : data.assessment_id,
        assessment_type: data.assessment_type
      })
    });

    setClearTableData(formattedData);
  };

  console.log(clearTableData, 'clearTableData')


  useEffect(() => {
    filterData();
  }, [searchMail, allData, betweenDates, searchEmployee]);




  useEffect(() => {
    axios.get(`${AssesmentUrl}all_score`)
      .then((res) => {
        console.log(res.data, "resAllScoresss")
        setAllData(res.data.score)
      })
      .catch((err) => {
        console.log("errr", err)
      })

  }, [])


  const columns = [
    // {
    //   title: 'S.No',
    //   dataIndex: 'key',
    //   key: 'key',
    //   align: 'center',
    // },
    {
      title: 'Assessment Date Time',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      sorter: (a, b) => {
        const dateA = moment(a.date, 'DD-MM-YYYY HH:mm:ss');
        const dateB = moment(b.date, 'DD-MM-YYYY HH:mm:ss');
        return dateA - dateB;
      },
      width: 130
    },
    {
      title: 'Employee Name',
      dataIndex: 'username',
      key: 'username',
      align: 'left',
      width: 150
    },
    {
      title: 'Email ID',
      dataIndex: 'email',
      key: 'email',
      align: 'left',
      width: 250
    },
    {
      title: 'Course Title',
      dataIndex: 'course_title',
      key: 'course_title',
      align: 'center',
      width: 150
    },
    // {
    //   title: '# of Assessment Questions',
    //   dataIndex: 'no_of_question',
    //   key: 'no_of_question',
    //   align: 'center',
    //   width: 100
    // },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      align: 'center',
      sorter: (a, b) => {
          // Extract numerical values from the score strings
          const [aMax, aTotal] = a.score.split('/').map(Number);
          const [bMax, bTotal] = b.score.split('/').map(Number);
  
          // Calculate the score as a fraction or percentage
          const aScore = aMax / aTotal;
          const bScore = bMax / bTotal;
  
          // Compare the calculated scores
          return aScore - bScore;
      },
      width: 100
  },
  {
    title: 'View Description',
    dataIndex: 'view_reasons',
    render: (_, record) => {
      console.log('Record:', record); // Log the record to the console
  
      return (
        <>
        {
          record.assessment_type === 'mcq with description' ?
          <VisibilityIcon onClick={() => handleViewRecord(record)} />
          :
          <>
<Popover content="No description available" >
          <VisibilityOffIcon />
          </Popover>
          </>
        }
        </>
      );
    },
    width: 100
  }
  
  ];

  

  useEffect(() => {
    axios.get(`${BaseUrl}teams`)
      .then((res) => {
        let teams = res.data.map((item) => {
          let teamsObj = {
            value: item.team_id,
            label: item.Team_name
          }
          return teamsObj;
        })
        setTeamOption(teams);

      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [])

  console.log(dataSource, 'userDataaaaa');
  console.log(selectedRowKeys, 'rowSelection');

  useEffect(() => {
    if (selectedRowKeys.length != 0) {


      ///////====================Last working code without removing the unchecking the row data=========/////////

      const mergedSelectedRowKeys = Array.from(new Set([...previousSelectedRowKeys, ...selectedRowKeys]));

      let filteredUsers = mergedSelectedRowKeys.map(i => clearTableData.find(item => item.key === i));
      // Ensure each filtered user has a unique key
      filteredUsers = filteredUsers.map((user, index) => {
        return ({
          date: user.date,
          username: user.username,
          email: user.email,
          courseTitile: user.course_title,
          noOfQuestions: user.no_of_question,
          score: user.score,
        })
      }
      );

      console.log(filteredUsers, 'filteredUsers')
      setUserData(filteredUsers);
      // Update the previous selected row keys
      setPreviousSelectedRowKeys(mergedSelectedRowKeys);

    }
    else {

      let users = clearTableData.map((item) => {
        let correctedDate = item.date.split('T')[0]
        let userObj = {
          // key: item.user_id,
          date: item.date,
          username: item.username,
          email: item.email,
          courseTitile: item.course_title,
          noOfQuestions: item.no_of_question,
          score: item.score,
          // team: item.team_id.Team_name
        }
        return userObj
      })

      setUserData(users);
      setPreviousSelectedRowKeys([]);
    }
  }, [selectedRowKeys, clearTableData.length])



  console.log(dataSource, 'clickedUser')



  const now = new Date();

  const padZero = (num) => num.toString().padStart(2, '0');

  const day = padZero(now.getDate());
  const month = padZero(now.getMonth() + 1); // getMonth() returns 0-based month
  const year = now.getFullYear().toString().slice(-2); // get last two digits of the year
  const hours = padZero(now.getHours());
  const minutes = padZero(now.getMinutes());

  let formattedCurrentTime = `${day}-${month}-${year}(${hours}.${minutes})`;


  const handleDownload = () => {
    const columnNames = ['Assessment Date Time', 'Employee Name', 'Email ID', 'Course Title', 'No of Assessment Questions', 'Score'];
    // const columnNames = ['Course Title', 'Username', 'Email', 'No of Questions', 'Score', 'Date'];
    // const csvContent = "data:text/csv;charset=utf-8," +
    //   userData.map(row => Object.values(row).join(",")).join("\n");
    const csvContent = `data:text/csv;charset=utf-8,${[columnNames.join(',')].concat(userData.map(row => Object.values(row).join(","))).join("\n")}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `Reports ${formattedCurrentTime}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  const handleRoute = (e) => {
    navigate(e)
  }


  const handleViewRecord = (record) => {
    console.log(record, 'handleViewRecord');
    
    let body = {
      created_dt : record.created_dt ,
      assessment_id : record.assessment_id ,
      user_id : record.user_id
    }
    
    axios.post( `${AssesmentUrl}user_description_report`, body )
    .then(( res ) => {
      console.log(res.data, 'user_description_report');
      
      if( res.data.status === 200 ){
        setViewReason(res.data.description_response);
        setViewReasonModal(true);
      } else{
        message.error('Failed to view the reasons')
      }

    }) .catch(( err ) => {
      message.error(err.message);
    })
  }

  const reasonModalCancel = () => {
    setViewReasonModal(false);
  }


  return (
    <div className='scoresMainDiv' >
      <Modal className='viewReasonModalClass' title='Justified Reasons' open={viewReasonModal} onCancel={reasonModalCancel} footer={false} >
        { viewReason.map(( item, index ) => (
        <Card className={ item.description_score == 0 ? 'red-viewReasonCard' : 'green-viewReasonCard'} key={index}>
          <b className={`${item.description_score == 0 ? 'red' : 'green'}descriptionScoreClass`}>Score for description : {item.description_score}</b>
          <p><b>Question : </b>{item.question}</p>
          <div className='wholeDescriptionClass'>
            <div className='userDescClass'>
              <b style={{ margin: 0 }} >User Description</b>
              <p>{item.user_description}</p>
            </div>
            <div className='correctDescClass'>
              <b style={{ margin: 0 }}>Correct Description</b>
              <p>{item.correct_description}</p>
            </div>
          </div>
        </Card>
        ))

        }
      </Modal>
      <div className='reportsSideHeading'>
        <h4 className='nonActiveHeading' onClick={() => handleRoute('/coursesactivityreports')} >Courses Activity Report</h4>
        <h4 className='nonActiveHeading' onClick={() => handleRoute('/trainingreports')} >Training Report</h4>
        <h4 className='nonActiveHeading' style={{ cursor: 'not-allowed' }} 
        // onClick={() => handleRoute('/trainingnoncompletionreports')} 
        >Training Non-compliance Report</h4>
        <h4 className='nonActiveHeading' style={{ cursor: 'not-allowed' }}
        //  onClick={() => handleRoute('/userreports')}
        >User Report</h4>
        <h4 className='activeHeading' onClick={() => handleRoute('/reports')} >Courses Report</h4>
      </div>
      <h2>Reports</h2>
      <div>
        <Row style={{ marginBottom: 10 }}>
          <Col span={5} offset={3} >
            <RangePicker style={{ height: '36px' }} onChange={handleDateRangeChange} />
          </Col>
          <Col span={5} offset={1}>
            <Input placeholder='Search by course' className='searchInput'
              onChange={(e) => setSearchMail(e.target.value)}
            />
          </Col>
          <Col span={5} offset={1}>
            <Input placeholder='Search by Employee Name' className='searchInput'
              onChange={(e) => setSearchEmployee(e.target.value)}
            />
          </Col>
          <Col offset={1} span={3}>
            <Button style={{ width: '100%' }} type="primary" onClick={handleDownload}>Download Report</Button>
          </Col>
        </Row>
        <p
          style={{
            marginLeft: 8,
            marginTop: 23
          }}
        >
          {hasSelected ? `(Selected ${userData.length} user)` : ''}
        </p>
        <Table
          // dataSource={userData}
          dataSource={clearTableData}
          columns={columns}
          pagination={{
            position: ['bottomCenter'],
            showSizeChanger: false,
          }} 
          rowSelection={rowSelection}
        // columns={userColumn}
        />
      </div>
    </div>
  )
}

export default ScoreUsers