import React, { useState, useEffect } from "react";
import { Col, Row, Card, Button, Modal, Input, Select, message, Checkbox, DatePicker, Switch, Pagination } from 'antd'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import AWS from 'aws-sdk';
import { BaseUrl, REACT_APP_ACESS_KEY_ID, REACT_APP_SECRET_ACESS_KEY } from "../Constants/Constants";
import TextArea from "antd/es/input/TextArea";
import './TrainingModule.css'
import moment from "moment";
import dayjs from "dayjs";
// import { convertTimeToEndOfDay, getDatesBetween } from "../Functions/AWSFunction";

const { RangePicker } = DatePicker;

const TrainingModule = () => {

  const { Meta } = Card;
  const navigate = useNavigate();
  const s3 = new AWS.S3();

  const [courseModal, setCourseModal] = useState(false);
  const [courseValue, setCourseValue] = useState("");
  const [teamId, setTeamId] = useState(null);
  const [course, setcourse] = useState([])
  const [Loading, setLoading] = useState(false)
  const [file, setfile] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [TeamOptions, setTeamOptions] = useState([])
  const [CourseOptions, setCourseOptions] = useState([])
  const [arrayFiles, setarrayFiles] = useState("");
  const [showInactive, setShowInactive] = useState(false);
  const Profile = localStorage.getItem("Profile");
  const ParsedProfile = JSON.parse(Profile);

  const companyId = localStorage.getItem('companyId');

  // console.log(companyId.replace(/^"|"$/g, ''), 'companyId')
  console.log(companyId, 'companyId')


  AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.Credentials({
      accessKeyId: REACT_APP_ACESS_KEY_ID,
      secretAccessKey: REACT_APP_SECRET_ACESS_KEY,
      s3ForcePathStyle: true,
    }),
  });



  const CourseTable = () => {
    setLoading(true)
    axios
      .get(`${BaseUrl}trainings/getAllTraining`)
      .then((res) => {
        setcourse(res.data.data);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const trainingInactive = () => {
    setLoading(true)
    axios
      .get(`${BaseUrl}trainings/getAllInActiveTraining`)
      .then((res) => {
        setcourse(res.data.data);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => {

    if (showInactive) {
      trainingInactive();
    } else {
      CourseTable();
    }
    teamdata();

  }, [showInactive])


  useEffect(() => {
    axios.get(`${BaseUrl}courses/findAll`)
      .then((res) => {

        // console.log(res.data.data, 'findAllCourses')
        // setcourse(res.data.data)
        // settransformedData(res.data.data)
        // setTitle("All")
        const transformedArray = res.data.data.map(item => ({
          label: item.course_title,
          value: item.course_id,
        }));
        console.log(transformedArray, 'findAllCourses')
        setCourseOptions(transformedArray)
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [])



  useEffect(() => {
    if (file.length > 0) {
      const fileNames = file.map((i) => `https://trainingnexus.s3.amazonaws.com/DLP/trainingmoduleimg/${i.name}`);
      setarrayFiles(fileNames[0]);
    }
  }, [file]);


  let teamdata = () => {
    axios
      .get(`${BaseUrl}teams`)
      .then((res) => {
        const transformedArray = res.data.map(item => ({
          label: item.Team_name.toUpperCase(),
          value: item.team_id,
        }));
        setTeamOptions(transformedArray)
      })
      .catch((err) => {
        console.log(err.message)
      })
  }

  const filteredCourses = showInactive
    ? course.filter(course => !course.status) // Show inactive courses
    : course.filter(course => course.status);

  const handleCheckboxChange = (e) => {
    setShowInactive(e.target.checked);
  };

  console.log(filteredCourses, ' showInactive')


  const [userReminder, setUserReminder] = useState(true);
  const [managerReminder, setManagerReminder] = useState(true);
  const [trainingDescription, setTrainingDescription] = useState('');
  const [trainingInstruction, setTrainingInstruction] = useState('');
  const [firstReminder, setFirstReminder] = useState('');
  const [secondReminder, setSecondReminder] = useState('');
  const [trainingStartDate, setTrainingStartDate] = useState('');
  const [trainingEndDate, setTrainingEndDate] = useState('');
  const [rangePickerVal, setRangePicerVal] = useState(null);
  const [statusVal, setStatusVal] = useState(true);
  const [errors, setErrors] = useState(false);
  const [inactiveReason, setInactiveReason] = useState(statusVal ? "Active" : "");
  const [trainingUpdateModal, setTrainingUpdateModal] = useState(false);
  const [cardTrainingId, setCardTrainingId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(11);

  console.log(userReminder, 'userReminder', managerReminder)

  const cancelCourseModal = () => {
    setCourseModal(false);
    setErrors(false);
    // setUserReminder(false);
    // setManagerReminder(false);
    setTrainingDescription('');
    setTrainingInstruction('');
    setFirstReminder('');
    setSecondReminder('');
    setTrainingStartDate('');
    setTrainingEndDate('');
    setTeamId(null);
    setCourseValue('')
    setStatusVal(true);
    setRangePicerVal(null);
    setUserReminder(true);
    setManagerReminder(true);
  }


  const handleAddCourse = () => {
    setErrors(true);
    if (courseValue !== '' && teamId !== '' && trainingDescription !== '' && trainingStartDate !== '' && trainingEndDate !== ''
      && trainingStartDate !== 'Invalid date' && trainingEndDate !== 'Invalid date' && teamId !== null && inactiveReason !== '') {

      let courseBody = {
        courseId: teamId
      }
      axios.post(`${BaseUrl}courses/findOne`, courseBody)
        .then((res) => {

          let courseImage = res.data.data[0].course_img;

          if (res.data.statuscode === 200) {
            let body = {
              companyId: companyId,
              courseId: teamId,
              trainingTitle: courseValue,
              createdBy: ParsedProfile.user_id,
              trainingDescription: trainingDescription,
              trainingInstruction: trainingInstruction,
              status: statusVal,
              inActiveReason: inactiveReason,
              trainingImg: courseImage,
              trainingStartDate: trainingStartDate,
              trainingEndDate: trainingEndDate,
              reminderNotification: userReminder,
              escalationReminder: managerReminder
            }

            axios.post(`${BaseUrl}trainings`, body)
              .then((res) => {
                if (res.data.statuscode === 200) {
                  message.success('Training Created!');
                  setCourseModal(false);
                  setTimeout(() => {
                    // navigate(`/assigntrainings/all/${res.data.data[0].training_id}`);
                  }, 1000)
                } else {
                  message.error(`can't able to create a course!`)
                }
              })
              .catch((err) => {
                console.log(err, "upload err");
              });
          }



        }).catch((err) => {
          console.log(err.message)
        })


    } else {
      message.error('Please fill in all required fields');
    }
  }

  const handleUserReminder = (checked) => {
    if (checked) {
      setUserReminder(true);
    } else {
      setUserReminder(false);
    }
  }
  const handleManagerReminder = (checked) => {
    if (checked) {
      setManagerReminder(true);
    } else {
      setManagerReminder(false);
    }
  }


  const getDatesBetween = (startDate, endDate) => {
    let dates = [];
    let currentDate = moment(startDate);
    const end = moment(endDate);

    while (currentDate <= end) {
      dates.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'days');
    }

    return dates;
  };

  function convertTimeToEndOfDay(dateTimeString) {

    console.log(dateTimeString, 'dateTimeString')

    if (dateTimeString === 'Invalid date') {
      return null;
    }


    const dateTime = new Date(dateTimeString);

    // if (isNaN(dateTime.getTime())) {
    //   throw new Error('Invalid dateTimeString format');
    // }

    dateTime.setHours(23, 59, 59, 999); // Set to end of day
    const formattedDateTime = dateTime.toISOString().split('T')[0] + ' 23:59:59'; // Format as YYYY-MM-DD 23:59:59

    return formattedDateTime;
  }

  const handleTrainingDuration = (date, dateString) => {

    // const [startDate, endDate] = dateString;
    // const datesBetween = getDatesBetween(startDate, endDate);

    const startDateString = moment(dateString[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const endDateString = moment(dateString[1]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const converEndDate = convertTimeToEndOfDay(endDateString);

    setTrainingStartDate(startDateString);
    setTrainingEndDate(converEndDate);
    setRangePicerVal(date);


  }
  console.log(trainingStartDate, 'trainingEndDate--->', trainingEndDate)


  const dateFormat = 'YYYY-MM-DD';
  const [defaultStartDate, setDefaultStartDate] = useState('');
  const [defaultEndDate, setDefaultEndDate] = useState('');


  const handleCard = (e) => {
    setCardTrainingId(e.training_id)
    if (e.training_id !== '') {
      let body = {
        training_id: e.training_id
      }
      setTrainingUpdateModal(true);

      axios.post(`${BaseUrl}trainings/findOneTraining`, body)
        .then((res) => {
          setLoading(true);
          console.log(res.data, 'updateTrainingRes')
          if (res.data.statuscode === 200) {

            let response = res.data.data;
            setCourseValue(response.training_title);
            setTeamId(response.course_id.course_id);
            setTrainingDescription(response.training_description);
            setTrainingInstruction(response.training_instruction);
            setInactiveReason(response.inactive_reason);
            // setTrainingStartDate(response.training_start_date);
            // setTrainingEndDate(response.training_end_date);
            setStatusVal(response.status)
            setLoading(false);
            setUserReminder(response.reminder_notification);
            setManagerReminder(response.escalation_reminder);
            setTrainingStartDate(response.training_start_date);
            setTrainingEndDate(response.training_end_date);

            let startDateDB = response.training_start_date;
            let startDate = startDateDB.split('T')[0];

            let endDateDB = response.training_end_date;
            let endDate = endDateDB.split('T')[0];

            // let dayjsStart = dayjs(startDateDB, dateFormat)

            // console.log(dayjsStart ,'startDateDBEntry')



            setDefaultStartDate(startDate);
            setDefaultEndDate(endDate);

          }
        })
        .catch((err) => {
          console.log(err.message);
          setLoading(false);
        })
    }

  }

  console.log(defaultStartDate, 'defaultStartDate')

  const handleTrainingUpdate = () => {

    console.log(courseValue, 'courseValue', teamId, 'teamId', trainingStartDate, 'trainingStartDate', trainingEndDate)

    setErrors(true);

    if (courseValue !== '' && teamId !== '' && trainingDescription !== '' && trainingStartDate !== '' && trainingEndDate !== ''
      && trainingStartDate !== 'Invalid date' && trainingEndDate !== 'Invalid date' && teamId !== null && inactiveReason !== '') {
      let body = {
        trainingId: cardTrainingId,
        trainingDescription: trainingDescription,
        trainingInstruction: trainingInstruction,
        status: statusVal,
        inActiveReason: inactiveReason,
        reminderNotification: userReminder,
        escalationReminder: managerReminder
      }

      axios.post(`${BaseUrl}trainings/update`, body)
        .then((res) => {
          setTrainingUpdateModal(false);

          if (showInactive) {
            trainingInactive();
          } else {
            CourseTable();
          }
        })
        .finally(() => {
          setTrainingUpdateModal(false);
          if (showInactive) {
            trainingInactive();
          } else {
            CourseTable();
          }
        })
        .catch((err) => {
          console.log(err.message);
          setTrainingUpdateModal(false);
        })
    } else {
      message.error('Please fill the missed fields!');
    }
  }

  const cancelTrainingModal = () => {
    setTrainingUpdateModal(false);
    setErrors(false);
    // setUserReminder(false);
    // setManagerReminder(false);
    setTrainingDescription('');
    setTrainingInstruction('');
    setFirstReminder('');
    setSecondReminder('');
    setTrainingStartDate('');
    setTrainingEndDate('');
    setTeamId(null);
    setCourseValue('')
    setStatusVal(true);
    setDefaultStartDate('');
    setDefaultEndDate('');
    setUserReminder(true);
    setManagerReminder(true);
  }

  useEffect(() => {
    if( !showInactive && currentPage === 1){
      setPageSize(11)
    }else {
      setPageSize(12)
    }
  }, [currentPage,showInactive])
  const handleFirstReminder = (date, dateString) => {
    const convertedDate = moment(dateString).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    setFirstReminder(convertedDate)
  }

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  console.log(currentPage, 'currentPage')
  const paginatedCourses = filteredCourses.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  // const validateForm = () => {
  //   const newErrors = {};

  //   if (!courseValue) newErrors.courseValue = 'Training Name is required';
  //   if (!teamId) newErrors.teamId = 'Select Course is required';
  //   if (!trainingDescription) newErrors.trainingDescription = 'Training Description is required';
  //   if (!trainingInstruction) newErrors.trainingInstruction = 'Training Instruction is required';
  //   // if (!trainingDuration) newErrors.trainingDuration = 'Training Duration is required';
  //   if (userReminder) {
  //     if (!firstReminder) newErrors.firstReminderDate = 'First Reminder Date is required';
  //     if (!secondReminder) newErrors.secondReminderDate = 'Second Reminder Date is required';
  //   }

  //   setErrors(newErrors);
  //   return Object.keys(newErrors).length === 0;
  // };

  return (
    <div className="Courses-main">

      <Modal  title='Add Training' open={courseModal} onCancel={cancelCourseModal} onOk={handleAddCourse}>
        <h3>Training Name <span className="requiredStar">*</span></h3>
        <Input placeholder="Training Module Name" value={courseValue} onChange={(e) => setCourseValue(e.target.value)} />
        {errors && courseValue === '' ? <p style={{ color: 'red' }}>Training name is required</p> : <></>}
        <h3>Select Course <span className="requiredStar">*</span></h3>
        <Select
          placeholder='Select Course'
          style={{ width: '472px' }}
          onChange={(value) => setTeamId(value)}
          options={CourseOptions}
          value={teamId}
        />
        {errors && teamId === '' || errors && teamId === null ? <p style={{ color: 'red' }}>Course is required</p> : <></>}
        <h3>Training Description <span className="requiredStar">*</span></h3>
        <TextArea placeholder="Training Description"
          value={trainingDescription}
          onChange={(e) => setTrainingDescription(e.target.value)}
          rows={3} />
        {errors && trainingDescription === '' ? <p style={{ color: 'red' }}>Training description is required</p> : <></>}
        <h3>Training Instruction
          {/* <span className="requiredStar">*</span> */}
        </h3>
        <TextArea placeholder="Training Instruction"
          value={trainingInstruction}
          onChange={(e) => setTrainingInstruction(e.target.value)}
          rows={3} />
        {/* {errors && trainingInstruction === '' ? <p style={{ color: 'red' }}>Training instruction is required</p> : <></>} */}
        <h3>Training Duration <span className="requiredStar">*</span></h3>
        <RangePicker onChange={handleTrainingDuration}
          // defaultValue={null}
          value={rangePickerVal}
        />
        {
          errors && trainingStartDate === '' || errors && trainingStartDate === 'Invalid date' ?
            <p style={{ color: 'red' }}>Training duration is required</p> : <></>
        }
        <h3>User Reminder Notification <span className="requiredStar">*</span></h3>
        <div className="userReminderClass">
          <Switch defaultChecked onChange={handleUserReminder} value={userReminder} />
          <p>{userReminder ? 'Yes' : 'No'}</p>
        </div>
        {/* {
          userReminder ?
            <div className="setReminderDiv">
              <h3>Set First Reminder Date</h3>
              <DatePicker 
              onChange={handleFirstReminder}
              // onChange={(date, dateString) => setFirstReminder(dateString)} 
              />
              <h3>Set Second Reminder Date</h3>
              <DatePicker onChange={(date, dateString) => setSecondReminder(dateString)} />
            </div>
            :
            <></>
        } */}

        <div>
          <h3>Reminder With Escalation <span className="requiredStar">*</span></h3>
          <p style={{ color: 'red' }} >(<strong>Note : </strong> This will send reminder to the user & escalation to the manager )</p>
        </div>
        <div className="userReminderClass">
          <Switch onChange={handleManagerReminder}
            value={managerReminder}
          />
          <p>{managerReminder ? 'Yes' : 'No'}</p>
        </div>
        <h3>Status</h3>
        <Select
          style={{
            width: '150px'
          }}
          value={statusVal}
          options={[
            {
              label: 'Active',
              value: true
            },
            {
              label: 'Inactive',
              value: false
            }
          ]}
          onChange={(value) => {
            setStatusVal(value)
            if (value) {
              setInactiveReason('Active');
            } else {
              setInactiveReason('');
            }
          }}
        />
        {
          statusVal ?
            <></>
            :
            <div>
              <h3>Reason for Inactive</h3>
              <TextArea value={inactiveReason} onChange={(e) => setInactiveReason(e.target.value)} rows={3} placeholder="Reason for incactive" />
              {
                errors && !statusVal && inactiveReason === '' ? <p style={{ color: 'red' }}>Inactive reason is required</p> : <></>
              }
            </div>

        }
      </Modal>


      <Modal className="addTrainingModal" title='Edit Training' footer={null} open={trainingUpdateModal} onCancel={cancelTrainingModal} >
        <h3>Training Name <span className="requiredStar">*</span></h3>
        <Input placeholder="Training Module Name" value={courseValue} disabled onChange={(e) => setCourseValue(e.target.value)} />
        {errors && courseValue === '' ? <p style={{ color: 'red' }}>Training name is required</p> : <></>}
        <h3>Select Course <span className="requiredStar">*</span></h3>
        <Select
          placeholder='Select Course'
          style={{ width: '472px' }}
          onChange={(value) => setTeamId(value)}
          options={CourseOptions}
          value={teamId}
          disabled
        />
        {errors && teamId === '' || errors && teamId === null ? <p style={{ color: 'red' }}>Course is required</p> : <></>}
        <h3>Training Description <span className="requiredStar">*</span></h3>
        <TextArea placeholder="Training Description"
          value={trainingDescription}
          onChange={(e) => setTrainingDescription(e.target.value)}
          rows={3} />
        {errors && trainingDescription === '' ? <p style={{ color: 'red' }}>Training description is required</p> : <></>}
        <h3>Training Instruction
          {/* <span className="requiredStar">*</span> */}
        </h3>
        <TextArea placeholder="Training Instruction"
          value={trainingInstruction}
          onChange={(e) => setTrainingInstruction(e.target.value)}
          rows={3} />
        {/* {errors && trainingInstruction === '' ? <p style={{ color: 'red' }}>Training instruction is required</p> : <></>} */}
        <h3 style={{ marginBottom: 7 }}>Training Duration <span className="requiredStar">*</span></h3>
        {/* <RangePicker defaultValue={[ dayjs(defaultStartDate, dateFormat), dayjs(defaultEndDate, dateFormat) ]} format={dateFormat} 
        // onChange={handleTrainingDuration}
         disabled />
        {
          errors && trainingStartDate === '' || errors && trainingStartDate === 'Invalid date' ?
            <p style={{ color: 'red' }}>Training duration is required</p> : <></>
        } */}
        <div style={{ display: 'flex' }}>
          <div>
            <h4 style={{ margin: 0 }} >Start date</h4>
            <h4 style={{ color: '#797979', margin: 0 }} >{defaultStartDate} </h4>
          </div>
          <div style={{ marginLeft: '12%' }}>
            <h4 style={{ margin: 0 }} >End date</h4>
            <h4 style={{ color: '#797979', margin: 0 }} >{defaultEndDate}</h4>

          </div>
        </div>
        <h3>User Reminder Notification</h3>
        <div className="userReminderClass">
          <Switch onChange={handleUserReminder} value={userReminder} />
          <p>{userReminder ? 'Yes' : 'No'}</p>
        </div>
        <div>
          <h3>Reminder With Escalation</h3>
          <p style={{ color: 'red' }} >(<strong>Note : </strong> This will send reminder to the user & escalation to the manager )</p>
        </div>
        <div className="userReminderClass">
          <Switch onChange={handleManagerReminder} value={managerReminder} />
          <p>{managerReminder ? 'Yes' : 'No'}</p>
        </div>
        <h3>Status</h3>
        <Select
          style={{
            width: '150px'
          }}
          value={statusVal}
          options={[
            {
              label: 'Active',
              value: true
            },
            {
              label: 'Inactive',
              value: false
            }
          ]}
          onChange={(value) => {
            setStatusVal(value)
            if (value) {
              setInactiveReason('Active');
            } else {
              setInactiveReason('');
            }
          }}
        />
        {
          statusVal ?
            <></>
            :
            <div>
              <h3>Reason for Inactive</h3>
              <TextArea value={inactiveReason} onChange={(e) => setInactiveReason(e.target.value)} rows={3} placeholder="Reason for incactive" />
              {
                errors && !statusVal && inactiveReason === '' ? <p style={{ color: 'red' }}>Inactive reason is required</p> : <></>
              }
            </div>

        }
        <div className="ModalButtons" >
          <Button onClick={cancelTrainingModal}>Cancel</Button>
          <Button className="updateModalButton" onClick={handleTrainingUpdate}>Update</Button>
        </div>
      </Modal>

      <div className="addCourceandHeading">
        <h2>Training Module</h2>
        {/* <Button
            onClick={() => setCourseModal(true)}
            className="addCourseButton">Add Course</Button> */}
        <Checkbox onChange={handleCheckboxChange}>Show Inactive Trainings</Checkbox>
      </div>

      {
        Loading ?
          <div className='Loader' >

            <img src='/Loader/Ellipsis-2.9s-200px.svg'>
            </img>
          </div>
          :
          <>
            <Row className="manageChatbotRow">
              {
                !showInactive && currentPage === 1 &&
                <Col>
                  <Card
                    onClick={() => {
                      setCourseModal(true);
                      // navigate('/courses/00011')
                    }
                    }
                    hoverable
                    className="cards-addCourseCards"
                    style={{
                      width: 200,
                      padding: 2,
                    }}
                  >
                    <AddIcon
                      style={{
                        fontSize: '83px',
                        margin: 0
                      }}
                      className="addIconClass" />
                    <h3 style={{ margin: 0 }}>Create New Training</h3>
                  </Card>
                </Col>
              }

              {filteredCourses.length > 0 ? (
                <>
                  {paginatedCourses.map((item, key) => {
                    let training_end_date_dbVal = item?.training_end_date.split('T');
                    let training_end_date = training_end_date_dbVal[0];
                    
                    // Split the date into parts
                    let dateParts = training_end_date.split('-');
                    
                    // Rearrange the parts to dd/mm/yyyy
                    let formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
                    console.log(training_end_date, "item_training_end_date")
                    return (
                      <div className="Course-card" key={key}>
                        <Col span={6} className="manageBotCardCol">
                          <Card
                            onClick={() => handleCard(item)}
                            hoverable
                            className="cards"
                            style={{
                              width: 200,
                                                padding: 2,
                            }}
                            cover={
                              <img
                                src={item.training_img != null && item.training_img != "" ? item.training_img : "/icons/CourseIcons.png"}
                                alt="Bot Image"
                                // src="icons/CourseIcons.png"
                                height={130}
                              />
                            }
                          >
                            {/* <Meta
                                title={
                                  item.name != ""
                                    ? item.name
                                    : `Course ${item.num}`
                                }
                              // title={'Bot' + " " + (key + 1)}
                              /> */}


                            <Meta title={item.training_title || `Training ${item.num}`} />
                            <p className="endDateCardClass"><strong>End date</strong> - {formattedDate}</p>
                          </Card>
                        </Col>
                      </div>
                    );
                  })}

                </>
              ) : (
                <div 
                // className="noCourseDIv"
                className={ showInactive ? 'noCourseDIv' : 'noourseDivActive' }
                >
                  <h3 className="noCourseHeading">No Trainings</h3>
                </div>
              )}

            </Row>
            <Pagination
              onChange={handlePaginationChange}
              current={currentPage}
              pageSize={pageSize}
              showSizeChanger={false}
              total={filteredCourses.length}
              style={{ position: 'relative', bottom: '0px', left: '45%' }}
            />
          </>
      }
    </div>
  );
};

export default TrainingModule