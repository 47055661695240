// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userListMainDiv{
    margin-top: 70px;
    padding: 25px;
}
.userListMainDiv .ant-table-cell{
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/Masters/Analytics/UserList/UserList.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;AACjB;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".userListMainDiv{\n    margin-top: 70px;\n    padding: 25px;\n}\n.userListMainDiv .ant-table-cell{\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
