import React, { useState, useEffect } from 'react';
import { Card, Input, Button, Upload, message, Modal, Progress, Switch } from 'antd';
import { PlusOutlined, DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { AssesmentUrl, BaseUrl, REACT_APP_ACESS_KEY_ID, REACT_APP_SECRET_ACESS_KEY } from '../../../Constants/Constants';
import { EditOutlined } from '@mui/icons-material';
import AWS from 'aws-sdk';
import { uploadToS3 } from '../../../Functions/AWSFunction';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const { Dragger } = Upload;

const CourseEditor = () => {

  const profileData = JSON.parse(localStorage.getItem('Profile'));
  const userEmail = profileData.email;
  console.log(userEmail, 'profileData');

  const [parts, setParts] = useState([
    {
      id: 1,
      name: '',
      number: 'Part 1',
      modules: [
        {
          id: 1,
          name: 'Module 1',
          trainingModule: "",
          trainingModuleType: "",
          trainingModuleDuration: ""
        },
      ],
    },
  ]);

  const [courseName, setCourseName] = useState("");
  const [titleModal, setTitleModal] = useState(false);
  const [updateCourse, setUpdateCourse] = useState('');
  // const [ arrayFiles, setarrayFiles ] = useState([]);
  const [file, setfile] = useState([]);
  const [errorFlag, setErrorFlag] = useState(false);
  const [Percent, setPercent] = useState('0');
  const [vectorError, setVectorError] = useState("");
  const [loading, setLoading] = useState(false);
  const [AssesmentUrls, setAssesmentUrls] = useState([]);
  const [session_id, setSessionId] = useState("");
  const [ statusLoading, setStatusLoading ] = useState(false);
  const [ courseStatus, setCourseStatus ] = useState(false);

  const { courseId } = useParams();


  const navigate = useNavigate();
  useEffect(() => {

    let body = {
      courseId: courseId
    }

    axios.post(`${BaseUrl}courses/findOne`, body)
      .then((res) => {
        console.log(res.data, 'findOneResponse');
        if (res.data.data.length != 0) {
          setCourseName(res.data.data[0].course_title);
          setCourseStatus(res.data.data[0].is_active);
        }
      })
      .catch((err) => {
        console.log(err.message, 'findOne API Error')
      })
  }, [])



  const addPart = () => {
    setParts((prevParts) => [
      ...prevParts,
      { id: prevParts.length + 1, number: `Part ${prevParts.length + 1}`, modules: [] },
    ]);
  };

  const addModule = (partId) => {
    // Find the part with the specified id
    const targetPart = parts.find((part) => part.id === partId);

    if (targetPart) {
      // Increment the module count within the target part
      const moduleCount = targetPart.modules.length + 1;

      // Add a new module to the specified part with the incremented count
      const updatedParts = parts.map((part) =>
        part.id === partId
          ? {
            ...part,
            modules: [...part.modules, { id: moduleCount, name: '', trainingModule: "", trainingModuleType: "", trainingModuleDuration: "" }],
          }
          : part
      );

      setParts(updatedParts);
    }
  };

  const deletePart = (partId) => {
    // Delete the specified part
    const updatedParts = parts.filter((part) => part.id !== partId);
    setParts(updatedParts);
  };

  const deleteModule = (partId, moduleId) => {
    // Delete the specified module from the specified part
    const updatedParts = parts.map((part) =>
      part.id === partId
        ? {
          ...part,
          modules: part.modules.filter((module) => module.id !== moduleId),
        }
        : part
    );
    setParts(updatedParts);
  };


  AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.Credentials({
      accessKeyId: REACT_APP_ACESS_KEY_ID,
      secretAccessKey: REACT_APP_SECRET_ACESS_KEY,
      s3ForcePathStyle: true,
    }),
  });

  const s3 = new AWS.S3();
  useEffect(() => {


    // const fileNames = file.name;
    // setarrayFiles(fileNames);

    const FilenameList = file.map((i) => `https://trainingnexus.s3.amazonaws.com/DLP/${courseId}/${i.name}`);
    setAssesmentUrls(prevFileNameList => {
      const uniqueUrls = FilenameList.filter(url => !prevFileNameList.includes(url));
      return [...prevFileNameList, ...uniqueUrls]
    }
    )

  }, [file]);


  console.log(AssesmentUrls, 'uploadedFiles');


  const uploadFileToS3 = (filess, partId, moduleId, trainingModuleDuration) => {
    if (filess.length === 0) {
      setVectorError('Please Upload Document!');
      return;
    }
    console.log("reached", partId, "part", moduleId)
    setLoading(true);
    setPercent('0')

    const uploadPromises = filess.map((file) => {
      // const fileName = `https://trainingnexus.s3.amazonaws.com/DLP/${courseId}/${file.name}`
      const params = {
        Bucket: 'trainingnexus',
        Key: `DLP/${courseId}/${file.name}`,
        Body: file,
      };

      return new Promise((resolve, reject) => {
        s3.upload(params, { partSize: 5 * 1024 * 1024, queueSize: 1 })
          .on('httpUploadProgress', (progress) => {
            const uploadedPercentage = Math.round((progress.loaded / progress.total) * 100);
            setPercent(uploadedPercentage);
          })
          .send((err, data) => {
            if (err) {
              reject(err);
            } else {
              resolve(data);
              // debugger;
              const updatedParts = parts.map((part) =>
                part.id === partId
                  ? {
                    ...part,
                    modules: part.modules.map((module) => {
                      let type = data.Location.split('.').slice(-1);

                      const moduleDuration = `${Math.floor(trainingModuleDuration / 60)}:${Math.floor(trainingModuleDuration % 60).toString().padStart(2, '0')}`
                      console.log(part.id, 'videoType', partId)

                      return (

                        module.id === moduleId ? { ...module, trainingModule: data.Location, trainingModuleType: type[0], trainingModuleDuration: moduleDuration } : module
                      )
                    }
                    ),
                  }
                  : part
              );
              setParts(updatedParts);
              console.log(parts, "chan", data, partId);
            }
          });
      });
    });

    Promise.all(uploadPromises)
      .then(() => {
        console.log("reached1")
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "upload err");
        setLoading(false);
      });


  };


  const props = {
    name: 'file',
    multiple: false,
    // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
      console.log(info, "info")
      const fileObj = info.fileList.map((item) => item.originFileObj);

      console.log(fileObj, 'fileObj');
      uploadFileToS3(fileObj)
      setfile(fileObj)


    },
  };

  const closeTitleModal = () => {
    setTitleModal(false);
  }

  const titleUpdate = () => {

    let body = {
      "courseId": courseId,
      "courseTitle": updateCourse
    }

    axios.post(`${BaseUrl}courses/update`, body)

      .then((res) => {
        console.log(res.data, 'updateResponse');

        if (res.data.statuscode === 200) {
          setCourseName(updateCourse);
          setTitleModal(false);
          message.success('Course name changed successfully!')
        } else {
          setTitleModal(false);
          message.error(`Can't change the course name`)
        }
      }).catch((err) => {
        message.error(err.message)
      })

  }


  console.log(parts, 'katParts')

  const handleCreateParts = () => {

    const postData = {
      coursePartsTraining: parts.map((part) => ({
        courseId: courseId,
        partName: part.name,
        partNumber: part.number,
        training: part.modules.map((module) => ({
          trainingModuleName: module.name,
          trainingModuleDuration: module.trainingModuleDuration, // Add your logic to get module duration
          trainingModuleType: module.trainingModuleType, // Add your logic to get module type
          trainingModule: module.trainingModule
        })),
      })),
    };



    axios.post(`${BaseUrl}course-parts/addParts`, postData)

      .then((res) => {
        console.log(res, 'postDataRes');
        if (res.data.statuscode === 200) {
          // navigate('/assigncourse');
          message.success('Course parts created successfully!');
          navigate('/managecourses');
        } else {
          message.error('Error creating parts!')
        }
      }).catch((err) => {
        console.log(err)
      })

    console.log(postData, 'postData'); // Display the transformed data in the console


    let body = {
      course_id: courseId,
      url: AssesmentUrls,
      mailid: userEmail
    }

    axios
      .post(`${AssesmentUrl}content_extraction`, body)
      .then((res) => {
        setSessionId(res.data.session_id)
        console.log(res)
        console.log(session_id, "session_id")
      })
      .catch((err) => {
        console.log(err, "error")
      })

  }
  const handleCustomRequest = ({ file, onSuccess, onError }) => {
    const isAllowedType = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'audio/mpeg', 'audio/wav'].includes(file.type);


    if (!isAllowedType) {
      message.error("Unsupported file type.");
      return onError("Unsupported file type");
    }

    onSuccess("ok");
  };

  const handleStatusChange = (e) => {
    console.log(e, 'statusCheckedValue')
    setCourseStatus(e)
    setStatusLoading(true);
    let body = {
        courseId : courseId,
        isActive : e
    }

    axios.post(`${BaseUrl}courses/update`, body)
    .then(( res ) => {
        console.log(res.data, 'courseStatusupdate');
        if( res.data.statuscode === 200 ){
            setStatusLoading(false);
            message.success(`course status changed to ${ e ? 'active' : 'inactive' } `)
        } else{
            message.error('Error updating the course status!')
            setStatusLoading(false);
        }

    }) .catch((errr) => {
        message.error(errr.message);
        setStatusLoading(false);
    })

}


  return (
    <div>
      <p className='back'>
        <span onClick={(e) => navigate(`/managecourses`)} style={{ color: '#4096FF', textDecoration: "underline" }}>
          Courses
        </span>
        <span>
          <ArrowForwardIosIcon className='arrow' />
        </span>
        <span style={{ color: '#454545', textDecoration: "" }}>
          {courseName}
        </span>


      </p>
      <Modal open={titleModal} onOk={titleUpdate} onCancel={closeTitleModal}>
        <h3>Change Course Name - {courseName}</h3>
        <Input placeholder='Type the name to be changed' value={updateCourse} onChange={(e) => setUpdateCourse(e.target.value)} />
      </Modal>
      <div className='newCourseTitleDiv'>
        <h3>Course Title - <span className='courseTitleInCourseEditor'>{courseName}</span> </h3>
        <EditOutlined className='editIconForCourseTitle' onClick={() => setTitleModal(true)} />
      </div>
      <div className='statusInactiveDiv'>
                <h3 className='statusheadingClass'>Status</h3>
            <Switch onChange={handleStatusChange} loading={statusLoading} value={courseStatus} checkedChildren="Active" unCheckedChildren='InActive' className='statusSwitchClass' />
            </div>

      <Button className='addPartButton' block type='primary' icon={<PlusOutlined />} onClick={addPart}>
        Add Module
      </Button>
      {parts.map((part) => (
        <Card key={part.id} className='addModuleCard' style={{ width: '100%' }}>
          <div style={{ display: 'flex' }}>
            <h3>{part.number} - </h3>
            <Input value={part.name}
              style={{ marginLeft: '1%', marginTop: '8px' }}
              onChange={(e) => {
                // Update the module name when input changes
                const updatedParts = parts.map((p) =>
                  p.id === part.id
                    ? {
                      ...p,
                      // modules: p.modules.map((m) =>
                      //   m.id === module.id ? { ...m, name: e.target.value } : m
                      // ),
                      name: e.target.value
                    }
                    : p
                );
                setParts(updatedParts);
              }}
            />
          </div>
          <div className='trainingModuleButtons'>
            <Button type='primary' icon={<PlusOutlined />} onClick={() => addModule(part.id)}>
              Add Topic
            </Button>
            <Button
              type='primary'
              danger
              icon={<DeleteOutlined />}
              onClick={() => deletePart(part.id)}
            >
              Delete Module
            </Button>
          </div>

          {part.modules.map((module) => (
            <div className='trainingModuleDiv' key={module.id}>
              <h4 className='moduleTitles trainingModuleNameClass'>Topic</h4>
              <Input
                value={module.name}
                onChange={(e) => {
                  // Update the module name when input changes
                  const updatedParts = parts.map((p) =>
                    p.id === part.id
                      ? {
                        ...p,
                        modules: p.modules.map((m) =>
                          m.id === module.id ? { ...m, name: e.target.value } : m
                        ),
                      }
                      : p
                  );
                  setParts(updatedParts);
                }}
              />

              <h4 className='moduleTitles'>Add Topic Content</h4>
              <Dragger className='moduleUploadDragger'
                name='file'
                action=''
                maxCount={1}
                fileList={file}
                onRemove={() => setfile([])}
                beforeUpload={() => false}
                // customRequest={handleCustomRequest}
                onChange={(info) => {
                  console.log(info, 'addPartsInfo')
                  const fileObj = info.fileList.map((item) => item.originFileObj);
                  if (fileObj.length > 0) {
                    const file = fileObj[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      if (file.type === 'application/pdf') {
                        setfile(fileObj);
                        uploadFileToS3(fileObj, part.id, module.id, null);
                      } else if (file.type === 'video/mp4') {
                        const video = document.createElement('video');
                        video.src = e.target.result;
                        console.log(file.type, 'fileObj')
                        video.onloadedmetadata = () => {
                          const duration = video.duration;
                          console.log(duration, 'durationModules')
                          uploadFileToS3(fileObj, part.id, module.id, duration);
                          setfile(fileObj);
                        };
                      } else if (!errorFlag) {
                        setErrorFlag(true);
                        message.error('Please upload supported format files!');
                        setTimeout(() => setErrorFlag(false), 2000);
                      }
                      // const video = document.createElement('video');
                      // video.src = e.target.result;
                      // video.onloadedmetadata = () => {
                      //   const duration = video.duration;
                      //   uploadFileToS3(fileObj, part.id, module.id, duration);
                      //   setfile(fileObj);
                      // };
                    };
                    reader.readAsDataURL(file);
                  }
                }
                }

              >
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>
                  Click or drag file to this area to upload
                </p>
                <p className='ant-upload-hint'>
                  Note: Upload one file per topic.  Supports .docx, .pdf. .mp3, .wav files
                </p>
              </Dragger>
              <Button
                className='deleteModuleButtons'
                type='primary'
                danger
                icon={<DeleteOutlined />}
                onClick={() => deleteModule(part.id, module.id)}
              >
                Delete Module
              </Button>
            </div>
          ))}
        </Card>
      ))}
      <Button onClick={handleCreateParts} className='SaveBtn' block type='primary'>
        {
          loading == true ?
            <Progress strokeColor={'#fff'} className="progress" percent={Percent} type="circle" />
            :
            <>
              Save
            </>

        }
      </Button>
    </div>
  );
};

export default CourseEditor;
