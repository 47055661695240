import React ,{useEffect} from 'react'
import './Header.css'
import { Avatar, Col, Row, Input,Button, Popover } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import CampaignIcon from '@mui/icons-material/Campaign';



const Header = () => {
  const { Search } = Input;
  let url = window.location.href;
  let splittedUrl = url.split('/')[3];


  // console.log(splittedUrl,"splittedUrl",splittedUrl == "courses");

  const analyticsClassName = splittedUrl === 'analytics' ? 'activeAnalytic' : 'nonActiveAnalytic'

  const navigate = useNavigate()
  const handleClick = (e) => {
if(e == "Masters"){
  // navigate(`/masters`)
}
 if (e == 'Analytics'){
  // navigate(`/analytics`)
}
  }

  useEffect(() => {
    // Reset URL segment state when component mounts
    navigate(window.location.pathname);
  }, [navigate]);
  
  const handleSettings = () => {
    navigate(`/settings`)
  }
  const handleLogout = () => {
    localStorage.setItem("IsAuthenticated", "false");
    navigate('/login')
  }
  const ProfileContent = (
    <div>
      <p className='Contents'
      onClick={handleSettings}
      >
        Settings
      </p>
      <p className='Contents' onClick={handleLogout}>
        Logout
      </p>
    </div>
  )
  const ProfileTitle =(
<>
<Row>
        <Col span={6}>
        
      <Avatar className='headingAvatar' size={35} icon={<UserOutlined />} />
        </Col>
        <Col span={16}>
          <p className='Username'>Admin</p>
        <p className='Email'> admin@gmail.com</p>
        </Col>
      </Row>
</>
  )

  return (
    <div className='headerDiv'>
      <Row>
        <Col span={6}>
          <img width={180} height={45} className='headerLogoImg' src='/Logo/Training Nexus 2.jpg' alt='' />
        </Col>
        <Col span={12} offset={6}>
          <Row className='pageHadingRow'>
            <Col offset={6} span={4}>
              <h3>Dashboard</h3>
            </Col>
            <Col  
            span={3} 
            onClick={() => handleClick('Masters')}>
              <h3 className={splittedUrl == "courses" ? "active" : ""}>
                Masters</h3>
            </Col>
            {/* <Col span={4} onClick={() => handleClick('Analytics')} >
              <h3 className={splittedUrl == "Analytics" ? "active" : ""}>
              Analytics</h3>
            </Col> */}
            <Col span={4}>
              <h3 onClick={() => handleClick('Analytics')} className={analyticsClassName}>Analytics</h3>
            </Col>
            {/* <Col span={5}>
              <Search
                className='headerSearch'
                placeholder='Search any topic'
                allowClear
              />
            </Col> */}
            {/* <Col span={6}>
            </Col> */}
            <Col span={3}>
            <Popover content={ProfileContent} title={ProfileTitle} trigger="click">
              <Avatar className='headingAvatar' size={35} icon={<UserOutlined />} />
    </Popover>
            </Col>
            
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Header