import { PlusOutlined, InboxOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Card, Input, message, Modal, Progress, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import './AddParts.css'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BaseUrl, REACT_APP_ACESS_KEY_ID, REACT_APP_SECRET_ACESS_KEY } from '../../../../Constants/Constants';
import AWS from 'aws-sdk';

const { Dragger } = Upload;

const AddParts = ({ props, addPartSuccess }) => {

    console.log(props, 'AddPartsparts');

    // const { courseId } = useParams();

    const url = window.location.href;
    const courseId = url.split('/')[4];
    console.log(props.length, 'courseId');

    AWS.config.update({
        region: 'us-east-1',
        credentials: new AWS.Credentials({
            accessKeyId: REACT_APP_ACESS_KEY_ID,
            secretAccessKey: REACT_APP_SECRET_ACESS_KEY,
            s3ForcePathStyle: true,
        }),
    });

    const s3 = new AWS.S3();

    const [partModalOpen, setPartModalOpen] = useState(false);
    const [file, setfile] = useState([]);
    const [errorFlag, setErrorFlag] = useState(false);
    const [Percent, setPercent] = useState('0');
    const [loading, setLoading] = useState(false);
    const [partName, setPartName] = useState('');
    const [parts, setParts] = useState([
        {
            id: 1,
            name: null,
            number: `Part ${props.length + 1}`,
            modules: [
                {
                    id: 1,
                    name: 'Topic 1',
                    trainingModule: "",
                    trainingModuleType: "",
                    trainingModuleDuration: ""
                },
            ],
        },
    ]);

    useEffect(() => {
        setParts([
            {
                id: 1,
                name: null,
                number: `Part ${props.length + 1}`,
                modules: [
                    {
                        id: 1,
                        name: 'Topic 1',
                        trainingModule: "",
                        trainingModuleType: "",
                        trainingModuleDuration: ""
                    },
                ],
            },
        ])
    }, [props])

    const handleOpenPartModal = () => {
        setPartModalOpen(true);
    }
    const handleCancelPartModal = () => {
        setPartModalOpen(false);
        setfile([]);
    }

    const addModule = (partId) => {
        // Find the part with the specified id
        const targetPart = parts.find((part) => part.id === partId);

        if (targetPart) {
            // Increment the module count within the target part
            const moduleCount = targetPart.modules.length + 1;

            // Add a new module to the specified part with the incremented count
            const updatedParts = parts.map((part) =>
                part.id === partId
                    ? {
                        ...part,
                        modules: [...part.modules, { id: moduleCount, name: '', trainingModule: "", trainingModuleType: "", trainingModuleDuration: "" }],
                    }
                    : part
            );

            setParts(updatedParts);
        }
    };

    const deleteModule = (partId, moduleId) => {
        // Delete the specified module from the specified part
        const updatedParts = parts.map((part) =>
            part.id === partId
                ? {
                    ...part,
                    modules: part.modules.filter((module) => module.id !== moduleId),
                }
                : part
        );
        setParts(updatedParts);
    };

    console.log(file, 'fileListOfDragger')

    const handleCreateParts = () => {

        if (partName === '' || file.length === 0) {
            message.error('Please fill all the fields & upload documents to proceed!');
        } else {
            const postData = {
                coursePartsTraining: parts.map((part) => ({
                    courseId: courseId,
                    partName: part.name,
                    partNumber: part.number,
                    training: part.modules.map((module) => ({
                        trainingModuleName: module.name,
                        trainingModuleDuration: module.trainingModuleDuration,
                        trainingModuleType: module.trainingModuleType,
                        trainingModule: module.trainingModule
                    })),
                })),
            };

            console.log(postData, 'postData');
            axios.post(`${BaseUrl}course-parts/addParts`, postData)
                .then((res) => {
                    console.log(res.data, 'addPartsResponse')
                    if (res.data.statuscode === 200) {
                        message.success('Course parts added successfully!');
                        addPartSuccess(true);
                        handleCancelPartModal();
                    } else {
                        message.error('Error adding parts!')
                    }
                })
                .catch(err => {
                    message.error(err.message);
                })
        }

    };

    const uploadFileToS3 = (filess, partId, moduleId, trainingModuleDuration) => {
        if (filess.length === 0) {
            //   setVectorError('Please Upload Document!');
            message.error('Please Upload Document!');
            return;
        }
        console.log("reached", partId, "part", moduleId)
        setLoading(true);
        setPercent('0')

        const uploadPromises = filess.map((file) => {
            // const fileName = `https://trainingnexus.s3.amazonaws.com/DLP/${courseId}/${file.name}`
            const params = {
                Bucket: 'trainingnexus',
                Key: `DLP/${courseId}/${file.name}`,
                Body: file,
            };

            return new Promise((resolve, reject) => {
                s3.upload(params, { partSize: 5 * 1024 * 1024, queueSize: 1 })
                    .on('httpUploadProgress', (progress) => {
                        const uploadedPercentage = Math.round((progress.loaded / progress.total) * 100);
                        setPercent(uploadedPercentage);
                    })
                    .send((err, data) => {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(data);
                            // debugger;
                            const updatedParts = parts.map((part) =>
                                part.id === partId
                                    ? {
                                        ...part,
                                        modules: part.modules.map((module) => {
                                            let type = data.Location.split('.').slice(-1);

                                            const moduleDuration = `${Math.floor(trainingModuleDuration / 60)}:${Math.floor(trainingModuleDuration % 60).toString().padStart(2, '0')}`
                                            console.log(part.id, 'videoType', partId)

                                            return (

                                                module.id === moduleId ? { ...module, trainingModule: data.Location, trainingModuleType: type[0], trainingModuleDuration: moduleDuration } : module
                                            )
                                        }
                                        ),
                                    }
                                    : part
                            );
                            setParts(updatedParts);
                            console.log(parts, "chan", data, partId);
                        }
                    });
            });
        });

        Promise.all(uploadPromises)
            .then(() => {
                console.log("reached1")
                setLoading(false);
            })
            .catch((err) => {
                console.log(err, "upload err");
                setLoading(false);
            });


    };



    return (
        <div className='addPartMainDiv'>
            <Modal className='updateAddPartsModal' title='Add Part & Modules' footer={false} onOk={handleCreateParts} open={partModalOpen} onCancel={handleCancelPartModal} >
                {
                    parts.map(part => (
                        <>
                            <div>
                                <Card key={part.id} className='addModuleCard' style={{ width: '100%' }}>
                                    <div style={{ display: 'flex' }}>
                                        <h3>{part.number} - </h3>
                                        <Input value={part.name}
                                            placeholder='Enter Part Name'
                                            style={{ marginLeft: '1%', marginTop: '8px' }}
                                            onChange={(e) => {
                                                // Update the module name when input changes
                                                const updatedParts = parts.map((p) =>
                                                    p.id === part.id
                                                        ? {
                                                            ...p,
                                                            // modules: p.modules.map((m) =>
                                                            //   m.id === module.id ? { ...m, name: e.target.value } : m
                                                            // ),
                                                            name: e.target.value
                                                        }
                                                        : p
                                                );
                                                setParts(updatedParts);
                                                setPartName(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className='trainingModuleButtons'>
                                        <Button type='primary' icon={<PlusOutlined />}
                                            onClick={() => addModule(part.id)}
                                        >
                                            Add Topic
                                        </Button>
                                    </div>

                                    {part.modules.map((module) => (
                                        <div className='trainingModuleDiv' key={module.id}>
                                            <h4 className='moduleTitles trainingModuleNameClass'>Topic</h4>
                                            <Input
                                                value={module.name}
                                                placeholder='Enter Topic Name'
                                                onChange={(e) => {
                                                    // Update the module name when input changes
                                                    const updatedParts = parts.map((p) =>
                                                        p.id === part.id
                                                            ? {
                                                                ...p,
                                                                modules: p.modules.map((m) =>
                                                                    m.id === module.id ? { ...m, name: e.target.value } : m
                                                                ),
                                                            }
                                                            : p
                                                    );
                                                    setParts(updatedParts);
                                                }}
                                            />

                                            <h4 className='moduleTitles'>Add Topic Content</h4>
                                            <Dragger className='moduleUploadDragger'
                                                name='file'
                                                fileList={file}
                                                onRemove={() => setfile([])}
                                                action=''
                                                beforeUpload={() => false}
                                                maxCount={1}
                                                // customRequest={handleCustomRequest}
                                                onChange={(info) => {
                                                    const fileObj = info.fileList.map((item) => item.originFileObj);
                                                    console.log(fileObj, 'addPartModuleInfo')
                                                    if (fileObj.length > 0) {
                                                        const file = fileObj[0];
                                                        const reader = new FileReader();
                                                        reader.onload = (e) => {
                                                            if (file.type === 'application/pdf') {
                                                                setfile(fileObj);
                                                                uploadFileToS3(fileObj, part.id, module.id, null);
                                                            } else if (file.type === 'video/mp4') {
                                                                const video = document.createElement('video');
                                                                video.src = e.target.result;
                                                                console.log(file.type, 'fileObj')
                                                                video.onloadedmetadata = () => {
                                                                    const duration = video.duration;
                                                                    console.log(duration, 'durationModules')
                                                                    uploadFileToS3(fileObj, part.id, module.id, duration);
                                                                    setfile(fileObj);
                                                                };
                                                            }
                                                            else if (!errorFlag) {
                                                                setErrorFlag(true);
                                                                message.error('Please upload supported format files!');
                                                                setTimeout(() => setErrorFlag(false), 2000);
                                                            }
                                                        };
                                                        reader.readAsDataURL(file);
                                                    }
                                                }
                                                }

                                            >
                                                <p className='ant-upload-drag-icon'>
                                                    <InboxOutlined />
                                                </p>
                                                <p className='ant-upload-text'>
                                                    Click or drag file to this area to upload
                                                </p>
                                                <p className='ant-upload-hint'>
                                                    Note: Upload one file per topic.  Supports .docx, .pdf. .mp3, .wav files
                                                </p>
                                            </Dragger>
                                            <Button
                                                className='deleteModuleButtons'
                                                type='primary'
                                                danger
                                                icon={<DeleteOutlined />}
                                                onClick={() => deleteModule(part.id, module.id)}
                                            >
                                                Delete Module
                                            </Button>
                                        </div>
                                    ))}
                                </Card>
                            </div>
                            <div>
                                <Button onClick={handleCreateParts} disabled={loading ? true : false} className='SaveBtn' block type='primary'>
                                    {
                                        loading == true ?
                                            <Progress strokeColor={'#fff'} className="progress" percent={Percent} type="circle" />
                                            :
                                            <>
                                                Add Part {props.length + 1}
                                            </>

                                    }
                                </Button>
                            </div>
                        </>
                    ))
                }
            </Modal>
            <Button icon={<PlusOutlined />} type='primary'
                onClick={handleOpenPartModal}
            >Add Part</Button>
        </div>
    )
}

export default AddParts