import React, { useEffect, useState } from 'react'
import './Teams.css'
import { Row, Col, Modal, Typography,Checkbox } from 'antd'
import Sider from '../../Courses/Sider'
import { EditOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import axios from 'axios'
import randomstring from 'randomstring';
import { Space, Table, Tag, Button, Input, Select, notification } from 'antd';
import { BaseUrl } from '../../Constants/Constants';

const Teams = () => {
    const [Data, setData] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenLogin, setIsModalOpenLogin] = useState(false);
    const [TeamName, setTeamName] = useState("");
    const [ teamDesc, setTeamDesc ] = useState('')
    const [Email, setEmail] = useState('')
    const [Status, setStatus] = useState([])
    const [StatusAdd, setStatusAdd] = useState(true)
    const [api, contextHolder] = notification.useNotification();
    const [Options, setOptions] = useState({})
    const [Password, setPassword] = useState("")
    const [TeamOptions, setTeamOptions] = useState('')
    const [LoadTable, setLoadTable] = useState(false)
    const [showTable, setshowTable] = useState(false)
    const [teamId, setteamId] = useState('')
    const [TeamData, setTeamData] = useState([])
    const [Loading, setLoading] = useState(false);
    const [ manager, setManager ] = useState('');
    const [ managerOption, setManagerOption ] = useState('');
    const [ managerEmail, setManagerEmail ] = useState('');
    const [ managerUserId, setManagerUserId ] = useState('');
    const [showInactive, setShowInactive] = useState(false);

    const companyId = localStorage.getItem('companyId');

    const showModal = (e) => {
        console.log(e, "recordTeamValues", e.email)
        setTeamName(e.Team_name)
        setEmail(e.email)
        setStatus(e.isActive)
        setIsModalOpen(true);
        setteamId(e.team_id);
        setManager(e.manager_username);
        setManagerEmail(e.manager_mail);
    };
    console.log(TeamName, 'TeamName')
    const generatePassword = () => {
        setPassword(randomstring.generate({
            length: 10,
            charset: 'alphanumeric',
        }))

    };

 

    const openNotificationWithIconWarning = (type) => {
        api['warning']({
            message: "Warning!!!",
            description:
                type,
        });
    };
    const openNotificationWithIconSuccess = (type) => {
        console.log('getsSuccess')
        api['success']({
            message: "Mail sent",
            description:
                type,
        });
    };

    
    const handleOk = () => {

        let body = {
            teamId : teamId,
            teamName : TeamName,
            IsActive : Status, 
            managerMail: managerEmail,
            managerUsername: manager
        }

        axios.post(`${BaseUrl}teams/team_update`, body)
        .then(( res ) => {
            console.log(res.data.statuscode, 'updateTeamDetails');

            if( res.data.statuscode==200 ) {
                setTeamName('')
                setEmail('')
                setStatus(true);
                openNotificationWithIconSuccess('Updated successfully!!!');
                setLoadTable(!LoadTable);
                setIsModalOpen(false);
            }
        })
        .catch(( err ) => console.log(err, "teamUpdateError"))  
        

    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setTeamName('')
        setEmail('')
        setStatus("")
    };
    const showModalLogin = (e) => {
        setIsModalOpenLogin(true);


    };

    console.log(Password, "pass")

    useEffect(() => {
        let userUrl = `${BaseUrl}users`

        axios.get(userUrl)
        .then(( res ) => {
            let managers = res.data.filter( item => item.role === 'manager' )
            
            let options = managers.map( item => ({
                label : item.email,
                value : item.email,
                userId : item.user_id
            }) )
            console.log(options, 'userresponse');

            setManagerOption(options);

        })

    }, [])

    const handleOkAdd = () => {
        setLoadTable(false)
        setLoading(true)
        generatePassword()
        let Body = {
            teamName: TeamName,
            companyid: companyId,
            userid: managerUserId,
            IsActive: StatusAdd,
            managerMail: managerEmail,
            managerUsername: manager
        }
        axios
            .post(`${BaseUrl}teams/create`, Body)
            .then((res) => {
                console.log(res, "log")
                if (res.data.status === 204) {
                    setLoading(false)
                    openNotificationWithIconWarning('Invalid ');
                }
                else {
                    openNotificationWithIconSuccess("Team Added Sucessfully")
                    setLoadTable(true)
                    setIsModalOpenLogin(false);

                }
            })
            .catch((err) => {
                // openNotificationWithIconWarning('Invalid Credentials');
                console.log(err, "log")
                if (err.status == 409) {
                    setLoading(false)
                    setIsModalOpenLogin(false);
                    openNotificationWithIconWarning('User Already exist');
                }
            })
    };

    const handleManagerMail = (val, e) => {
            console.log(val, 'managerMailId', e.userId)
            setManagerEmail(val);
            setManagerUserId(e.userId)
    }

    const handleCancelAdd = () => {
        setIsModalOpenLogin(false);
    };
    const OpenTeamview = (e) => {
        console.log(e, "team")
        setTeamName(e.Team_name)
        setLoading(true)

        let body = {
            teamid: e.team_id
        }
        axios
            .post(`${BaseUrl}users/findone`, body)
            .then((res) => {
                console.log(res.data.data.users, 'teamss')
                setTeamData(res.data.data.users)
                setshowTable(true)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const columns = [
        // {
        //     title: 'S.No',
        //     dataIndex: 'key',
        //     key: 'key',
        // },
        {
            title: 'Team Name',
            dataIndex: 'Team_name',
            key: 'Team_name',
            render: (text, record) => <p onClick={(e) => OpenTeamview(record)} className='NameTxtTeam'>{text}</p>,
        },
        {
            title: 'Manager',
            dataIndex: 'manager_username',
            key: 'manager_username',
            align: 'center'
            // render: (text, record) => <p onClick={(e) => OpenTeamview(record)} className='NameTxtTeam'>{text}</p>,
        },
        {
            title: 'Manager Email',
            dataIndex: 'manager_mail',
            key: 'manager_mail',
            align: 'center'
            // render: (text, record) => <p onClick={(e) => OpenTeamview(record)} className='NameTxtTeam'>{text}</p>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <EditOutlined className='Edit' onClick={(e) => {
                        console.log(record, 'teamRecord');

                        showModal(record)}} />
                </Space>
            ),
        },
    ];

    const usercolumns = [
        {
            title: 'S.No',
            dataIndex: 'key',
            key: 'key',
            render: (_, record, index) => <p>{index + 1}</p>,
        },
        {
            title: 'User Name',
            dataIndex: 'username',
            key: 'username',
            render: (text, record) => <p >{text}</p>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => <p >{text}</p>,
        },

    ];


    let companyData = () => {
        axios
            .get(`${BaseUrl}company`)
            .then((res) => {
                console.log(res.data, "options")
                setOptions(res.data)
                const transformedArray = res.data.map(item => ({
                    label: item.company_mail.toUpperCase(),
                    value: item.company_id,
                }));
                setOptions(transformedArray)
                console.log(transformedArray, "transformedArray")
            })
            .catch((err) => {

            })
    }
    console.log(Data, 'TeamData')

    let teamdata = () => {
        setLoading(true)
        axios
            .get(`${BaseUrl}teams`)
            .then((res) => {
                console.log(res.data, "optionsteam")
                setData(res.data)
                setOptions(res.data)
                const transformedArray = res.data.map(item => ({
                    label: item.Team_name.toUpperCase(),
                    value: item.team_id,
                }));
                setTeamOptions(transformedArray)
                console.log(transformedArray, "transformedArrayTeam")
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
            })
    }
    useEffect(() => {
        companyData()
        teamdata()
    }, [LoadTable])
    const handleChange = (value) => {
        console.log(`selected ${value}`);
        setStatus(value)
    };
    const handleChangeTeam = (value) => {
        console.log(`selected ${value}`);
        setteamId(value)
    };

    const filteredTeams = showInactive
    ? Data.filter(Data => !Data.isActive) // Show inactive Datas
    : Data.filter(Data => Data.isActive);

    const handleCheckboxChange = (e) => {
        setShowInactive(e.target.checked);
      };

    return (
        <div className='Users-main'>
            {contextHolder}
            <div className='ModalDiv'>
                <Modal title="Edit Team" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <p style={{ marginTop: 20 }} className='Title' >Team Name</p>
                    <Input placeholder="Team Name"
                        onChange={(e) => setTeamName(e.target.value)}
                        value={TeamName}
                        className='EditInput' />
                    <p className='Title' >Manager Name</p>
                    <Input placeholder="Manager Name"
                        value={manager}
                        onChange={(e) => setManager(e.target.value)}
                        className='EditInput' />
                        <p className='Title' >Manager Email Id</p>
                    <Input placeholder="Manager Mail Id"
                        value={managerEmail}
                        onChange={(e) => setManagerEmail(e.target.value)}
                        className='EditInput' />
                    <p className='Title' >Status</p>
                    <Select
                        defaultValue={Status}
                        value={Status}
                        className='EditInput'
                        onChange={handleChange}
                        options={[
                            {
                                value: true,
                                label: 'Active',
                            },
                            {
                                value: false,
                                label: 'Inactive',
                            }
                        ]}
                    />

                </Modal>
                <Modal title="Add Team" open={isModalOpenLogin} onOk={handleOkAdd} onCancel={handleCancelAdd}>
                    <p style={{ marginTop: 20 }} className='Title' >Team Name</p>
                    <Input placeholder="Team Name"
                        // defaultValue={TeamName}
                        // value={TeamName}
                        onChange={(e) => setTeamName(e.target.value)}
                        className='EditInput' />
                        <p style={{ marginTop: 20 }} className='Title' >Manager Name</p>
                    <Input placeholder="Manager Name"
                        // defaultValue={TeamName}
                        // value={TeamName}
                        onChange={(e) => setManager(e.target.value)}
                        className='EditInput' />
                        <p style={{ marginTop: 20 }} className='Title' >Manager Email</p>
                    {/* <Input placeholder="Manager Email"
                        onChange={(e) => setManagerEmail(e.target.value)}
                        className='EditInput' /> */}
                        <Select options={managerOption} 
                        style={{ width: '100%', height: '50px' }}
                        onChange={handleManagerMail}
                        placeholder='Select Manager Email'
                        />
                    {/* <p className='Title' >Assign members</p> */}
                    {/* <Select
                        // defaultValue={Status}
                        mode="multiple"
                        allowClear
                        placeholder="Select Employees"
                        className='EditInput'
                        onChange={handleChange}
                        options={Options}
                    /> */}
                    {/* <p className='Title' >Map Company</p>
                    <Select
                        defaultValue={Status}
                        value={Status}
                        placeholder="Select Company Name"
                        className='EditInput'
                        onChange={handleChange}
                        options={Options}
                    /> */}


                    <p style={{ marginTop: 20 }} className='Title' >Team Description</p>
                    <Input placeholder="Team Description"
                        // defaultValue={TeamName}
                        // value={TeamName}
                        onChange={(e) => setTeamDesc(e.target.value)}
                        className='EditInput' />

                    {/* <p style={{ marginTop: 20 }} className='Title' >Team Manager  <span style={{ fontSize: "10px", color: "grey" }}>(User to whom all the escalation mails will be sent)</span></p>
                    <Input placeholder="Team Manager"
                        // defaultValue={TeamName}
                        // value={TeamName}
                        onChange={(e) => setTeamName(e.target.value)}
                        className='EditInput' /> */}


                    <p className='Title' >Status</p>
                    <Select
                        // defaultValue={true}
                        defaultValue={true}
                        // value={StatusAdd}
                        // value={Status}
                        className='EditInput'
                        onChange={handleChange}
                        options={[
                            {
                                value: true,
                                label: 'Active',
                            },
                            {
                                value: false,
                                label: 'Inactive',
                            }
                        ]}
                    />


                </Modal>
            </div>

            <div className="addCourceandHeading">
        <h2>Courses</h2>
        {/* <Button
          onClick={() => setCourseModal(true)}
          className="addCourseButton">Add Course</Button> */}
          <Checkbox onChange={handleCheckboxChange}>Show Inactive Teams</Checkbox> 
      </div>

            {
               !showInactive && showTable ?
                    <>
                        <p className='back' onClick={() => setshowTable(false)}>
                            Team/{TeamName}
                        </p>
                        <h2>
                            {TeamName} Team Users List
                        </h2>
                        {
                            Loading ?
                                <div className='Loader' >

                                    <img src='Loader\Ellipsis-2.9s-200px.svg'>
                                    </img>
                                </div>
                                :

                                <Table columns={usercolumns}
                                    dataSource={TeamData}
                                />
                        }
                    </>
                    :
                    <>
                        <h2>
                            Manage Teams
                        </h2>
                        <Button
                            onClick={showModalLogin}
                            className='ADD'
                            type='primary'
                        >
                            Add Team <UsergroupAddOutlined />
                        </Button>
                        {
                            Loading ?
                                <div className='Loader' >

                                    <img src='Loader\Ellipsis-2.9s-200px.svg'>
                                    </img>
                                </div>
                                :
                                <Table  pagination={{
                                    position: ['bottomCenter'],
                                    showSizeChanger: false,
                                  }}  columns={columns} dataSource={filteredTeams} />
                        }
                    </>
            }
        </div>
    )
}


export default Teams