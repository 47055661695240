import React, { useState, useEffect } from "react";
import { Col, Row, Card, Button, Modal, Input, Select, message,Pagination } from 'antd'
import { useNavigate } from 'react-router-dom'
import "./Courses.css";
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import { BaseUrl } from "../../Constants/Constants";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { Checkbox } from 'antd';
import AWS from 'aws-sdk';
import { REACT_APP_ACESS_KEY_ID, REACT_APP_SECRET_ACESS_KEY } from "../../Constants/Constants";

const Courses = () => {

  const { Meta } = Card;
  const navigate = useNavigate();
  const s3 = new AWS.S3();

  const [courseModal, setCourseModal] = useState(false);
  const [courseValue, setCourseValue] = useState("");
  const [teamId, setTeamId] = useState("");
  const [course, setcourse] = useState([])
  const [Loading, setLoading] = useState(false)
  const [file, setFile] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [TeamOptions, setTeamOptions] = useState({})
  const [arrayFiles, setarrayFiles] = useState("");
  const [showInactive, setShowInactive] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [error, setError] = useState(null);
  const companyId = localStorage.getItem('companyId');
  

  AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.Credentials({
      accessKeyId: REACT_APP_ACESS_KEY_ID,
      secretAccessKey: REACT_APP_SECRET_ACESS_KEY,
      s3ForcePathStyle: true,
    }),
  });

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };


  const checkboxchange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const handleCard = (e) => {
    navigate(`/managecourses/${e.course_id}`);
    console.log(e.id , "id")
  }

  const cancelCourseModal = () => {
    setCourseModal(false);
  }

 const CourseTable = () => {
  setLoading(true);
  axios
    .get(`${BaseUrl}courses/findAll`)
    .then((res) => {
      console.log(res.data.data, "courseFindall");

      // Sort the data in descending order based on date and time
      const sortedData = res.data.data.sort((a, b) => {
        // Ensure 'modified_at' is a valid date string
        return new Date(b.created_at) - new Date(a.created_at);
      });

      setcourse(sortedData);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };
  const InActiveCourseTable = () => {
    setLoading(true);
    axios
      .get(`${BaseUrl}courses/findAllInActiveCourses`)
      .then((res) => {
        console.log(res.data.data, "courseFindall");
  
        // Sort the data in descending order based on date and time
        const sortedData = res.data.data.sort((a, b) => {
          // Ensure 'modified_at' is a valid date string
          return new Date(b.modified_at) - new Date(a.modified_at);
        });
  
        setcourse(sortedData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    };

  useEffect(() => {
    if(!showInactive){
      CourseTable();
    } else{
      InActiveCourseTable();
    }
    teamdata()
    
  }, [showInactive])


  useEffect(() => {
    if( currentPage === 1){
      setPageSize(11)
    }else {
      setPageSize(12)
    }
  }, [currentPage])
  useEffect(() => {

    console.log(file, "file")
  
    if(file.length > 0){
      
      const fileNames = file.map((i) => `https://trainingnexus.s3.amazonaws.com/DLP/CourseImage/${i.name}`);
      console.log(fileNames,"arrayfiles")
      setarrayFiles(fileNames[0]);
    }
    

  }, [file]);
  console.log(arrayFiles, "arrayfiles1",file)

  const handleAddCourse = () => {
    if (!courseValue.trim()) {
      setError('Course Title is required.');
      return;
    }
    // Proceed with adding the course
    setError(null);
    if (file.length > 0) {
     
    
    setLoading(true);
    // setPercent('0')

    const uploadPromises = file.map((file) => {
      // const fileName = `https://trainingnexus.s3.amazonaws.com/DLP/${courseId}/${file.name}`
      setCourseModal(false);
      const params = {
        Bucket: 'trainingnexus',
        Key: `DLP/CourseImage/${file.name}`,
        Body: file,
      };

      return new Promise((resolve, reject) => {
        s3.upload(params, { partSize: 5 * 1024 * 1024, queueSize: 1 })
          .on('httpUploadProgress', (progress) => {
            const uploadedPercentage = Math.round((progress.loaded / progress.total) * 100);
            // setPercent(uploadedPercentage);
          })
          .send((err, data) => {
            if (err) {
              reject(err);
            } else {
              resolve(data);

              // setParts(updatedParts);
              console.log(data.Location, "chan")
            }
          });
      });
    });
    console.log(arrayFiles, "arrayfiles")

    Promise.all(uploadPromises)
      .then(() => {
        console.log("reached1")
        // setLoading(false);

        // console.log(file,"base64")
        let body = {
          companyId: companyId,
          teamId: teamId,
          courseImg: arrayFiles,
          courseTitle: courseValue
        }

        axios.post(`${BaseUrl}courses/addCourse`, body)
          .then((res) => {
            if (res.data.statuscode === 200) {
              message.success('Course Created!');
              setCourseModal(false);
              setTimeout(() => {
                navigate(`/courses/${res.data.data[0].course_id}`);
              }, 1000)
            } else {
              message.error(`can't able to create a course!`)
            }
          })

      })
      .catch((err) => {
        console.log(err, "upload err");
      });


    }
    else{
      
      let body = {
        companyId: companyId,
        teamId: teamId,
        courseImg: arrayFiles,
        courseTitle: courseValue
      }

      axios.post(`${BaseUrl}courses/addCourse`, body)
        .then((res) => {
          if (res.data.statuscode === 200) {
            message.success('Course Created!');
            setCourseModal(false);
            setTimeout(() => {
              navigate(`/courses/${res.data.data[0].course_id}`);
            }, 1000)
          } else {
            message.error(`can't able to create a course!`)
          }
        })

    
    .catch((err) => {
      console.log(err, "upload err");
    });
    }



  }



  let teamdata = () => {
    axios
      .get(`${BaseUrl}teams`)
      .then((res) => {
        const transformedArray = res.data.map(item => ({
          label: item.Team_name.toUpperCase(),
          value: item.team_id,
        }));
        setTeamOptions(transformedArray)
        console.log(transformedArray, "transformedArrayTeam")
      })
      .catch((err) => {

      })
  }

  const filteredCourses = showInactive
  ? course.filter(course => !course.is_active) // Show inactive courses
  : course.filter(course => course.is_active);

  console.log(course, 'filteredCourses')

  const handleCheckboxChange = (e) => {
    setShowInactive(e.target.checked);
  };

  const paginatedCourses = course.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  console.log(showInactive, 'showInactive')

  return (
    <div className="Courses-main">

<Modal
      open={courseModal}
      title="Add Course"
      onCancel={cancelCourseModal}
      onOk={handleAddCourse}
    >
      <h3>Course Title</h3>
      <Input
        placeholder="Course Name"
        value={courseValue}
        onChange={(e) => setCourseValue(e.target.value)}
      />
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <h3>Team</h3>
      <Select
        placeholder="Select Team"
        style={{ width: '200px' }}
        onChange={(value) => setTeamId(value)}
        options={TeamOptions}
      />
      <h3>Cover Image</h3>
      <Dragger
        fileList={fileList}
        multiple={false}
        customRequest={({ file, onSuccess, onError }) => {
          const isImage = file.type.startsWith('image/');
          if (!isImage) {
            message.error("Only image files are allowed.");
            return onError("Invalid file type");
          }
          onSuccess("ok");
        }}
        onChange={(info) => {
          const filteredList = info.fileList.filter(file => file.type.startsWith('image/'));
          setFileList(filteredList);
          const fileObj = filteredList.map((item) => item.originFileObj);
          setFile(fileObj);
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag Image to this area to upload
        </p>
        <p className="ant-upload-hint">
          Upload cover image for your course.
        </p>
      </Dragger>
    </Modal>
      <div className="addCourceandHeading">
        <h2>Courses</h2>
        {/* <Button
          onClick={() => setCourseModal(true)}
          className="addCourseButton">Add Course</Button> */}
          <Checkbox onChange={handleCheckboxChange}>Show Inactive Courses</Checkbox> 
      </div>

      {
        Loading ?
          <div className='Loader' >

            <img src='/Loader/Ellipsis-2.9s-200px.svg'>
            </img>
          </div>
          :
          <>
            <Row className="manageChatbotRow">
              {
                !showInactive && currentPage === 1 &&
                <Col>
                <Card
                  onClick={() => {
                    setCourseModal(true);
                    // navigate('/courses/00011')
                  }
                  }
                  hoverable
                  className="cards-addCourseCards"
                  style={{
                    width: 200,
                    height: '178px',
                    padding: 2,
                  }}
                >
                  <AddIcon
                    style={{
                      fontSize: '83px'
                    }}
                    className="addIconClass" />
                  <h2>Add course</h2>
                </Card>
              </Col>
              }
            
              {course.length > 0 ? (
                <>
                  {paginatedCourses.map((item) => {
                    console.log(item.courseImg, "item")
                    return (
                      <div className="Course-card">
                        <Col span={6} className="manageBotCardCol">
                          <Card
                            onClick={() => handleCard(item)}
                            hoverable
                            className="cards"
                            style={{
                              width: 200,
                              height: '178px',
                              padding: 2,
                            }}
                            cover={
                              <img
                                src= { item.course_img !== "" && item.course_img !== null ? item.course_img:"/icons/CourseIcons.png" }
                                alt="Bot Image"
                                // src="/icons/CourseIcons.png"
                                height={130}
                                
                              />
                            }
                          >
                            {/* <Meta
                              title={
                                item.name != ""
                                  ? item.name
                                  : `Course ${item.num}`
                              }
                            // title={'Bot' + " " + (key + 1)}
                            /> */}


                              <Meta title={item.course_title || `Course ${item.num}`} />
                          </Card>
                        </Col>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="noCourseDIv">
                  <h3 className="noCourseHeading">No Courses</h3>
                </div>
              )}
           
            </Row>
            <Pagination
              onChange={handlePaginationChange}
              current={currentPage}
              pageSize={pageSize}
              showSizeChanger={false}
              total={course.length}
              style={{ position: 'absolute', bottom: '0px', left: '45%' }}
            />
          </>
      }
    </div>
  );
};

export default Courses;
