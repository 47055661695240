import React, { useEffect, useState } from 'react'
import './UserMapping.css'
import { Button, Select, Space, Table, message,Input } from 'antd'
import axios from 'axios';
import { BaseUrl } from '../../Constants/Constants';
import { EditOutlined } from '@mui/icons-material';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const userColumn = [
    {
        title: 'User Name',
        dataIndex: 'username',
        key: 'username',
        align: 'center',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        align: 'center',
    },
    {
        title: 'Team',
        dataIndex: 'team',
        key: 'team',
        align: 'center',
    },
];

const nonLinkedColumn = [
    {
        title: 'User Name',
        dataIndex: 'username',
        key: 'username',
        align: 'center',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        align: 'center',
    },
    {
        title: 'Team',
        dataIndex: 'team',
        key: 'team',
        align: 'center',
    },
];

const UserMapping = () => {

    const { courseId } = useParams();
    const navigate = useNavigate();


    const [teamOption, setTeamOption] = useState([]);
    // const [selectedTeam, setSelectedTeam] = useState('d8d3b0cb-02f4-4fe2-9ef6-afc0a34ef8c3');
    const [selectedTeam, setSelectedTeam] = useState('');
    // const [nonLinkedUserTeam, setNonLinkedUserTeam] = useState('d8d3b0cb-02f4-4fe2-9ef6-afc0a34ef8c3');
    const [nonLinkedUserTeam, setNonLinkedUserTeam] = useState('');
    const [userData, setUserData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [ linkedRowKeys, setLinkedRowKeys ] = useState([]);
    const [nonLinkedData, setNonLinkedData] = useState([]);
    const [searchLinked, setSearchLinked] = useState('');
    const [searchNonLinked, setSearchNonLinked] = useState('');

    const { Search } = Input;

    useEffect(() => {
        axios.get(`${BaseUrl}teams`)
            .then((res) => {
                let teams = res.data.map((item) => {
                    let teamsObj = {
                        value: item.team_id,
                        label: item.Team_name
                    }
                    return teamsObj;
                })
                setTeamOption(teams);

            })
            .catch((err) => {
                console.log(err.message)
            })
    }, [])

    const fetchAllCourseUsers = () => {
        let course = { courseId };

        axios.post(`${BaseUrl}course-mapping/getAllUsers`, course)
            .then((res) => {
                let courseMappedUsers = res.data.data;
                let bodyUsers = { userId: courseMappedUsers };

                if (res.data.statuscode === 200) {
                    axios.post(`${BaseUrl}users/courseMappedUsers`, bodyUsers)
                        .then((response) => {
                            let linkedUsersArray = response.data.data.linkedUsers;
                            let nonLinkedUsersArray = response.data.data.notLinkedUsers;

                            const filterUsersByTeam = (usersArray, team) => {
                                if (team) {
                                    return usersArray.filter(i => i.team_id.team_id === team);
                                }
                                return usersArray;
                            };

                            let users = filterUsersByTeam(linkedUsersArray, selectedTeam).map((item) => ({
                                key: item.user_id,
                                username: item.username,
                                email: item.email,
                                team: item.team_id.Team_name
                            }));
                            setUserData(users);

                            let nonLinkedUsers = filterUsersByTeam(nonLinkedUsersArray, selectedTeam).map((item) => ({
                                key: item.user_id,
                                username: item.username,
                                email: item.email,
                                team: item.team_id.Team_name
                            }));
                            setNonLinkedData(nonLinkedUsers);
                        })
                        .catch((err) => {
                            console.log(err.message);
                        });
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    useEffect(() => {

        fetchAllCourseUsers();
        
    }, [selectedTeam, nonLinkedUserTeam])

    console.log(selectedTeam, 'filteredUsers')


    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const onLinkedSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setLinkedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const linkedrowSelection = {
        linkedRowKeys,
        onChange: onLinkedSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;
    const linkedSelected = linkedRowKeys.length > 0;

    console.log(linkedRowKeys, 'linkedSelected')
    const handleRemove = () => {

        if (linkedSelected) {


            let body = {
                courseId : courseId, 
                userId : linkedRowKeys
            }


            console.log(body, 'selectedBody')

            axios.post(`${BaseUrl}course-mapping/removeUserFromCourse`, body)
                .then((res) => {
                    let response = res.data;
                    console.log(response,'DELETE')

                    if (response.success === true) {

                        message.success(`Removed ${linkedRowKeys.length} user`);
                        fetchAllCourseUsers();

                    }
                    else {
                        message.error(`Can't remove users!`)
                    }


                })
                .catch((err) => {
                    console.log(err.message)
                })
        }
        else {
            message.warning('Please select any user to Remove!')
        }
    }

    

    const handleAssign = () => {
        console.log(hasSelected, 'hasSelected')

        if (hasSelected) {

            let body = selectedRowKeys.map((i) => (
                {
                    courseId: courseId,
                    userId: i,
                }
            ))

            console.log(body, 'selectedBody')

            axios.post(`${BaseUrl}course-mapping/create`, body)
                .then((res) => {

                    let response = res.data;

                    if (response.statuscode === 200) {

                        let updateBody = [{
                            courseId: courseId,
                            teamId: selectedTeam
                        }]

                        axios.post(`${BaseUrl}courses/update`, updateBody)

                            .then((res) => {
                                console.log(res.data, 'updateCourse');
                                message.success(`Course assigned to ${selectedRowKeys.length} user`);
                                fetchAllCourseUsers();
                                // setTimeout(() => {
                                //     navigate('/assigncourse');
                                // }, 1000)
                            })
                            .catch((err) => {
                                console.log(err.message);
                                message.error(`Error Updating the team to the course!`);
                            })

                    }
                    else {
                        message.error(`Can't assign courses!`)
                    }


                })
                .catch((err) => {
                    console.log(err.message)
                })
        }
        else {
            message.warning('Please select any user to assign course!')
        }
    }

    const filteredUserData = userData.filter(user =>
        user.username.toLowerCase().includes(searchLinked.toLowerCase()) ||
        user.email.toLowerCase().includes(searchLinked.toLowerCase())
    );

    const filteredNonLinkedData = nonLinkedData.filter(user =>
        user.username.toLowerCase().includes(searchNonLinked.toLowerCase()) ||
        user.email.toLowerCase().includes(searchNonLinked.toLowerCase())
    );






    console.log(selectedTeam, 'selectedUserId')

    return (
        <div className='userMappingMain'>
            <p className='back'>
                <span onClick={(e) => navigate(`/assigncourse`)} style={{ color: '#4096FF', textDecoration: "underline" }}>
                    /Courses
                </span>
                <span>
                    <ArrowForwardIosIcon className='arrow' />
                </span>
                {/* <span  style={{ color: '#454545',textDecoration:""}}>
                 {selectedTeam}
                </span> */}


            </p>
            <h2>Assign Course</h2>

            <div className='selectAndAssignButton'>
            <Select style={{ width: 250 }}
                    placeholder='Select Team'
                    onSelect={(value) => setSelectedTeam(value)}
                    options={teamOption}
                />
                    <div>
                     <Button className='addCourseButton'
                    onClick={handleAssign}
                >Assign Users</Button>
                <Button className='addCourseButton'
                    onClick={handleRemove}
                >Remove</Button>
                </div>
            </div>
            <div className='Tableset'>
            <div>
            <h2>Mapped Users</h2>
            <Search
                        placeholder="Search Users"
                        onChange={e => setSearchLinked(e.target.value)}
                        style={{ marginBottom: 16, width: 300 }}
                    />
            <Table rowSelection={linkedrowSelection} columns={userColumn} dataSource={filteredUserData} />
            <h3
                style={{
                    marginLeft: 8,
                }}
            >
                {hasSelected ? `Selected ${selectedRowKeys.length} user` : ''}
            </h3>
            <div className='selectAndAssignButton'>
                {/* <Select style={{ width: 250 }}
                    value={nonLinkedUserTeam}
                    placeholder='Select Team' onSelect={(value) => {
                        console.log()
                        if (value != '') {
                            setNonLinkedUserTeam(value)
                        } else {
                            setNonLinkedUserTeam('d8d3b0cb-02f4-4fe2-9ef6-afc0a34ef8c3');
                        }
                    }} options={teamOption} /> */}
                {/* <Button className='addCourseButton'
                    onClick={handleAssign}
                >Assign Users</Button> */}
            </div>
            </div>
            <div>
            <h2>Unmapped Users</h2>
            <Search
                        placeholder="Search Users"
                        onChange={e => setSearchNonLinked(e.target.value)}
                        style={{ marginBottom: 16, width: 300 }}
                    />
            <Table rowSelection={rowSelection} columns={nonLinkedColumn} dataSource={filteredNonLinkedData} />
            </div>
            </div>
        </div>
    )
}

export default UserMapping