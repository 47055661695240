import Login from './Components/Login/Login'
import Signup from './Components/Signup/SignUp'
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import { useEffect,useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import { Row, Col } from 'antd'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useNavigate } from 'react-router';
import Sider from './Components/Courses/Sider';
import Users from './Components/Masters/Users/Users';
import Modules from './Components/Masters/Modules/Modules';
import Courses from './Components/Masters/Courses/Courses';
import Scores from './Components/Masters/Scores/Scores';
import Course from './Components/Masters/Scores/Courses/Course';
import UserList from './Components/Masters/Analytics/UserList/UserList';
import Analytics from './Components/Masters/Analytics/Analytics';
import AddCourse from './Components/Masters/Courses/AddCourse/AddCourse';
import Teams from './Components/Masters/Teams/Teams';
import Assesment from './Components/Masters/Assesment/Assesment';
import CourseMapping from './Components/CourseMapping/CourseMapping';
import UserMapping from './Components/CourseMapping/UserMapping/UserMapping';
import ScoreUsers from './Components/Masters/Scores/Users/ScoreUsers'
import CourseCompletion from './Components/Masters/Scores/Users/CourseCompletion';
import TrainingModule from './Components/TrainingModule/TrainingModule';
import AddTraining from './Components/TrainingModule/AddTraining/AddTraining';
import TrainingMapping from './Components/TrainingMapping/TrainingMapping';
import TraininguserMapping from './Components/TrainingMapping/TraininguserMapping/TraininguserMapping';
import TrainingAssessment from './Components/TrainingAssessment/TrainingAssessment';
import UserReport from './Components/Masters/Scores/UserReport/UserReport';
import TrainingReport from './Components/Masters/Scores/TrainingReports/TrainingReport';
import TrainingCompletion from './Components/Masters/Scores/Users/TrainingCompletion';
import ManageCourse from './Components/Masters/Courses/ManageCourse/ManageCourse';
import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
import PasswordChange from './Components/PasswordChange/PasswordChange';
import Settings from './Components/Settings/Settings';


function App() {

  const navigate = useNavigate();
  const [lastActivity, setLastActivity] = useState(Date.now());
  let url = window.location.href;
  let splittedUrl = url.split('/')[3];
  // console.log(splittedUrl,"url")

  const [Url, setUrl] = useState('')

  useEffect(() => {
    setUrl(splittedUrl)
  }, [splittedUrl])
//cannot render page need to change 
  // console.log(Url,"urllll")



  // useEffect(() => {
  //   // Your code to handle user activity
  //   const handleUserActivity = () => {
  //     setLastActivity(Date.now());
  //   };

  //   // Attach event listeners or use other mechanisms to track user activity
  //   window.addEventListener('mousemove', handleUserActivity);
  //   window.addEventListener('keydown', handleUserActivity);

  //   // Clean up the event listeners when the component unmounts
  //   return () => {
  //     window.removeEventListener('mousemove', handleUserActivity);
  //     window.removeEventListener('keydown', handleUserActivity);
  //   };
  // }, []);

  const INACTIVITY_TIMEOUT = 60 * 60 * 1000; //  60 minutes in milliseconds

  // useEffect(() => {
  //   const checkInactivity = () => {
  //     const currentTime = Date.now();
  //     if (currentTime - lastActivity > INACTIVITY_TIMEOUT) {
  //       // openNotificationWithIconError('User inactive. Logging out...')
  //       // console.log('User inactive. Logging out...');
  //       localStorage.setItem("IsAuthenticated", "false");
  //       setTimeout(() => {          
  //         if(splittedUrl !== 'login' && splittedUrl !== 'signup' && splittedUrl !== 'forgotpassword'){
  //           navigate('/login');
  //         }
  //       }, 500)
  //       // You may redirect to a logout page or dispatch a logout action.
  //     }
  //   };

  //   // Set up a timer to check inactivity at regular intervals
  //   const inactivityTimer = setInterval(checkInactivity, 1000); // Check every second

  //   // Clean up the timer when the component unmounts
  //   return () => {
  //     clearInterval(inactivityTimer);
  //   };
  // }, [lastActivity]);

  
  const IsAuthenticated = localStorage.getItem('IsAuthenticated')
  useEffect(() => {
    if (IsAuthenticated == "false" || IsAuthenticated == null) {
      // openNotificationWithIconError('Please Login to continue');
      setTimeout(() => {
        if(splittedUrl !== 'login' && splittedUrl !== 'signup' && splittedUrl !== 'forgotpassword'){
          navigate('/login')
        }        
      }, 500)
    }
  }, [])

  return (
    <div className="app-container">
      {
        Url == 'login' || Url === 'signup' || Url === 'forgotpassword' || Url === 'PasswordChange' ?
          <></>
          :
          <>

            <Header />
          </>
      }
      <div className='flex-container'>
        <div className='main-container'>
          <Row>
            {
              Url === 'login' || Url === 'signup' || Url === 'forgotpassword' || Url === 'PasswordChange' ?
                <></>

                :
                <Col span={4}>
                  <Sider />
                </Col>
            }
            <Col span={ Url === 'login' || Url === 'signup' || Url === 'forgotpassword' || Url === 'PasswordChange' ? 24 : 20}>
              <Routes>
              <Route exact path='/settings' element={<Settings />}></Route>
                <Route exact path="/login" element={<Login/>}></Route>
                <Route exact path="/signup" element={<Signup />}></Route>
                <Route exact path="/forgotpassword" element={<ForgotPassword />}></Route>
                <Route exact path='/PasswordChange' element={<PasswordChange />}></Route>
                <Route exact path="/" element={<Users/>}></Route> 
                <Route exact path="/manageusers" element={<Users />}></Route>
                <Route exact path="/modules" element={<Modules/>}></Route>
                <Route exact path="/managecourses" element={<Courses/>}></Route>
                <Route exact path="/managecourses/:id" element={<ManageCourse/>}></Route>
                <Route exact path="/managetrainings" element={<TrainingModule/>}></Route>
                <Route exact path='/courses/:courseId' element={<AddCourse />}></Route>
                <Route exact path='/trainings/:trainingId' element={<AddTraining />}></Route>
                <Route exact path='/Reports' element={<ScoreUsers/>}></Route>
                <Route exact path='/manageteams' element={<Teams/>}></Route>  
                <Route exact path='/Reports/:id' element={<Scores/>}></Route>
                <Route exact path='/coursesactivityreports' element={<CourseCompletion/>}></Route>
                <Route exact path='/trainingnoncompletionreports' element={<TrainingCompletion/>}></Route>
                <Route exact path='/trainingreports' element={<TrainingReport/>}></Route>
                <Route exact path='/analytics' element={<UserList/>}></Route>
                <Route exact path='/configureassessment' element={<Assesment/>}></Route>
                {/* <Route exact path='/trainingassessment' element={<TrainingAssessment/>}></Route> */}
                <Route exact path='/analytics/:userId' element={<Analytics/>}></Route>
                <Route exact path='/assigncourse' element={<CourseMapping />} ></Route>
                <Route exact path='/assigntrainings' element={<TrainingMapping />} ></Route>
                <Route exact path='/assigncourse/:type/:courseId' element={<UserMapping />}></Route>
                <Route exact path='/assigntrainings/:type/:trainingId' element={<TraininguserMapping />}></Route>
                <Route exact path='/userreports' element={<UserReport />} />
              </Routes>
            </Col>
          </Row>
        </div>
      </div>

      {
        splittedUrl === 'login' || splittedUrl === 'signup' || splittedUrl === 'forgotpassword' || splittedUrl === 'PasswordChange' ?
          <></>
          :
          <Footer />
      }
    </div>
  );
}

export default App;
