// import React, { useState, useEffect } from 'react'
// import { Table, Button, Space, Modal, Input, Select, notification, Tabs, Popover, Row, Col, Card, message } from 'antd'
// import axios from 'axios';
// import './TrainingAssessment.css'
// import { AssesmentUrl, BaseUrl, ImageGenUrl, REACT_APP_ACESS_KEY_ID, REACT_APP_SECRET_ACESS_KEY } from '../Constants/Constants';
// import { EditOutlined, PlusCircleOutlined, SearchOutlined, UsergroupAddOutlined } from '@ant-design/icons';
// import { InboxOutlined } from "@ant-design/icons";
// import Dragger from "antd/es/upload/Dragger";
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import TextArea from 'antd/es/input/TextArea';
// import AWS from 'aws-sdk'
// import { Assessment } from '@mui/icons-material';

// const TrainingAssessment = () => {

//     const s3 = new AWS.S3();

//     const [api, contextHolder] = notification.useNotification();
//     const [Data, setData] = useState([])
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [Loading, setLoading] = useState(false)
//     const [showTable, setshowTable] = useState(false)
//     const [CourseData, setCourseData] = useState([])
//     const [Status, setStatus] = useState(true)
//     const [NumberOfQuestions, setNumberOfQuestions] = useState('');
//     const [assessmentName, setAssessmentName] = useState('');
//     const [assessmentLevel, setAssessmentLevel] = useState(null);
//     const [assessmentId, setAssessmentId] = useState('')
//     const [Duration, setDuration] = useState('')
//     const [LoadTable, setLoadTable] = useState(false)
//     const [SelectedCourseId, setSelectedCourseId] = useState(null)
//     const [isEditModalOpen, setIsEditModalOpen] = useState(false);
//     const [EditAssessmentData, setEditAssessmentData] = useState(null);
//     const [assessmentType, setAssessmentType] = useState('');
//     const [assessmentTypeId, setAssessmentTypeId] = useState('');
//     const [questionInput, setQuestionInput] = useState('');
//     const [totalQuestions, setTotalQuestions] = useState([]);
//     const [editId, setEditId] = useState(null);
//     const [assessmentObj, setAssessmentObj] = useState('');
//     const [file, setfile] = useState([]);
//     const [arrayFiles, setarrayFiles] = useState("");
//     const [fileList, setFileList] = useState([]);
//     const [BTNLoading, setBTNLoading] = useState(false);
//     const [searchCourse, setSearchCourse] = useState('');

//     console.log(SelectedCourseId, 'uploadedFiles')

//     AWS.config.update({
//         region: 'us-east-1',
//         credentials: new AWS.Credentials({
//             accessKeyId: REACT_APP_ACESS_KEY_ID,
//             secretAccessKey: REACT_APP_SECRET_ACESS_KEY,
//             s3ForcePathStyle: true,
//         }),
//     });


//     //   useEffect(() => {

//     //     console.log(file, "file")

//     //     if(file.length > 0){

//     //         // const fileNames = file.map((i) => `https://trainingnexus-images.s3.amazonaws.com/Assessment/${SelectedCourseId}/${assessmentName}/${i.name}`);
//     //         const fileNames = file.map((i) => {
//     //             const url = `https://trainingnexus-images.s3.amazonaws.com/Assessment/${SelectedCourseId}/${assessmentName}/${i.name}`;
//     //             const replacedUrl = url.replace(/\s+/g, '+');
//     //             return replacedUrl;
//     //           });
//     //         console.log(fileNames,"arrayfiles")
//     //       setarrayFiles(fileNames[0]);
//     //     }


//     // }, [file]);


//     const columns = [

//         {
//             title: 'Action',
//             key: 'action',
//             render: (_, record) => (
//                 <Space size="middle">
//                     <EditOutlined className='Edit' onClick={() => showEditModal(record)} />
//                 </Space>
//             ),
//         },
//         {
//             title: 'S.No',
//             dataIndex: 'key',
//             key: 'key',
//         },
//         {
//             title: 'Course Name',
//             dataIndex: 'course_title',
//             key: 'course_title',
//             render: (text, record) => <p
//             // onClick={(e) => OpenTeamview(record)}
//             // className='NameTxtTeam'
//             >{text}</p>,
//         },
//         {
//             title: 'Assessment Name',
//             dataIndex: 'Assessment_name',
//             key: 'Assessment_name',
//             render: (text, record) => <p
//             // onClick={(e) => OpenTeamview(record)}
//             // className='NameTxtTeam'
//             >{text}</p>,
//             align: 'center'
//         },
//         {
//             title: 'Assessment Level',
//             dataIndex: 'Assessment_level',
//             key: 'Assessment_level',
//             render: (text, record) => <p
//                 // onClick={(e) => OpenTeamview(record)}
//                 // className='NameTxtTeam'
//                 style={{ textTransform: 'capitalize' }}
//             >{text}</p>,
//             align: 'center'
//         },
//         {
//             title: 'Assessment Type',
//             dataIndex: 'Assessment_type',
//             key: 'Assessment_type',
//             render: (text, record) => <p
//                 // onClick={(e) => OpenTeamview(record)}
//                 // className='NameTxtTeam'
//                 style={{ textTransform: 'capitalize' }}
//             >{text}</p>,
//             align: 'center'
//         },
//         {
//             title: 'Question Count',
//             dataIndex: 'Number_of_questions',
//             key: 'Number_of_questions',
//             align: 'center'
//         },
//         ,
//         {
//             title: 'Duration',
//             dataIndex: 'Assesment_Duration',
//             key: 'Assesment_Duration',
//             render: (text, record) => <p
//             // onClick={(e) => OpenTeamview(record)}
//             >{text}:00 mins</p>,
//         },

//         {
//             title: 'Created At',
//             dataIndex: 'created_at',
//             key: 'created_at',
//             render: (text, record) => (
//                 <p>{new Date(text).toLocaleDateString()}</p>
//             ),
//         },

//     ];

//     const levelOptions = [
//         {
//             value: 'easy',
//             label: 'Easy'
//         },
//         {
//             value: 'medium',
//             label: 'Medium'
//         },
//         {
//             value: 'hard',
//             label: 'Hard'
//         },
//     ]

//     const courseData = () => {
//         axios
//             .get(`${BaseUrl}trainings/getAllTraining`)
//             .then((res) => {
//                 console.log(res.data, "courseData")
//                 const transformnedArray = res.data.data.map(item => ({
//                     value: item.training_id,
//                     label: item.training_title
//                 }))
//                 console.log(transformnedArray, "transformnedArray")
//                 setCourseData(transformnedArray)
//             })
//             .catch((err) => {

//             })
//     }

//     const openNotificationWithIconWarning = (type) => {
//         api['warning']({
//             message: "Warning!!!",
//             description:
//                 type,
//         });
//     };
//     const openNotificationWithIconError = (type) => {
//         api['error']({
//             message: "Oops!!!",
//             description:
//                 type,
//         });
//     };
//     const openNotificationWithIconSuccess = (type) => {
//         console.log('getsSuccess')
//         api['success']({
//             message: "Success!!!",
//             description:
//                 type,
//         });
//     };


//     useEffect(() => {
//         TableData()
//         courseData()
//     }, [LoadTable])

//     const TableData = () => {
//         setLoading(true)
//         axios
//             .get(`${BaseUrl}training-assessment`)
//             .then((res) => {
//                 console.log(res.data, 'assessmentData')

//                 const transformedData = res.data.map((item) => ({
//                     Assesment_status: item.status,
//                     Number_of_questions: item.Number_of_questions,
//                     key: item.key,
//                     course_title: item.training_id.training_title,
//                     Assesment_id: item.Assesment_id,
//                     Assesment_Duration: item.Assesment_Duration,
//                     created_at: item.created_at,
//                     Assessment_name: item.Assessment_name,
//                     Assessment_level: item.Assessment_level,
//                     Assessment_type: item.assessment_type,
//                     Assessment_type_id: item.assessment_type_id

//                 }));

//                 setData(transformedData)
//                 setLoading(false)
//             })
//     }

//     console.log(Data, 'AssesmentData')

//     const showEditModal = (record) => {
//         console.log(record, 'editModalRecord')
//         setIsEditModalOpen(true);
//         setEditAssessmentData(record);
//         setAssessmentName(record.Assessment_name);
//         setAssessmentLevel(record.Assessment_level);
//         setSelectedCourseId(record.course_id);
//         setNumberOfQuestions(record.Number_of_questions);
//         setDuration(record.Assesment_Duration);
//         setAssessmentId(record.Assesment_id);
//         setStatus(record.Assesment_status);
//     };

//     const handleEditOk = () => {
//         // Update the assessment with the edited data
//         // You can use the EditAssessmentData state to get the edited data
//         // Perform necessary actions (e.g., API calls) to update the assessment
//         // Close the modal and perform any other necessary operations

//         let body = {
//             Assesment_id: assessmentId,
//             Number_of_questions: NumberOfQuestions,
//             Assesment_Duration: Duration,
//             Assessment_name: assessmentName,
//             Assessment_level: assessmentLevel,
//             status: Status,
//             // assessment_type : assessmentType,
//             // assessment_type_id : assessmentTypeId,
//             // assessment_object : {
//             //     "key1": "value1",
//             //     "key2": "value2"
//             // }
//         }

//         axios.post(`${BaseUrl}training-assessment/assessment_update`, body)
//             .then((res) => {
//                 console.log(res, 'updateResponse');

//                 if (res.data.statuscode === 200) {
//                     setLoadTable(!LoadTable);
//                     openNotificationWithIconSuccess('Updated successfully!');
//                     setIsEditModalOpen(false);
//                 } else {
//                     openNotificationWithIconError('Error Updating');
//                     setIsEditModalOpen(false);
//                 }

//             })

//             .catch((err) => {
//                 openNotificationWithIconError('Error Updating');
//                 setIsEditModalOpen(false);
//             })




//     };

//     const [clearTableData, setClearTableData] = useState([])


//     useEffect(() => {
//         if (searchCourse === '') { setClearTableData(Data); return; }

//         const filteredEmails = Data.filter((item) => item.course_title.toLowerCase().includes(searchCourse.toLowerCase()))
//         setClearTableData(filteredEmails);
//         console.log(Data, 'searchVal')

//     }, [searchCourse, Data])
//     // console.log(Data, 'TableData')

//     const handleEditCancel = () => {
//         // Close the modal without saving any changes
//         setIsEditModalOpen(false);
//     };


//     const showModal = (e, id) => {
//         console.log(id, "showModal");
//         setAssessmentType(e);
//         setAssessmentTypeId(id);
//         setAssessmentLevel(null);
//         setAssessmentName('');
//         setNumberOfQuestions('');
//         setDuration('');
//         setSelectedCourseId(null);
//         setIsModalOpen(true);

//     };


//     const handleOk = () => {
//         if (assessmentName === '' || assessmentLevel === null || SelectedCourseId === null || Duration === '') {
//             openNotificationWithIconWarning('Please fill all the fields to submit')
//         } else {
//             setBTNLoading(true)
//             // setIsModalOpen(false);

//             if (assessmentType === 'mcq') {
//                 let body = {
//                     companyid: "ec81ffa5-1eb4-4a1d-9a23-b41fc65f46fd",
//                     training_id: SelectedCourseId,
//                     Number_of_questions: NumberOfQuestions,
//                     Assesment_Duration: Duration,
//                     Assessment_name: assessmentName,
//                     Assessment_level: assessmentLevel,
//                     status: Status,
//                     assessment_type: assessmentType,
//                     assessment_type_id: assessmentTypeId,
//                     assessment_object: ''
//                 }
//                 axios
//                     .post(`${BaseUrl}training-assessment/add`, body)
//                     .then((res) => {
//                         console.log(res.data, 'mcqGeneratedResponse')
//                         openNotificationWithIconSuccess('Assessment Assigned Sucessfully !')
//                         setLoadTable(true)
//                         setLoading(false);
//                         setBTNLoading(false)
//                         setTotalQuestions([]);
//                         setIsModalOpen(false);
//                     })
//                     .catch((err) => {
//                         console.log(err)
//                         setIsModalOpen(false);
//                         setLoading(false)
//                         setBTNLoading(false)
//                         openNotificationWithIconWarning("Error")
//                     })

//                 let Body = {
//                     course_id: SelectedCourseId,
//                     no_of_questions: NumberOfQuestions
//                 }
//                 axios
//                     .post(`${AssesmentUrl}mcq_generation`, Body)
//                     .then((res) => {
//                         console.log(res)
//                     })
//                     .catch((err) => {
//                         console.log(err, "err");
//                         openNotificationWithIconWarning('Error generating questions!')
//                         setBTNLoading(false)
//                     })

//             } else if (assessmentType === 'image') {

//                 const fileNames = file.map((i) => {
//                     const url = `https://trainingnexus-images.s3.amazonaws.com/Assessment/${SelectedCourseId}/${assessmentName}/${i.name}`;
//                     const replacedUrl = url.replace(/\s+/g, '+');
//                     return replacedUrl;
//                 });

//                 console.log(fileNames, 'draggers3Url')

//                 // const fileNames = file.map((i) => `s3://trainingnexus-images/Assessment/${SelectedCourseId}/${assessmentName}/${i.name}`)


//                 if (file.length > 9) {

//                     const uploadPromises = file.map((file) => {
//                         const params = {
//                             Bucket: 'trainingnexus-images',
//                             Key: `Assessment/${SelectedCourseId}/${assessmentName}/${file.name}`,
//                             Body: file,
//                         };

//                         return new Promise((resolve, reject) => {
//                             s3.upload(params, { partSize: 5 * 1024 * 1024, queueSize: 1 })
//                                 .on('httpUploadProgress', (progress) => {
//                                     const uploadedPercentage = Math.round((progress.loaded / progress.total) * 100);
//                                     // setPercent(uploadedPercentage);
//                                 })
//                                 .send((err, data) => {
//                                     if (err) {
//                                         reject(err);
//                                     } else {
//                                         resolve(data);

//                                         // setParts(updatedParts);
//                                         console.log(data.Location, "chan")
//                                     }
//                                 });
//                         });
//                     });

//                     Promise.all(uploadPromises)
//                         .then(() => {

//                             let body = {
//                                 companyid: "ec81ffa5-1eb4-4a1d-9a23-b41fc65f46fd",
//                                 training_id: SelectedCourseId,
//                                 Number_of_questions: JSON.stringify(file.length),
//                                 Assesment_Duration: Duration,
//                                 Assessment_name: assessmentName,
//                                 Assessment_level: assessmentLevel,
//                                 status: Status,
//                                 assessment_type: assessmentType,
//                                 assessment_type_id: assessmentTypeId,
//                                 assessment_object: JSON.stringify(fileNames)
//                             }
//                             axios
//                                 .post(`${BaseUrl}training-assessment/add`, body)
//                                 .then((res) => {

//                                     console.log(res.data, 'assessmentImageResponse')
//                                     if (res.data.statuscode === 200) {
//                                         console.log('REachedHere')
//                                         const resAssessmentId = res.data.data[0].Assesment_id;
//                                         let imageBody = {
//                                             user_id: resAssessmentId,
//                                             course_id: SelectedCourseId,
//                                             image_dir_list: fileNames
//                                         }

//                                         axios.post(`${ImageGenUrl}generate_questions`, imageBody)
//                                             .then((res) => {
//                                                 console.log(res, 'generateQuestionResponse');

//                                                 if (res.data.db_status === 200) {

//                                                     openNotificationWithIconSuccess('Assessment Assigned Sucessfully !')
//                                                     setLoadTable(true)
//                                                     setLoading(false);
//                                                     setBTNLoading(false)
//                                                     setIsModalOpen(false);
//                                                     setfile([]);
//                                                     setFileList([]);
//                                                 }


//                                             })
//                                             .catch((err) => {
//                                                 setIsModalOpen(false);
//                                                 setBTNLoading(false)
//                                                 console.log(err, 'GenerateImageQuestionError')
//                                                 openNotificationWithIconError('Error Uploading images!');
//                                             })

//                                     } else {
//                                         openNotificationWithIconError('Error Uploading images!');
//                                     }
//                                 })
//                                 .catch((err) => {
//                                     console.log(err, 'errMessage')
//                                     setIsModalOpen(false);
//                                     setLoading(false)
//                                     openNotificationWithIconWarning("Error")
//                                 })

//                             console.log(fileNames, 'reachedhere')

//                         })
//                         .catch((err) => {
//                             openNotificationWithIconError('Error Uploading images!');
//                             console.log(err, "upload err");
//                         });


//                 } else {
//                     openNotificationWithIconWarning('Upload atleast 10 images to submit!')

//                     setBTNLoading(false)
//                 }
//             } else if (assessmentType === 'manual') {

//                 if (totalQuestions.length > 9) {

//                     let body = {
//                         companyid: "ec81ffa5-1eb4-4a1d-9a23-b41fc65f46fd",
//                         training_id: SelectedCourseId,
//                         Number_of_questions: JSON.stringify(totalQuestions.length),
//                         Assesment_Duration: Duration,
//                         Assessment_name: assessmentName,
//                         Assessment_level: assessmentLevel,
//                         status: Status,
//                         assessment_type: assessmentType,
//                         assessment_type_id: assessmentTypeId,
//                         assessment_object: JSON.stringify(totalQuestions)
//                     }
//                     axios
//                         .post(`${BaseUrl}training-assessment/add`, body)
//                         .then((res) => {
//                             console.log(res.data, 'mcqGeneratedResponse')
//                             if (res.data.statuscode === 200) {

//                                 let manualBody = {
//                                     course_id: SelectedCourseId,
//                                     no_of_question: JSON.stringify(totalQuestions.length),
//                                     question: totalQuestions
//                                 }

//                                 axios.post(`${ImageGenUrl}admin/load_question`, manualBody)
//                                     .then((res) => {
//                                         console.log(res, 'manualQuestionGenRes');

//                                         if (res.data.status === 200) {
//                                             openNotificationWithIconSuccess('Assessment Assigned Sucessfully !')
//                                             setLoadTable(true)
//                                             setLoading(false);
//                                             setTotalQuestions([]);
//                                             setIsModalOpen(false);
//                                             setLoadTable(!LoadTable)
//                                             setBTNLoading(false)
//                                         }
//                                     })
//                                     .catch((err) => {
//                                         openNotificationWithIconError('Error Uploading images!');
//                                         console.log(err, 'loadQuestionCatchError')
//                                         setBTNLoading(false)
//                                     })
//                             }

//                         })
//                         .catch((err) => {
//                             console.log(err)
//                             setIsModalOpen(false);
//                             setLoading(false)
//                             setBTNLoading(false)
//                             openNotificationWithIconWarning("Error")
//                         })
//                 } else {
//                     openNotificationWithIconWarning('Give atleast 10 questions to submit!')
//                 }
//             }
//         }

//     };

//     console.log(file, 'draggerFile')
//     const handleCancel = () => {
//         setIsModalOpen(false);
//         setBTNLoading(false)
//         setQuestionInput('');
//         setTotalQuestions([]);
//         setAssessmentName('');
//         setAssessmentLevel('');
//         setSelectedCourseId('');
//         setNumberOfQuestions('');
//         setAssessmentType('');
//         setDuration('');
//         setStatus(true);
//         setfile([]);
//         setFileList([]);
//     };
//     const handleChange = (value) => {
//         setStatus(value)
//     }


//     const assessmentPopContent = (
//         <div>
//             <p onClick={() => showModal('mcq', 1)} className='assessmentTypes'>AI generated MCQ</p>
//             <p onClick={() => showModal('image', 2)} className='assessmentTypes'>AI generated Image</p>
//             <p onClick={() => showModal('manual', 3)} className='assessmentTypes'>Manual Questions</p>
//         </div>
//     );

//     const handleAddQuestion = () => {

//         if (questionInput.trim() === '') return; // Prevent adding empty questions
//         if (editId !== null) {
//             // If editing, update the existing question
//             setTotalQuestions(totalQuestions.map(q => q.id === editId ? { ...q, question: questionInput } : q));
//             setEditId(null); // Reset edit mode
//         } else {
//             // If not editing, add a new question
//             const newQuestion = {
//                 id: totalQuestions.length > 0 ? totalQuestions[totalQuestions.length - 1].id + 1 : 1,
//                 question: questionInput
//             };
//             setTotalQuestions([...totalQuestions, newQuestion]);
//         }
//         setQuestionInput(''); // Clear input after adding/editing
//     };

//     const handleEdit = (id) => {
//         const questionToEdit = totalQuestions.find(q => q.id === id);
//         if (questionToEdit) {
//             setQuestionInput(questionToEdit.question);
//             setEditId(id);
//         }
//     };

//     const handleDelete = (id) => {
//         setTotalQuestions(totalQuestions.filter(q => q.id !== id));
//     };

//     console.log(totalQuestions, 'assessmentName')

//     return (
//         <div className='Assesment_main'>
//             {contextHolder}

//             <Modal title="Edit Assessment"
//                 open={isEditModalOpen}
//                 onOk={handleEditOk}
//                 onCancel={handleEditCancel}>

//                 <p style={{ marginTop: 20 }} className='Title' >Assessment Name</p>
//                 <Input placeholder="Assessment Name"
//                     value={assessmentName}

//                     onChange={(e) => setAssessmentName(e.target.value)}
//                     className='EditInput' />

//                 <p className='Title' >Select Assessment Level</p>
//                 <Select
//                     // defaultValue=""
//                     // defaultValue={}
//                     value={assessmentLevel}
//                     placeholder="Select Level"
//                     className='EditInput'
//                     onChange={(value) => setAssessmentLevel(value)}
//                     options={levelOptions}
//                 />

//                 {/* <p className='Title' >Select Course</p>
//                 <Select
//                     // defaultValue=""
//                     value={EditAssessmentData ? EditAssessmentData.course_title : undefined}
//                     placeholder="Select Course"
//                     className='EditInput'
//                     onChange={(value) => setSelectedCourseId(value)}
//                     options={CourseData}
//                 /> */}
//                 <p className='Title' >Number of questions</p>
//                 <Input placeholder="Number of questions"
//                     // value={UserName}
//                     value={NumberOfQuestions}

//                     onChange={(e) => setNumberOfQuestions(e.target.value)}
//                     className='EditInput' />
//                 <p className='Title' >Assesment Duration</p>
//                 <Input placeholder="Assesment Duration"
//                     value={Duration}
//                     onChange={(e) => setDuration(e.target.value)}

//                     className='EditInput' />

//                 <p className='Title' >Status</p>
//                 <Select
//                     // defaultValue="Active"
//                     value={Status}
//                     className='EditInput'
//                     onChange={handleChange}
//                     options={[
//                         {
//                             value: true,
//                             label: 'Active',
//                         },
//                         {
//                             value: false,
//                             label: 'Inactive',
//                         }
//                     ]}
//                 />



//             </Modal>
//             <Modal className={assessmentType === 'mcq' ? 'addAssessmentMcqModalClass' : 'addAssessmentModalClass'}
//                 title={<p>Add Assessment- <span className='assessmentTypeClass'>{assessmentType}</span></p>}
//                 open={isModalOpen}
//                 footer={false}
//                 // onOk={handleOk}
//                 onCancel={handleCancel}
//             >
//                 <Row>
//                     <Col span={assessmentType === 'mcq' ? 24 : 11}>
//                         <h3>Fill in the details</h3>

//                         <p style={{ marginTop: 20 }} className='Title' >Assessment Name</p>
//                         <Input placeholder="Assessment Name"
//                             value={assessmentName}

//                             onChange={(e) => setAssessmentName(e.target.value)}
//                             className='EditInput' />

//                         <p className='Title' >Select Assessment Level</p>
//                         <Select
//                             value={assessmentLevel}
//                             placeholder="Select Level"
//                             className='EditInput'
//                             onChange={(value) => setAssessmentLevel(value)}
//                             options={levelOptions}
//                         />

//                         <p className='Title' >Select Training</p>
//                         <Select
//                             value={SelectedCourseId}
//                             placeholder="Select Training"
//                             className='EditInput'
//                             onChange={(value) => setSelectedCourseId(value)}
//                             options={CourseData}
//                         />
//                         {
//                             assessmentType === 'mcq' ?
//                                 <>
//                                     <p className='Title' >Question Count</p>
//                                     <Input placeholder="Number of questions"
//                                         value={NumberOfQuestions}

//                                         onChange={(e) => setNumberOfQuestions(e.target.value)}
//                                         className='EditInput' />
//                                 </>
//                                 :
//                                 <></>
//                         }

//                         <p className='Title' >Assesment Duration</p>
//                         <Input placeholder="Assesment Duration"
//                             onChange={(e) => setDuration(e.target.value)}
//                             value={Duration}
//                             className='EditInput' />

//                         <p className='Title' >Status</p>
//                         <Select
//                             defaultValue={true}
//                             className='EditInput'
//                             value={Status}
//                             onChange={handleChange}
//                             options={[
//                                 {
//                                     value: true,
//                                     label: 'Active',
//                                 },
//                                 {
//                                     value: false,
//                                     label: 'Inactive',
//                                 }
//                             ]}
//                         />
//                     </Col>
//                     {
//                         assessmentType === 'image' ?
//                             <>
//                                 <Col span={12} offset={1} >
//                                     <h3>Upload/Drag images to genreate questions</h3>
//                                     <p className='Title' >Upload Image</p>
//                                     <Dragger
//                                         fileList={fileList}
//                                         multiple={true}
//                                         className='imageUploader'
//                                         customRequest={({ file, onSuccess, onError }) => {
//                                             const isImage = file.type.startsWith('image/');
//                                             const isSVG = file.type === 'image/svg+xml';
//                                             if (!isImage || isSVG) {
//                                                 openNotificationWithIconError("Only image without SVG formats are allowed.");
//                                                 setBTNLoading(false);
//                                                 return onError("Invalid file type");
//                                             }
//                                             onSuccess("ok")
//                                         }}
//                                         onChange={(info) => {

//                                             const filteredList = info.fileList.filter(file => {
//                                                 const isImage = file.type.startsWith('image/');
//                                                 const isSVG = file.type === 'image/svg+xml';
//                                                 return isImage && !isSVG;
//                                             });

//                                             console.log(info, "info");
//                                             setFileList(filteredList);
//                                             const fileObj = filteredList.map((item) => item.originFileObj);
//                                             setfile(fileObj)
//                                         }}
//                                     >
//                                         <p className='ant-upload-drag-icon'>
//                                             <InboxOutlined />

//                                         </p>
//                                         <p className='ant-upload-text'>
//                                             Click or drag Image to this area to upload
//                                         </p>
//                                         <p className='ant-upload-hint'>
//                                             (Supported formats - png, jpg, webp)
//                                         </p>
//                                     </Dragger>
//                                 </Col>
//                             </>
//                             :
//                             <></>
//                     }
//                     {
//                         assessmentType === 'manual' ?
//                             <Col span={12} offset={1}>
//                                 <h3>Add Questions & Answers</h3>
//                                 <p className='Title' >Question</p>
//                                 <TextArea className='EditInput' rows={3} placeholder='Enter question' value={questionInput} onChange={(e) => setQuestionInput(e.target.value)} onPressEnter={handleAddQuestion} />

//                                 <Button className='addQuestionBtn'
//                                     onClick={handleAddQuestion}
//                                 ><PlusCircleOutlined className='addQuestionIcon' /> {editId !== null ? 'Edit' : 'Add'}</Button>

//                                 <div className='addedQuestionCardDiv'>
//                                     <div className='assessmentCardTotalQuestionCard'>
//                                         <h3>Added Questions</h3>
//                                         {
//                                             totalQuestions.length === 0 ?
//                                                 <p className='noQuestionDisplay'>No question yet!</p>
//                                                 : <>
//                                                     {
//                                                         totalQuestions.map((q) => (
//                                                             <>
//                                                                 <Card className='questionSingleCard' key={q.id}>
//                                                                     <p>{q.question}</p>
//                                                                     <EditIcon onClick={() => handleEdit(q.id)} className='questionEditIcon' />
//                                                                     <DeleteIcon onClick={() => handleDelete(q.id)} className='questionDeleteIcon' />
//                                                                 </Card>
//                                                             </>
//                                                         ))
//                                                     }
//                                                 </>
//                                         }
//                                     </div>
//                                     <Button style={{ marginTop: 10 }} className='clearQuestionBtn' onClick={() => setTotalQuestions([])}>Clear Questions</Button>
//                                 </div>

//                             </Col>
//                             :
//                             <></>
//                     }
//                 </Row>
//                 <Row className='footer-modal'>
//                     <Button onClick={handleCancel} style={{ border: "1px solid black", marginRight: 20 }} type='secondary'>
//                         Cancel
//                     </Button>
//                     <Button onClick={handleOk} loading={BTNLoading} type='primary'>
//                         Submit
//                     </Button>





//                 </Row>

//             </Modal>
//             <h2>
//                 Assessments
//             </h2>
//             <Row>
//                 <Col span={8} offset={10}>
//                     <Input placeholder='Search by course name' className='searchInput'
//                         onChange={(e) => setSearchCourse(e.target.value)}
//                     />
//                     <SearchOutlined className='inputSearch' />
//                 </Col>
//                 <Col offset={1} span={4}>
//                     <Popover title='Select assessment type' placement='bottomRight'
//                         content={assessmentPopContent}
//                         trigger="hover"
//                     >
//                         <Button
//                             // onClick={showModal}
//                             className='ADD'
//                             type='primary'
//                         >
//                             Add New Assessment<UsergroupAddOutlined />
//                         </Button>
//                     </Popover>
//                 </Col>
//             </Row>
//             {
//                 Loading ?
//                     <div className='Loader' >

//                         <img src='Loader\Ellipsis-2.9s-200px.svg'>
//                         </img>
//                     </div>
//                     :
//                     <Table className='assessmentTable' columns={columns} dataSource={clearTableData} />
//             }</div>
//     )
// }

// export default TrainingAssessment