import React from 'react'
import './Modules.css'
import { Button, Space, Table, Tag } from 'antd';

const Modules = () => {
const columns = [
  {
    title: 'S.no',
    dataIndex: 'Sno',
    key: 'Sno',
    // render: (text) => <a>{text}</a>,
  },
  {
    title: 'Modules',
    dataIndex: 'modules',
    key: 'modules',
  },
  {
    title: 'Course',
    dataIndex: 'course',
    key: 'course',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
//   {
//     title: 'Tags',
//     key: 'tags',
//     dataIndex: 'tags',
//     render: (_, { tags }) => (
//       <>
//         {tags.map((tag) => {
//           let color = tag.length > 5 ? 'geekblue' : 'green';
//           if (tag === 'loser') {
//             color = 'volcano';
//           }
//           return (
//             <Tag color={color} key={tag}>
//               {tag.toUpperCase()}
//             </Tag>
//           );
//         })}
//       </>
//     ),
//   },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <a>Delete</a>
      </Space>
    ),
  },
];
const data = [
  {
    key: '1',
    Sno: '1',
    course:'Hackathon',
    status:"Active",
    modules: 'Hackathon part 1',
    tags: ['nice', 'developer'],
  },
  {
    key: '2',
    Sno: '2',
    course:'Hackathon',
    status:"Active",
    modules: 'Hackathon part 2',
    tags: ['loser'],
  },
  {
    key: '3',
    Sno: '3',
    course:'Hackathon',
    status:"Active",
    modules: 'Hackathon part 3',
    tags: ['cool', 'teacher'],
  },
];
  return (
    <div className='Modules-main'>
        <h2>
        Modules
        </h2>
        <Button
        className='ADD'
        type='primary' 

        >
            Add Module
            </Button>
        <Table columns={columns} dataSource={data} />
        </div>
  )
}

export default Modules