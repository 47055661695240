// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.courseMappingMain{
    margin-top: 70px;
    padding: 25px;
}
.Select{
    float: right;
    width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/Components/CourseMapping/CourseMapping.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;AACjB;AACA;IACI,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".courseMappingMain{\n    margin-top: 70px;\n    padding: 25px;\n}\n.Select{\n    float: right;\n    width: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
