// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manageBotCardCol{   
    margin: 0% 0px 0px 5px;
}
.manageBotCardCol .ant-card-meta-title{
   text-align: center;
   letter-spacing: 0.5px;
}
.manageBotCardCol .ant-card-body{
   padding: 10px;
}
.Course-card{
   /* padding: 20px; */
   margin: 0px 25px 25px 0px;
}
.Course-heading{
   margin-left: 24px;
   font-weight: 400;
   margin-top: 80px;
}
.cards:hover{
   transition: transform 0.3s ease;
   transform: scale(1.1);
}
.SearchInput{
   width: 200px;
   float: right;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Masters/Scores/Courses/Courses.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;AACA;GACG,kBAAkB;GAClB,qBAAqB;AACxB;AACA;GACG,aAAa;AAChB;AACA;GACG,mBAAmB;GACnB,yBAAyB;AAC5B;AACA;GACG,iBAAiB;GACjB,gBAAgB;GAChB,gBAAgB;AACnB;AACA;GACG,+BAA+B;GAC/B,qBAAqB;AACxB;AACA;GACG,YAAY;GACZ,YAAY;AACf","sourcesContent":[".manageBotCardCol{   \n    margin: 0% 0px 0px 5px;\n}\n.manageBotCardCol .ant-card-meta-title{\n   text-align: center;\n   letter-spacing: 0.5px;\n}\n.manageBotCardCol .ant-card-body{\n   padding: 10px;\n}\n.Course-card{\n   /* padding: 20px; */\n   margin: 0px 25px 25px 0px;\n}\n.Course-heading{\n   margin-left: 24px;\n   font-weight: 400;\n   margin-top: 80px;\n}\n.cards:hover{\n   transition: transform 0.3s ease;\n   transform: scale(1.1);\n}\n.SearchInput{\n   width: 200px;\n   float: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
