// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userMappingMain{
    margin-top: 70px;
    padding: 25px;
}
.selectAndAssignButton{
    display: flex;
    justify-content: space-between;
    margin-bottom: 3%;
}
.Tableset{
    display: flex;
    gap:2%;
    /* justify-content: space-between; */
}`, "",{"version":3,"sources":["webpack://./src/Components/CourseMapping/UserMapping/UserMapping.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;AACjB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,MAAM;IACN,oCAAoC;AACxC","sourcesContent":[".userMappingMain{\n    margin-top: 70px;\n    padding: 25px;\n}\n.selectAndAssignButton{\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 3%;\n}\n.Tableset{\n    display: flex;\n    gap:2%;\n    /* justify-content: space-between; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
