import React from 'react'
import './Analytics.css'
import { Card, Row, Col } from 'antd'
import {Cell, PieChart,Pie,Line,LineChart,BarChart,CartesianGrid,XAxis,YAxis,Legend,Bar,Tooltip,ResponsiveContainer,ReferenceLine } from 'recharts'
// import { Pie } from '@ant-design/plots';
// import { Line } from '@ant-design/charts';
// import "@ant - design/flowchart/dist/index.css";

const Analytics = () => {

  const data = [
    {
      "name": "Page A",
      "MandatoryCourses": 40,
      "OtherCourses": 24
    },
    {
      "name": "Page B",
      "MandatoryCourses": 30,
      "OtherCourses": 13
    },
    {
      "name": "Page C",
      "MandatoryCourses": 20,
      "OtherCourses": 8
    },
    {
      "name": "Page D",
      "MandatoryCourses": 27,
      "OtherCourses": 39
    },
    {
      "name": "Page E",
      "MandatoryCourses": 18,
      "OtherCourses": 12
    },
    {
      "name": "Page F",
      "MandatoryCourses": 23,
      "OtherCourses": 38
    },
    {
      "name": "Page G",
      "MandatoryCourses": 34,
      "OtherCourses": 13
    }
  ]
const data1 = [
  {
    name: 'Page A',
    TopPerfomingCourses: 4,
    LowPerfomingCourses: 2,
    amt: 2400,
  },
  {
    name: 'Page B',
    TopPerfomingCourses: 3,
    LowPerfomingCourses: -1,
    amt: 2210,
  },
  {
    name: 'Page C',
    TopPerfomingCourses: 2,
    LowPerfomingCourses: -9,
    amt: 2290,
  },
  {
    name: 'Page D',
    TopPerfomingCourses: 2,
    LowPerfomingCourses: -3,
    amt: 2000,
  },
  {
    name: 'Page E',
    TopPerfomingCourses: -1,
    LowPerfomingCourses: 4,
    amt: 2181,
  },
  {
    name: 'Page F',
    TopPerfomingCourses: 2,
    LowPerfomingCourses: -3,
    amt: 2500,
  },
  {
    name: 'Page G',
    TopPerfomingCourses: 3,
    LowPerfomingCourses: 4 ,
    amt: 2100,
  },
];
const data01 = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
  { name: 'Group E', value: 278 },
  { name: 'Group F', value: 189 },
];
const data02 = [
  { name: 'User A', value: 24 },
  { name: 'User B', value: 45 },
  { name: 'User C', value: 13 },
];

const Linedata = [
  {
    name: 'Aug -2023',
    session: 1000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Sep -2023',
    session: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Oct -2023',
    session: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Nov -2023',
    session: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Dec -2023',
    session: 1890,
    pv: 4800,
    amt: 2181,
  }
];
const Piedata = [
  { name: 'Completed', value: 70 },
  { name: 'Not Completed', value: 20 },
];
const COLORS = ['#8884d8', '#82ca9d', '#8884d8', '#82ca9d'];
  return (
    <div className='analyticsMainDiv'>
      <h2>Analytics</h2>
      <Row>
        <Col span={5}>
          <Card>
            <p className='Course-card-head'>
              Active Courses
            </p>
            <h1 className='Course-score'>
              10
            </h1>
          </Card>
        </Col>
        <Col style={{marginLeft:26}} span={5}>
          <Card>
            <p className='Course-card-head'>
              Total Users
            </p>
            <h1 className='Course-score'>
              10
            </h1>
          </Card>
        </Col>
        <Col style={{marginLeft:26}}  span={5}>
          <Card>
            <p className='Course-card-head'>
              Active Courses
            </p>
            <h1 className='Course-score'>
              10
            </h1>
          </Card>
        </Col>
        <Col style={{marginLeft:26}} span={5}>
          <Card>
            <p className='Course-card-head'>
              Mandatory training
            </p>
            <h1 className='Course-score'>
              10
            </h1>
          </Card>
        </Col>


      </Row>
      <Row style={{marginTop:20}}>
        <Col span={10}>
          <Card>
          <p className='Course-graph-head'>
             Course completion rate
            </p>
            <BarChart width={350} height={300} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="MandatoryCourses" fill="#8884d8" />
              <Bar dataKey="OtherCourses" fill="#82ca9d" />
            </BarChart>
          </Card>
        </Col>
        <Col offset={1} span={11}>
          <Card>
            <p className='Course-graph-head'>
             Course Perfomance
            </p>
        <BarChart
          width={450}
          height={300}
          data={data1}
          stackOffset="sign"
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis  />
          <YAxis />
          <Tooltip />
          <Legend />
          <ReferenceLine  stroke="#000" />
          <Bar dataKey="TopPerfomingCourses"
          //  label={{ position: "insideBottom", fill: "black" }}  
           fill="#8884d8" stackId="stack" />
          <Bar dataKey="LowPerfomingCourses" fill="#82ca9d" stackId="stack" />
        </BarChart>
          </Card>
        </Col>

      </Row>
      <Row style={{marginTop:20}}>
        <Col span={5}>
        <Card 
        className='thirdrowCard'
        >
        <p className='Course-graph-head'  style={{margin:'0px 0px 10px 0px'}}>
             Training Engagement
            </p>
<PieChart   width={230} height={150}>
        
          <Pie
            dataKey="value"
           label={{ position: "inside", fill: "black" }} 
            isAnimationActive={true}
            data={data02}
            cx="35%"
            cy="50%"
            outerRadius={40}
            fill="#8884d8"
          />
         
          <Tooltip />
        </PieChart>
        </Card>
        </Col>
        <Col offset={1} span={10}>
        <Card className='thirdrowCard' >
          <p className='Course-graph-head' style={{margin:'0px 0px 10px 0px'}} >
            Training Session over Time
          </p>
          <LineChart width={300} height={150}
          data={Linedata}
          style={{marginRight:10}}
        
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
          //  type="monotone"
           dataKey="session" stroke="#8884d8" 
           activeDot={{ r: 4 }} />
         
        </LineChart>

        </Card>
        </Col>
      
        <Col offset={1} span={6}>
        <Card className='thirdrowCard' >
        <p className='Course-graph-head' style={{margin:'0px 0px 10px 0px'}} >
            Training Completion Rates
          </p>
        <PieChart width={150} height={150}
        // onMouseEnter={this.onPieEnter}
        >
        <Pie
        
        label={{ position: "inside", fill: "black" }} 
          data={Piedata}
          // cx={75}
          // cy={100}
          cx="50%"
          cy="45%"
          innerRadius={30}
          outerRadius={40}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
       
      </PieChart>

        </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Analytics