// export const BaseUrl = `https://api-training-nexus.valuehealthsolutions.com/`
// export const BaseUrl = `https://nestapi.dev.training-nexus.healthitplatform.com/`
// export const BaseUrl = 'http://localhost:9008/'
export const BaseUrl = process.env.REACT_APP_BASEURL
// export const BaseUrl = 'http://localhost:9009/'


export const AssesmentUrl = process.env.REACT_APP_ASSESSMENTURL
// export const AssesmentUrl = 'https://py2api.dev.training-nexus.healthitplatform.com/'

// export const FrontendUrl = 'http://localhost:4000/training/'
export const FrontendUrl = process.env.REACT_APP_FRONTENDURL
// export const FrontendUrl = 'https://training-nexus.valuehealthsolutions.com/training/'

export const REACT_APP_ACESS_KEY_ID =  process.env.REACT_APP_ACESS_KEY_ID
export const REACT_APP_SECRET_ACESS_KEY =  process.env.REACT_APP_SECRET_ACESS_KEY
export const REACT_APP_REGION = process.env.REACT_APP_REGION