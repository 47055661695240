import { Col, DatePicker, Input, Row, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { BaseUrl ,AssesmentUrl} from '../../../Constants/Constants';
import { Select,Checkbox ,Button,notification} from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const { RangePicker } = DatePicker;

const TrainingCompletion = () => {

    const [api, contextHolder] = notification.useNotification();
  const [userData, setUserData] = useState([]);
  // const [selectedTeam, setSelectedTeam] = useState('d8d3b0cb-02f4-4fe2-9ef6-afc0a34ef8c3');
    const [selectedTeam, setSelectedTeam] = useState('');
  const [teamOption, setTeamOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ selectLoading, setSelectLoading ] = useState(false);
  const [allData ,setAllData]= useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [sortbyScore ,setSortByScore]= useState("");
  const [ betweenDates, setBetweenDates ] = useState([]);
  const [clearTableData, setClearTableData] = useState([]);
  const [searchMail, setSearchMail] = useState('');
  const [ previousSelectedRowKeys, setPreviousSelectedRowKeys ] = useState([]);

  const navigate = useNavigate();


  // const rowSelection = {
  //     selectedRowKeys,
  //     onChange: (selectedRowKeys, selectedRows) => {
  //       const updatedSelectedRowKeys = new Set([...selectedRowKeys, ...newSelectedRowKeys]);
  //         setSelectedRowKeys(selectedRowKeys);
  //     },
  // };

  // const onSelectChange = (newSelectedRowKeys) => {
  //   const updatedSelectedRowKeys = new Set([...selectedRowKeys, ...newSelectedRowKeys]);
  //   setSelectedRowKeys(updatedSelectedRowKeys);
  // };


  // const rowSelection = {
  //   selectedRowKeys: Array.from(selectedRowKeys),
  //   onChange: (newSelectedRowKeys) => {
  //     onSelectChange(newSelectedRowKeys);
  //   },
  // };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    onSelect: (record, selected, selectedRows) => {
      const keys = selectedRows.map(row => row.key);
      setSelectedRowKeys(keys);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      const keys = selectedRows.map(row => row.key);
      setSelectedRowKeys(keys);
    },
  };



  const handleSortChange = (value) => {
    if (value === 'score') {
      setSortByScore(true);
    } else {
      setSortByScore(false);
    }
  };

  const dataSource = allData?.map((data, index) => ({
    key: index + 1,
    serial: index + 1,
    username: data.username,
    email: data.email,
    no_of_question: data.no_of_question,
    score: data.max_score,
    course_title: data.course_title,
    date: data.created_dt,
    status:"completed"
  })).sort((a, b) => new Date(b.date) - new Date(a.date)).map((data, index) => ({
    ...data,
    key: index + 1,
  }));

  useEffect(() => {
    if (selectedRowKeys.length != 0) {



      ///////====================Last working code without removing the unchecking the row data=========/////////

      const mergedSelectedRowKeys = Array.from(new Set([...previousSelectedRowKeys, ...selectedRowKeys]));

    let filteredUsers = mergedSelectedRowKeys.map(i => clearTableData.find(item => item.key === i));
    // Ensure each filtered user has a unique key
    filteredUsers = filteredUsers.map((user, index) => ({
      courseTitile: user?.course_title,
      username: user?.username,
      email: user?.email,
      noOfQuestions: user?.no_of_question,
      score: user?.score,
      date: user?.date
    }));

    console.log(allData,  'filteredUsers-->',filteredUsers, 'clearTableData---->', clearTableData)
    setUserData(filteredUsers);
    // Update the previous selected row keys
    setPreviousSelectedRowKeys(mergedSelectedRowKeys);

    }
    else {

      let users = clearTableData.map((item) => {
        let userObj = {
          // key: item.user_id,
          courseTitile: item?.course_title,
          username: item?.username,
          email: item?.email,
          noOfQuestions: item?.no_of_question,
          score: item?.max_score,
          date: item?.date
          // team: item.team_id.Team_name
        }
        return userObj
      })
    // console.log(users, 'filteredUsers')


      setUserData(users);
      setPreviousSelectedRowKeys([]);
    }
  }, [selectedRowKeys, clearTableData.length])

  


  const sortedDataSource = sortbyScore ? [...allData].sort((a, b) => b.score - a.score) : allData;
  const handleDownload = () => {
    const columnNames = ['Course Title', 'Username', 'Email', 'No of Questions', 'Score', 'Date'];
    // const csvContent = "data:text/csv;charset=utf-8," +
    //   userData.map(row => Object.values(row).join(",")).join("\n");
    const csvContent = `data:text/csv;charset=utf-8,${[columnNames.join(',')].concat(userData.map(row => Object.values(row).join(","))).join("\n")}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "Course Completion Reports.csv");
    document.body.appendChild(link);
    link.click();
  };

  const getDatesBetween = (startDate, endDate) => {
    let dates = [];
    let currentDate = moment(startDate);
    const end = moment(endDate);
  
    while (currentDate <= end) {
      dates.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'days');
    }
  
    return dates;
  };

  const handleDateRangeChange = (dates, dateString) => {

    const [startDate, endDate] = dateString;
    const datesBetween = getDatesBetween(startDate, endDate);
    
    console.log(datesBetween, 'dateString')
    setBetweenDates(datesBetween);



  };

  const filterData = () => {
    let filteredData = [...allData];

    // Filter by email
    if (searchMail !== '') {
      filteredData = filteredData.filter(data =>
        data.course_title.toLowerCase().includes(searchMail.toLowerCase())
      );
    }

    if( betweenDates.length != 0 ) {
      const dateOfDates = ['2024-06-11', '2024-06-12', '2024-06-13', '2024-06-14', '2024-06-15', '2024-06-16', '2024-06-17', '2024-06-18', '2024-06-19', '2024-06-20', '2024-06-21', '2024-06-22', '2024-06-23', '2024-06-24', '2024-06-25', '2024-06-26', '2024-06-27', '2024-06-28', '2024-06-29']
      filteredData = filteredData.filter( item => betweenDates.includes(item.date) )
    }

    console.log(filteredData, 'filteredData')

    // Map to format required by clearTableData
    const formattedData = filteredData.map((data, index) => ({
      key: data.s_num,
      serial: index + 1,
      username: data.username,
      email: data.email,
      no_of_question: data.no_of_question,
      score: data.max_score,
      course_title: data.course_title,
      date: data.created_dt,
      status:"Completed"
    }));

    setClearTableData(formattedData);
  };


  useEffect(() => {
    filterData();
  }, [searchMail, allData, betweenDates]);
  
  console.log(selectedRowKeys, 'dataSource')


  useEffect(()=>{
    axios.get(`${AssesmentUrl}last_best_score`)
    .then((res)=>{
      console.log(res.data, "res")
      setAllData(res.data.score)
    })
    .catch((err)=>{
      console.log("errr", err)
    })

  },[])


  const columns = [

    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
    },
    {
      title: 'Course Name',
      dataIndex: 'course_title',
      key: 'course_title',
      align: 'center',
    },
    // {
    //   title: 'No. of Questions',
    //   dataIndex: 'no_of_question',
    //   key: 'no_of_question',
    //   align: 'center',
    // },
    {
      title: ' Best Score',
      dataIndex: 'score',
      key: 'score',
      align: 'center',
      sorter: (a, b) => a.score - b.score,
    },
    
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
    },
 
    {
      title: 'Completion Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      render: (text) => {
        const formattedDate = new Date(text).toLocaleDateString('en-US');
        return formattedDate;
      },
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
  ];

  
 
  useEffect(() => {
    axios.get(`${BaseUrl}teams`)
      .then((res) => {
        let teams = res.data.map((item) => {
          let teamsObj = {
            value: item.team_id,
            label: item.Team_name
          }
          return teamsObj;
        })
        setTeamOption(teams);

      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [])

  useEffect(() => {
    axios.get(`${BaseUrl}users`)
      .then((res) => {
        let userList = res.data;
        console.log(res.data, 'totalUsers')
        if (selectedTeam === '' ||
          selectedTeam === null ||
          selectedTeam === '33f51844-7153-4e3f-86c8-eb5a017d1a9d' ||
          selectedTeam === 'd8d3b0cb-02f4-4fe2-9ef6-afc0a34ef8c3' ||
          selectedTeam === 'bf24ff2d-7984-4835-9b17-e7e2edf6c6b4'
        ) {

          let users = userList.map((item) => {
            let userObj = {
              key: item.user_id,
              username: item.username,
              email: item.email,
              team: item.team_id.Team_name
            }
            return userObj
          })
          setUserData(users);
        }
        else {
          let filteredUsers = userList.filter(i => i.team_id.team_id === selectedTeam);

          let users = filteredUsers.map((item) => {
            let userObj = {
              key: item.user_id,
              username: item.username,
              email: item.email,
              team: item.team_id.Team_name
            }
            return userObj
          })

          console.log(users, 'filteredUsers');
          setUserData(users);
        }
      })
      .catch((err) => {
        console.log(err.message);
      })
  }, [selectedTeam])


  
  
const openNotificationWithIconWarning = (type) => {
  api['warning']({
      message: "Warning!!!",
      description:
          type,
  });
};
const sendEmail = () => {
  console.log("hi",selectedRowKeys)
  // Convert JSON to CSV
  if(selectedRowKeys.length > 0){

    // const csvData = "data:text/csv;charset=utf-8,"+ encodeURIComponent(convertJsonToCsv(userData));
    const csvData = convertJsonToCsv(userData);

     // Create CSV blob
     console.log(csvData,"csvData")
  const csvBlob = new Blob([csvData], { type: 'text/csv' });

  // Create FormData and append the CSV blob
  const formData = new FormData();
  formData.append('file', csvBlob, 'data.csv');
  console.log(formData,"form")
    
    // Create mailto link with attachment
  const mailtoLink = `mailto:recipient@example.com?subject=JSON%20data%20as%20CSV%20attachment&body=Please%20find%20the%20attached%20CSV%20file.`;

  // Open default email client
  window.location.href = `${mailtoLink}&attachments=data.csv&attachment=${encodeURIComponent(URL.createObjectURL(csvBlob))}`;
  }else{
    openNotificationWithIconWarning("Select Users")
  }
};

const convertJsonToCsv = (json) => {
  if (!json || json.length === 0) return '';

  const header = Object.keys(json[0]).join(",");
  const rows = json.map(obj => Object.values(obj).join(","));
  return header + "\n" + rows.join("\n");
};

const handleRoute = (e) => {
  navigate(e)
}

  return (
    <div className='scoresMainDiv' >
      <div className='reportsSideHeading'>
        <h4 className='nonActiveHeading' onClick={() => handleRoute('/coursesactivityreports')} >Courses Activity Report</h4>
        <h4 className='nonActiveHeading' onClick={() => handleRoute('/trainingreports')} >Training Report</h4>
        <h4 className='activeHeading' 
        // onClick={() => handleRoute('/trainingnoncompletionreports')}
         >Training Non-compliance Report</h4>
          <h4 className='nonActiveHeading' style={{cursor:'not-allowed'}}
                //  onClick={() => handleRoute('/userreports')}
                  >User Report</h4>
        <h4 className='nonActiveHeading' onClick={() => handleRoute('/reports')} >Courses Report</h4>
      </div>
      {contextHolder}
      <h2>Training Completion Report</h2>
      <div>
        <Row style={{ marginBottom: 10 }}>
          <Col span={6} offset={5} >
          <RangePicker style={{ height: '36px' }} onChange={handleDateRangeChange} />
          </Col>
          <Col span={8} offset={1}>
            <Input placeholder='Search by course' className='searchInput'
              onChange={(e) => setSearchMail(e.target.value)}
            />
          </Col>
          <Col offset={1} span={3}>
            <Button style={{ width: '100%' }} type="primary" onClick={handleDownload}>Download Report</Button>
          </Col>
        </Row>
        <div className='tabletopbutton'>
{/* <div className='Reportsprintandmail'> */}
                    {/* <Button type="primary" onClick={handleDownload} >Print</Button> */}
                    {/* <Button type="primary" onClick={sendEmail} >Share Via Mail</Button> */}
                {/* </div> */}
                </div>
        <Table
          // dataSource={userData}
          dataSource={clearTableData}
          columns={columns}
          rowSelection={rowSelection}
          // columns={userColumn}
          rowKey='key'
          pagination={{
            position: ['bottomCenter'],
            showSizeChanger: false,
          }} 
        />
      </div>
    </div>
  )
}

export default TrainingCompletion