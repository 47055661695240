const AWS = require('aws-sdk');
const { REACT_APP_ACESS_KEY_ID, REACT_APP_SECRET_ACESS_KEY, REACT_APP_REGION } = require('../Constants/Constants');

AWS.config.update({
  accessKeyId: REACT_APP_ACESS_KEY_ID,
  secretAccessKey: REACT_APP_SECRET_ACESS_KEY,
  region: 'us-east-1',
  s3ForcePathStyle: true,
});

const s3 = new AWS.S3();

const uploadToS3 = async (file, courseId) => {
  console.log(file,"name",courseId)
  const params = {
    Bucket: 'trainingnexus',
    Key: `dev/${courseId}/${file.originalname}`, // Use the original file name as the key, you may want to modify this based on your naming convention
    Body: file.buffer,
    ACL: 'public-read', // Make the file publicly readable, adjust the ACL based on your needs
  };

  try {
    const data = await s3.upload(params).promise();
    console.log('File uploaded to S3:', data.Location);
    return data.Location; // Return the S3 URL
  } catch (error) {
    console.error('Error uploading file to S3:', error);
    throw error;
  }
};


module.exports = {
  uploadToS3,
};
