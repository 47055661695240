import { Table, Space, Select, Modal, Input, Upload, Card, message, Button } from 'antd';
import React, { useEffect, useState } from 'react'
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { InboxOutlined } from '@ant-design/icons';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import TrainingEditor from '../TrainingEditor/TrainingEditor';
import axios from 'axios';
import { BaseUrl } from '../../Constants/Constants';
const { Dragger } = Upload;

const AddTraining = () => {

    const { trainingId } = useParams();
    const navigate = useNavigate();
  
  
  
    const [newPage, setNewPage] = useState(false);
    const [courseName, setCourseName] = useState("");
    const [dataSource, setDataSource] = useState([]);
    const [editModal, setEditModal] = useState(false);
    const [fullCourses, setFullCourses] = useState([]);
    const [courseParts, setCourseParts] = useState([]);
    const [ modules, setModules ] = useState([]);

    const Profile = localStorage.getItem("Profile");
    const ParsedProfile = JSON.parse( Profile)
  
  
    useEffect(() => {
      let trainingid = trainingId.split('-');
      console.log(trainingid.length, 'trainingid');
      if( trainingid.length != 1 ){
        setNewPage(false);
      } else{
        setNewPage(true)
      }
    }, [])
  
    // Dragger Functions
  
  const props = {
    name: 'file',
    multiple: true,
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };
  
  
  
  
    const handleEditRecord = (record) => {
      console.log(record, 'record');
      let coursePartsArray = [];
      const clickedCourseParts = fullCourses.find(item => item.part === record.parts);
      coursePartsArray.push(clickedCourseParts);
      setCourseParts(coursePartsArray[0].courses);
    }
  
    const columns = [
      {
        title: 'Parts',
        dataIndex: 'parts',
        key: 'parts',
        width: '40%'
      },
      {
        title: 'Type',
        key: 'type',
        render: (_, record) => (
          // <Select defaultValue="user1" style={{ width: '50%' }} options={selectOptions} />
          <p>Video</p>
        ),
        align: 'center'
      },
      {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <a onClick={() => {
              handleEditRecord(record)
              setEditModal(true)
            }}>Edit</a>
          </Space>
        ),
        align: 'center'
      },
    ]
  
  
  
  
    const handleDelete = (e) => {
      console.log(e, 'delete');
      // courseParts.pop(e);
      const newCourseParts = courseParts.filter((item, i) => i != e);
  
      setCourseParts(newCourseParts)
    }
  
    const handleAddCourse = () => {
      message.success("Course Added!");
  
      setTimeout(() => {
        navigate('/managecourses');
      }, 3000)
    }
  
  
  
  
    
  
  
    return (
      <div className='Courses-main'>
        <h2>Add Modules{courseName}</h2>
        {
          newPage ?
          <>
            <Modal className='editModalClass' open={editModal} onCancel={() => setEditModal(false)} >
          <h2>Modules</h2>
          {
            courseParts.length !== 0 ?
              courseParts.map((item, index) => (
                <div className='courseModuleDiv'>
                  <div style={{ width: '60%' }}>
                    <h3>Module</h3>
                    <Input value={item.name} />
                  </div>
                  <div style={{ width: '10%', textAlign: 'center' }}>
                    <h3>Type</h3>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                      <PlayCircleOutlinedIcon style={{ fontSize: '25px', marginTop: '5px', color: '#1677ff' }} />
                      <p style={{ margin: '6px 0px' }}>Play</p>
                    </div>
                  </div>
                  <div>
                    <h3>Delete</h3>
                    <DeleteOutlineOutlined onClick={() => { handleDelete(index) }} style={{ fontSize: '25px', marginLeft: '12px', color: '#ff2e2e', cursor: 'pointer' }} />
                  </div>
                </div>
              ))
              :
              <>
                <h1 style={{ textAlign: 'center' }}>No Modules Yet!</h1>
              </>
          }
        </Modal>
        <Table columns={columns} dataSource={dataSource} />
          </>
          :
          <TrainingEditor />
        }
  
  
        
      </div>
    )
  }

export default AddTraining