import React, { useEffect, useState } from 'react'
import './CourseMapping.css'
import { Row, Col, Card, Select, Pagination } from 'antd'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BaseUrl } from '../Constants/Constants';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const CourseMapping = () => {

    const { Meta } = Card;

    const navigate = useNavigate();

    const [course, setCourse] = useState([]);
    const [transformedData, settransformedData] = useState([])
    const [Title, setTitle] = useState('');
    const [selectedValue, setSelectedValue] = useState('All');
    const [pageSize, setPageSize] = useState(12);
    const [currentPage, setCurrentPage] = useState(1);
    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    useEffect(() => {
        axios.get(`${BaseUrl}courses/findAll`)
            .then((res) => {
                console.log(res.data.data, 'unAssign');
                setCourse(res.data.data)
                settransformedData(res.data.data)
                setTitle("All")
            })
            .catch((err) => {
                console.log(err.message)
            })
    }, [])
    const paginatedCourses = transformedData.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    let type = selectedValue.toLocaleLowerCase();
    console.log(type, 'selectedValue')
    const handleCard = (e) => {



        navigate(`/assigncourse/${type}/${e.course_id}`)
    }

    const handleChange = (e) => {
        console.log(e)
        if (e == "Unassigned") {
            setTitle(e)
            let arr = []

            let mappedcourse = course.map((i) => {

                if (i.team_id != null && i.team_id != "") {
                } else {
                    let obj = {

                        course_img: i.course_img,
                        course_title: i.course_title,
                        course_id: i.course_id
                    }
                    arr.push(obj)

                }
                settransformedData(arr)
                // console.log(mappedcourse,"mapp")
                console.log(arr, "array")
            })
        }
        if (e == "Assigned") {
            setTitle(e)
            let arr = []

            let mappedcourse = course.map((i) => {

                if (i.team_id != null && i.team_id != "") {
                    let obj = {

                        course_img: i.course_img,
                        course_title: i.course_title,
                        course_id: i.course_id
                    }
                    arr.push(obj)

                }
                settransformedData(arr)
                console.log(arr, "array")
            })
        }
        if (e == "All") {
            setTitle(e)
            settransformedData(course)
        }
    }




    return (
        <div className='courseMappingMain' style={{ padding: "25px 25px 25px 110px" }}>
            {/* <p className='back'>
                 <span onClick={(e)=> navigate(`/assigncourse`)} style={{ color: '#4096FF',textDecoration:"underline"}}>
                 /courses
                </span>
                <span>
                    <ArrowForwardIosIcon className='arrow'/>
                </span>
                <span  style={{ color: '#454545',textDecoration:""}}>
                 {Title}
                </span>
                
              
            </p> */}

            {/* <Select
                className='Select'
                onChange={handleChange}
                defaultValue={"All"}
                value={selectedValue}
                onSelect={(value) => setSelectedValue(value)}
                options={[
                    {
                        value: 'Unassigned',
                        label: 'Unassigned',
                    },
                    {
                        value: 'Assigned',
                        label: 'Assigned',
                    },
                    {
                        value: 'All',
                        label: 'All',
                    }
                ]}>

            </Select> */}
            <p>
                No of Courses : {transformedData.length}
            </p>
            <h2>{Title} <></>Courses</h2>
            <Row className="manageChatbotRow">




                {transformedData.length != 0 ? (
                    <>
                        {paginatedCourses.map((item) => {

                            return (
                                <div className="Course-card">
                                    <Col span={6} offset={2} className="manageBotCardCol">
                                        <Card
                                            onClick={() => handleCard(item)}
                                            hoverable
                                            className="cards"
                                            style={{
                                                width: 200,
                                                padding: 2,
                                            }}
                                        // cover={


                                        // }
                                        >

                                            <Meta
                                                title={
                                                    item.course_title != ""
                                                        ? item.course_title
                                                        : `Course`
                                                }
                                            // title={'Bot' + " " + (key + 1)}
                                            />
                                            <img
                                                alt="Course Image"
                                                src={item.course_img === '' || item.course_img === null ? "icons/CourseIcons.png" : item.course_img}
                                                height={130}
                                                style={{ width: "100%" }}
                                            />

                                        </Card>
                                    </Col>
                                </div>
                            );
                        })}
                    </>
                ) : (
                    <h3>No items</h3>
                )}
            </Row>
            <Pagination
                onChange={handlePaginationChange}
                current={currentPage}
                pageSize={pageSize}
                total={transformedData.length}
                showSizeChanger={false}
                style={{ position: 'absolute', bottom: '0px', left: '45%' }}
            />

        </div>
    )
}

export default CourseMapping