import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AssesmentUrl, BaseUrl, REACT_APP_ACESS_KEY_ID, REACT_APP_SECRET_ACESS_KEY } from '../../../Constants/Constants';
import { Button, Card, Input, message, Modal, Progress, Switch } from 'antd';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import './ManageCourse.css'
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import 'pdfjs-dist/webpack';
import ReactPlayer from 'react-player';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import AWS from 'aws-sdk'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddParts from './AddParts/AddParts';
import { DeleteOutline } from '@mui/icons-material';
import AddTrainingModule from './AddTrainingModule/AddTrainingModule';
import Lottie from 'lottie-react';
import loadingJson from '../../../LottieAnimation/save-loading.json'

const ManageCourse = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const playerRef = useRef(null);
    const courseInputRef = useRef(null);
    const partInputRef = useRef(null);
    const topicInputRef = useRef(null);

    const profileData = JSON.parse(localStorage.getItem('Profile'));
    const userEmail = profileData.email;
    console.log(userEmail, 'profileData');




    const [courseData, setCourseData] = useState([]);
    // const [ partTitleInput, setPartTitleInput ] = useState(false);
    const [editingPartId, setEditingPartId] = useState(null);
    const [editingTrainingId, setEditingTrainingId] = useState(null);
    const [partTitles, setPartTitles] = useState({});
    const [partTitleVal, setPartTitleVal] = useState('');
    const [trainingTitles, setTrainingTitles] = useState({});
    const [trainingTitleVal, setTrainingTitleVal] = useState('');
    const [pdfModal, setPdfModal] = useState(false);
    const [videoModal, setVideoModal] = useState(false);
    const [draggerModal, setDraggerModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [videoUrl, setVideoUrl] = useState('');
    const [file, setfile] = useState([]);
    const [trainingId, setTrainingId] = useState('');
    const [uploadErr, setUploadErr] = useState('');
    const [errorFlag, setErrorFlag] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [courseName, setCourseName] = useState('');
    const [editCourse, setEditCourse] = useState(false);
    const [spinLoader, setSpinLoader] = useState(false);
    const [deleteDisable, setDeleteDisable] = useState(false);
    const [addTopicModal, setAddTopicModal] = useState(false);
    const [addTopicName, setAddTopicName] = useState('');
    const [addTopicFile, setAddTopicFile] = useState([]);
    const [addPartId, setAddPartId] = useState('');
    const [addTopicFileS3Url, setAddTopicFileS3Url] = useState('');
    const [addFileDuration, setAddFileDuration] = useState('');
    const [addFileType, setAddFileType] = useState('');
    const [Percent, setPercent] = useState('0');
    const [loading, setLoading] = useState(false);
    // const [ isPlaying, setIsPlaying ] = useState(false);
    const [renderPlayer, setRenderPlayer] = useState(true);
    const [saveAllow, setSaveAllow] = useState(false);
    const [s3Urls, setS3Urls] = useState([]);
    const [saveLoading, setSaveLoading] = useState(false);
    const [courseIgnoreBlur, setCourseIgnoreBlur] = useState(false);
    const [ courseStatus, setCourseStatus ] = useState(false);
    const [ statusLoading, setStatusLoading ] = useState(false);
    const [partIgnoreBlur, setPartIgnoreBlur] = useState(false);
    const [topicIgnoreBlur, setTopicIgnoreBlur] = useState(false);

    console.log(courseIgnoreBlur, 'courseIgnoreBlur')


    useEffect(() => {
        if (editCourse && courseInputRef.current) {
            courseInputRef.current.focus(); // Automatically focus the input when editCourse is true
        }
    }, [editCourse]); // Dependency on editCourse, runs when editCourse changes

    useEffect(() => {
        if (editingPartId !== null && partInputRef.current) {
            partInputRef.current.focus(); // Automatically focus the input when editCourse is true
        }
    }, [editingPartId]);

    useEffect(() => {
        if (editingTrainingId !== null && topicInputRef.current) {
            topicInputRef.current.focus(); // Automatically focus the input when editCourse is true
        }
    }, [editingTrainingId]);



    AWS.config.update({
        region: 'us-east-1',
        credentials: new AWS.Credentials({
            accessKeyId: REACT_APP_ACESS_KEY_ID,
            secretAccessKey: REACT_APP_SECRET_ACESS_KEY,
            s3ForcePathStyle: true,
        }),
    });

    const s3 = new AWS.S3();

    const findCourseName = () => {

        let body = {
            courseId: id
        }

        axios.post(`${BaseUrl}courses/findOne`, body)
            .then((res) => {
                console.log(res.data, 'findOneResponse');
                if (res.data.data.length != 0) {
                    setCourseName(res.data.data[0].course_title);
                    setCourseStatus(res.data.data[0].is_active);
                }
            })
            .catch((err) => {
                console.log(err.message, 'findOne API Error')
            })
    }
    console.log(saveAllow, 'addPartsValueFromProps')
    console.log(s3Urls, 'allS3Urls')

    const courseDetails = (value) => {
        setSaveAllow(value);


        let body = {
            courseId: id
        }

        axios.post(`${BaseUrl}course-parts/findOneByCourseId`, body)
            .then((res) => {
                console.log(res.data.data, 'findOneByCourseId');
                if (res.data.data.length) {
                    const initialPartTitles = res.data.data.reduce((acc, item) => {
                        console.log(acc, 'accCourseData')
                        acc[item.partId] = item.partTitle;
                        return acc;
                    }, {});

                    setPartTitles(initialPartTitles);

                    const allS3Urls = res.data.data.map(item => (
                        item.training.map(trainings => (
                            trainings.module
                        ))
                    ))

                    setS3Urls(allS3Urls.flat());


                    const initialTrainingTitles = res.data.data.reduce((acc, item) => {
                        item.training.forEach(training => {
                            acc[training.id] = training.name;
                        });
                        return acc;
                    }, {});

                    setTrainingTitles(initialTrainingTitles);

                    console.log(initialTrainingTitles, 'initialTrainingTitles')

                }
                setCourseData(res.data.data);
            })
    }

    useEffect(() => {
        courseDetails();
        findCourseName();
    }, [])


    const addTopicUploadFileToS3 = (filess, trainingModuleDuration, trainingModuleType) => {
        if (filess.length === 0) {
            //   setVectorError('Please Upload Document!');
            message.error('Please Upload Document!');
            return;
        }
        setLoading(true);
        setPercent('0')

        const uploadPromises = filess.map((file) => {
            // const fileName = `https://trainingnexus.s3.amazonaws.com/DLP/${courseId}/${file.name}`
            const params = {
                Bucket: 'trainingnexus',
                Key: `DLP/${id}/${file.name}`,
                Body: file,
            };

            return new Promise((resolve, reject) => {
                s3.upload(params, { partSize: 5 * 1024 * 1024, queueSize: 1 })
                    .on('httpUploadProgress', (progress) => {
                        const uploadedPercentage = Math.round((progress.loaded / progress.total) * 100);
                        setPercent(uploadedPercentage);
                    })
                    .send((err, data) => {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(data);

                            const moduleDuration = `${Math.floor(trainingModuleDuration / 60)}:${Math.floor(trainingModuleDuration % 60).toString().padStart(2, '0')}`
                            setAddFileDuration(moduleDuration);
                            setAddFileType(trainingModuleType);
                            setAddTopicFileS3Url(data.Location);
                        }
                    });
            });
        });

        Promise.all(uploadPromises)
            .then(() => {
                console.log("reached1")
                setLoading(false);
            })
            .catch((err) => {
                console.log(err, "upload err");
                setLoading(false);
            });


    };

    const uploadFileToS3 = (filess, trainingDuration, trainingType) => {

        setUploadLoading(true);
        const uploadPromises = filess.map((file) => {
            // const fileName = `https://trainingnexus.s3.amazonaws.com/DLP/${courseId}/${file.name}`
            const params = {
                Bucket: 'trainingnexus',
                Key: `DLP/${id}/${file.name}`,
                Body: file,
            };

            return new Promise((resolve, reject) => {
                s3.upload(params, { partSize: 5 * 1024 * 1024, queueSize: 1 })
                    .on('httpUploadProgress', (progress) => {
                        const uploadedPercentage = Math.round((progress.loaded / progress.total) * 100);
                        setPercent(uploadedPercentage);
                    })
                    .send((err, data) => {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(data);
                        }
                    });
            });
        });

        Promise.all(uploadPromises)
            .then((res) => {
                console.log(res[0].Location, "s3UploadData", trainingId);
                const moduleDuration = `${Math.floor(trainingDuration / 60)}:${Math.floor(trainingDuration % 60).toString().padStart(2, '0')}`
                if (res[0].Location) {
                    let body = {
                        trainingModuleId: trainingId,
                        trainingModule: res[0].Location,
                        trainingModuleType: trainingType,
                        trainingDuration: moduleDuration
                    }

                    axios.post(`${BaseUrl}training-module/update`, body)
                        .then((response) => {

                            console.log(response.data, 'updateResponse', trainingId, res[0].Location)
                            if (response.data.statuscode === 200) {
                                courseDetails();
                                setUploadLoading(false);
                                setDraggerModal(false);
                                setSaveAllow(true);
                                message.success("Module Changed Successfully!!");
                            }
                        })
                        .catch(err => {
                            console.log(err.message, 'Update Api Error');
                            setUploadLoading(false);
                            setDraggerModal(false);
                            message.error(`Can't Update Module`);
                        })
                }

            })
            .catch((err) => {
                console.log(err, "upload err");
                setUploadLoading(false);
                setDraggerModal(false);
                message.error(`Can't change module`)
            });


    };

    const handleCourseInputChange = (e) => {
        setCourseName(e.target.value);
    };

    const handleCourseNameSave = (event) => {
        // setCourseIgnoreBlur(true);
        // event.preventDefault();
        // event.stopPropagation();
        setSpinLoader(true);
        let body = {
            "courseId": id,
            "courseTitle": courseName
        }

        axios.post(`${BaseUrl}courses/update`, body)

            .then((res) => {
                console.log(res.data, 'updateResponse');

                if (res.data.statuscode === 200) {
                    // setCourseName(courseName);
                    setEditCourse(false);
                    message.success('Course name changed successfully!');
                    setSpinLoader(false);
                } else {
                    setEditCourse(false);
                    setSpinLoader(false);
                    message.error(`Can't change the course name`)
                }
            }).catch((err) => {
                message.error(err.message);
                setEditCourse(false);
                setSpinLoader(false);
            })
    }

    const handleInputChange = (e, partId) => {
        setPartTitles({
            ...partTitles,
            [partId]: e.target.value,
        });

        setPartTitleVal(e.target.value);

    };

    const handlePartSave = (partId) => {
        setSpinLoader(true);

        if (partTitleVal !== '') {
            let body = {
                partId: partId,
                partTitle: partTitleVal
            }

            axios.post(`${BaseUrl}course-parts/update`, body)
                .then((res) => {
                    if (res.data.statuscode === 200) {
                        setEditingPartId(null);
                        message.success('Part name changed successfully!');
                        setSpinLoader(false);
                    } else {
                        message.error('Error changing part name');
                        setSpinLoader(false);
                    }
                    console.log(res.data, 'responseData');
                })
                .catch((err) => {
                    message.error(err.message);
                    setSpinLoader(false);
                })

        } else {
            setEditingPartId(null);
            setSpinLoader(false);
        }

        console.log(partId, 'partId');
    };

    const handleTrainingInputChange = (e, trainingId) => {
        setTrainingTitles({
            ...trainingTitles,
            [trainingId]: e.target.value,
        });
        setTrainingTitleVal(e.target.value);
    };

    console.log(trainingTitleVal, 'trainingTitleVal')

    const handleSaveTrainingName = (trainingId) => {
        setSpinLoader(true);
        if (trainingTitleVal !== '') {
            let body = {
                trainingModuleId: trainingId,
                trainingModuleName: trainingTitleVal
            }
            axios.post(`${BaseUrl}training-module/update`, body)
                .then((res) => {
                    console.log(res.data, 'trainingModuleAPI')
                    if (res.data.statuscode === 200) {
                        setEditingTrainingId(null);
                        setSpinLoader(false);
                        message.success('Training module name changed successfully!');
                    } else {
                        message.error('Error changing training module name');
                        setSpinLoader(false);
                    }
                }).catch((err) => {
                    message.error(err.message);
                    setSpinLoader(false);
                })
        } else {
            setEditingTrainingId(null);
            setSpinLoader(false);
        }

    };

    const handleShowModule = (module, type) => {
        console.log(module, 'trainingModule', type);
        if (type === 'pdf') {
            setPdfModal(true);
            setPdfUrl(module);
        } else if (type === 'mp4' || type === 'mov') {
            setVideoModal(true);
            setVideoUrl(module);
            // setIsPlaying(true);
        }
    }

    const handleShowDraggerModal = (trainingID) => {
        setTrainingId(trainingID);
        setDraggerModal(true);
    }
    // console.log(trainingId, 'trainingId')

    const handlePdfCancel = () => {
        setPdfModal(false);
        setPdfUrl('');
    }

    const handleVideoCancel = () => {
        setVideoModal(false);
        setVideoUrl('');
        if (playerRef.current) {
            playerRef.current.getInternalPlayer().pause(); // Pause the video
        }
        // setTimeout(() => setRenderPlayer(true), 0);
    };

    console.log(playerRef, 'isPlaying')

    const playerConfig = {
        file: {
            attributes: {
                controlsList: 'nodownload', // Disable the download button
            },
        },
    };

    console.log(courseData, 'courseDataManageCourse')

    const handleDeleteTrainingModule = (trainingId) => {


        for (const part of courseData) {
            const training = part.training.find(t => t.id === trainingId);
            if (training) {
                const trainingLength = part.training.length;
                console.log(`The length of the training array for trainingId ${trainingId} is ${trainingLength}`);
                if (trainingLength > 1) {
                    setDeleteDisable(true);
                    let body = {
                        trainingModuleId: trainingId,
                        isActive: false
                    }

                    axios.post(`${BaseUrl}training-module/update`, body)
                        .then((res) => {
                            console.log(res.data, 'updateResponse');

                            if (res.data.statuscode === 200) {
                                message.success('Module Deleted Successfully!');
                                courseDetails();
                                setDeleteDisable(false);
                            } else {
                                message.error('Error while deleting the module!')
                                setDeleteDisable(false);
                            }
                        })
                        .catch((err) => {
                            console.log(err.message, 'deleteTrainingModuleCatchErr');
                            message.error(err.message);

                        })
                } else {
                    message.error('The part should have atleast one topic, Please update the topic!');
                    setDeleteDisable(false);
                }
            }
        }


    }

    const addTrainingModule = (partId) => {
        setAddTopicModal(true);
        setAddPartId(partId);
    }

    const handleCancelTopicModal = () => {
        setAddPartId('');
        setAddTopicModal(false);
        setfile([]);
        setAddTopicName('');
        setAddTopicFile([]);
        setAddFileDuration('');
        setAddFileType('');
        setAddTopicFileS3Url('');
    }

    const handleCreateTopic = () => {
        console.log();
        if (addTopicName === '' || addTopicFile.length === 0) {
            message.error('Please fill all the fields!');
        } else {
            let body = {
                partId: addPartId,
                trainingModuleName: addTopicName,
                trainingModuleType: addFileType,
                trainingModuleDuration: addFileDuration,
                trainingModule: addTopicFileS3Url
            }

            axios.post(`${BaseUrl}training-module/addTrainingModule`, body)
                .then((res) => {
                    if (res.data.statuscode === 200) {
                        message.success(`Topic added successfully!`);
                        courseDetails(true);
                        handleCancelTopicModal();
                    } else {
                        message.error('Error while adding topic');
                    }
                })
                .catch((err) => {
                    message.error(err.message);
                    console.log(err.message, 'add topic error')
                })

            console.log(body, 'addTopicBody')
        }
    }

    const handleBack = () => {
        if (saveAllow) {
            message.error('Please save the changes before exit!')
        } else {
            navigate(`/managecourses`);
        }
    }


    const handleSaveChanges = () => {
        setSaveLoading(true);
        if (saveAllow) {

            let body = {
                course_id: id,
                url: s3Urls,
                mailid: userEmail
            }

            axios.post(`${AssesmentUrl}content_extraction`, body)
                .then((res) => {
                    if (res.data.status === 200) {
                        message.success('Changes Saved Successfully!');
                        setTimeout(() => {
                            navigate('/managecourses');
                        }, 1000)
                        setSaveLoading(false);
                        setSaveAllow(false);
                    } else {
                        message.error('Error while extracting files, Please try after some time!');
                        setSaveLoading(false);
                    }
                }).catch(err => {
                    message.error(err.message);
                    setSaveLoading(false);
                })

        } else {
            // message.error('No modules or parts added! Going back!');
            navigate('/managecourses');
            setSaveLoading(false);
        }
    }


    console.log(courseStatus, 'courseStatus')

    const handleStatusChange = (e) => {
        console.log(e, 'statusCheckedValue')
        setCourseStatus(e)
        setStatusLoading(true);
        let body = {
            courseId : id,
            isActive : e
        }

        axios.post(`${BaseUrl}courses/update`, body)
        .then(( res ) => {
            console.log(res.data, 'courseStatusupdate');
            if( res.data.statuscode === 200 ){
                setStatusLoading(false);
                message.success(`course status changed to ${ e ? 'active' : 'inactive' } `)
            } else{
                message.error('Error updating the course status!')
                setStatusLoading(false);
            }

        }) .catch((errr) => {
            message.error(errr.message);
            setStatusLoading(false);
        })

    }



    return (
        <div className='Courses-main'>
            <Modal className='videoModalClass' title='Preview PDF' open={pdfModal} onOk={() => setPdfModal(false)} onCancel={handlePdfCancel} footer={false} >
                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                    <Viewer fileUrl={pdfUrl} />
                </Worker>
            </Modal>
            <Modal className='videoModalClass' title='Preview Video' open={videoModal} onOk={() => setVideoModal(false)} onCancel={handleVideoCancel} footer={false} >
                {
                    // renderPlayer &&(
                    <ReactPlayer
                        ref={playerRef}
                        controls
                        config={playerConfig}
                        url={videoUrl}
                        width='100%'
                        playing={true}
                    />
                    // )
                }
            </Modal>
            <Modal title='Add Topic' onCancel={handleCancelTopicModal} open={addTopicModal} footer={false} >
                <div>
                    <h4>Topic</h4>
                    <Input className='courseTopicInput' placeholder='Enter topic name' value={addTopicName} onChange={(e) => setAddTopicName(e.target.value)} />
                    <Dragger className='moduleUploadDragger'

                        name='file'
                        action=''
                        // showUploadList={false}
                        maxCount={1}
                        beforeUpload={() => false}
                        fileList={addTopicFile}
                        onRemove={() => setAddTopicFile([])}
                        onChange={(info) => {
                            console.log(info, 'addTopicInfo')
                            const fileObj = info.fileList.map((item) => item.originFileObj);
                            if (fileObj.length > 0) {
                                const file = fileObj[0];
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                    console.log(file.type, 'fileTypeofCourseModule')
                                    if (addTopicModal) {
                                        if (file.type === 'application/pdf') {
                                            addTopicUploadFileToS3(fileObj, null, 'pdf');
                                            setAddTopicFile(info.fileList);
                                        } else if (file.type === 'video/mp4') {
                                            const video = document.createElement('video');
                                            video.src = e.target.result;
                                            console.log(file.type, 'fileObj')
                                            video.onloadedmetadata = () => {
                                                const duration = video.duration;
                                                addTopicUploadFileToS3(fileObj, duration, 'mp4');
                                                setAddTopicFile(info.fileList);
                                            };
                                        } else if (!errorFlag) {
                                            setErrorFlag(true);
                                            message.error('Please upload supported format files!');
                                            setTimeout(() => setErrorFlag(false), 2000);
                                        }
                                    } else if (!errorFlag) {
                                        setErrorFlag(true);
                                        message.error('Please upload a file to proceed!');
                                        setTimeout(() => setErrorFlag(false), 2000);
                                    }
                                };
                                reader.readAsDataURL(file);
                            }
                        }
                        }
                        customRequest={({ onSuccess }) => onSuccess("ok")}
                    >
                        <p className='ant-upload-drag-icon'>
                            <InboxOutlined />
                        </p>
                        <p className='ant-upload-text'>
                            Click or drag file to this area to upload
                        </p>
                        <p className='ant-upload-hint'>
                            Note: Upload one file per topic.  Supports .pdf, .mp4 files
                        </p>
                    </Dragger>
                    <div className='addTopicBtnDiv'>
                        <Button onClick={handleCreateTopic} disabled={loading ? true : false}
                            className='SaveBtn'
                            block type='primary'>
                            {
                                loading == true ?
                                    <Progress strokeColor={'#fff'} className="progress" percent={Percent} type="circle" />
                                    :
                                    <>
                                        Add Topic
                                    </>
                            }
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal title='Change Module' open={draggerModal}
                onCancel={() => {
                    setTrainingId('');
                    setDraggerModal(false);
                    setUploadErr('');
                    setUploadLoading(false);
                }
                }
                footer={false} >
                {
                    uploadLoading ?
                        <div className='uploadLoaderImgDiv'>
                            <img src='/Loader/sandLoader.gif' alt='' className='uploadLoaderImgClass' />
                        </div>
                        :
                        <></>
                }
                <Dragger className='moduleUploadDragger'

                    name='file'
                    action=''
                    showUploadList={false}
                    maxCount={1}
                    beforeUpload={() => false}
                    onChange={(info) => {
                        const fileObj = info.fileList.map((item) => item.originFileObj);
                        if (fileObj.length > 0) {
                            const file = fileObj[0];
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                if (draggerModal) {
                                    if (file.type === 'application/pdf') {
                                        uploadFileToS3(fileObj, null, 'pdf');
                                    } else if (file.type === 'video/mp4') {
                                        const video = document.createElement('video');
                                        video.src = e.target.result;
                                        console.log(file.type, 'fileObj')
                                        video.onloadedmetadata = () => {
                                            const duration = video.duration;
                                            uploadFileToS3(fileObj, duration, 'mp4');
                                            // setfile(fileObj);
                                        };
                                    } else if (!errorFlag) {
                                        setErrorFlag(true);
                                        message.error('Please upload supported format files!');
                                        setTimeout(() => setErrorFlag(false), 2000);
                                    }
                                } else if (!errorFlag) {
                                    setErrorFlag(true);
                                    message.error('Please upload a file to proceed!');
                                    setTimeout(() => setErrorFlag(false), 2000);
                                }
                            };
                            reader.readAsDataURL(file);
                        }
                    }
                    }
                    customRequest={({ onSuccess }) => onSuccess("ok")}
                >
                    <p className='ant-upload-drag-icon'>
                        <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>
                        Click or drag file to this area to upload
                    </p>
                    <p className='ant-upload-hint'>
                        Note: Upload one file per topic.  Supports .pdf, .mp4 files
                    </p>
                </Dragger>
                {/* <p style={{ color: 'red' }} >{uploadErr}</p> */}
            </Modal>
            <p className='back'>
                <span onClick={handleBack} style={{ color: '#4096FF', textDecoration: "underline" }}>
                    Courses
                </span>
                <span>
                    <ArrowForwardIosIcon className='arrow' />
                </span>
                <span style={{ color: '#454545', textDecoration: "" }}>
                    {courseName}
                </span>
            </p>

            <div className='manageCourseClass'>

                <h2 className='partNumber'>Manage Course - </h2>
                {
                    editCourse ?
                        <div className='partTitleInputDiv' >
                            <Input value={courseName}
                                onChange={(e) => handleCourseInputChange(e)}
                                ref={courseInputRef}
                                // onBlur={() => {
                                //     console.log('entering into blur function')
                                //     if( courseIgnoreBlur ){
                                //         setCourseIgnoreBlur(false);
                                //         return;
                                //     } else{
                                //         setSpinLoader(false);
                                //         setEditCourse(false);
                                //         findCourseName();
                                //     }
                                // }}
                                className='partTitleInput' placeholder='Change Part Title' />
                            <div className='partTitleInputActions'>
                                {
                                    spinLoader ?
                                        <img className='checkIconLoader' src='/Loader/spin.gif' alt='' />
                                        :

                                        <CheckIcon className='partTitleInputSubmit' onClick={() => handleCourseNameSave()} />
                                }
                                <ClearIcon className='partTitleInputCancel' onClick={() => {
                                    setSpinLoader(false);
                                    setEditCourse(false);
                                    findCourseName();
                                }} />
                            </div>
                        </div>
                        :
                        <h2 className='changePartTitle' onClick={() => setEditCourse(true)}>{courseName}</h2>
                }
            </div>
            <div className='statusInactiveDiv'>
                <h3 className='statusheadingClass'>Status</h3>
            <Switch onChange={handleStatusChange} loading={statusLoading} value={courseStatus} checkedChildren="Active" unCheckedChildren='InActive' className='statusSwitchClass' />
            </div>
            <p className='managecourseNoteParagraph'><b style={{ color: 'red' }} >Note</b> : Please save the changes before exit</p>
            <div className='addPartsInManageCourse'>
                <AddParts props={courseData} addPartSuccess={(e) => courseDetails(e)} />
            </div>
            <div className='coursePartsWholeDiv'>
                {
                    courseData.map(item => (
                        <Card className='courseOverallCard' key={item.partId}>
                            <div className='partTitleDiv'>
                                <h3 className='partNumber'>{item.partNumber} - </h3>
                                {
                                    editingPartId === item.partId ?
                                        <div className='partTitleInputDiv' >
                                            <Input value={partTitles[item.partId]}
                                                ref={partInputRef}
                                                // onBlur={() => {
                                                //     setSpinLoader(false);
                                                //     setEditingPartId(null);
                                                //     courseDetails();
                                                // }}
                                                onChange={(e) => handleInputChange(e, item.partId)}
                                                className='partTitleInput' placeholder='Change Part Title' />
                                            <div className='partTitleInputActions'>
                                                {
                                                    spinLoader ?
                                                        <img className='checkIconLoader' src='/Loader/spin.gif' alt='' />
                                                        :
                                                        <CheckIcon className='partTitleInputSubmit' onClick={() => handlePartSave(item.partId)} />
                                                }
                                                <ClearIcon className='partTitleInputCancel' onClick={() => {
                                                    setSpinLoader(false);
                                                    setEditingPartId(null);
                                                    courseDetails();
                                                }} />
                                            </div>
                                        </div>
                                        :
                                        <h3 className='changePartTitle' onClick={() => setEditingPartId(item.partId)} >{partTitles[item.partId] || item.partTitle} </h3>
                                }
                            </div>
                            <div className='trainingModuleChangeDiv'>
                                <div className='addTrainingModuleDiv'>
                                    <h3>Topic</h3>
                                    <Button icon={<PlusOutlined />} type='primary'
                                        onClick={() => addTrainingModule(item.partId)}
                                    >Add Topic</Button>
                                </div>
                                {
                                    item.training.map(training => (
                                        <Card className='courseTrainingCard' key={training.id}>
                                            <div key={training.id} >
                                                <Button className='deleteTrainingModuleBtn' disabled={deleteDisable} icon={<DeleteOutline />}
                                                    onClick={() => handleDeleteTrainingModule(training.id)}
                                                />
                                            </div>
                                            <div className='partTitleDiv'>
                                                <h3 className='partNumber'>Topic Name - </h3>
                                                {
                                                    editingTrainingId === training.id ?
                                                        <div className='partTitleInputDiv' >
                                                            <Input value={trainingTitles[training.id]}
                                                                ref={topicInputRef}
                                                                // onBlur={() => {
                                                                //     setSpinLoader(false);
                                                                //         setEditingTrainingId(null);
                                                                //         courseDetails();
                                                                // }}
                                                                onChange={(e) => handleTrainingInputChange(e, training.id)}
                                                                className='partTitleInput' placeholder='Change Training Title' />
                                                            <div className='partTitleInputActions'>
                                                                {
                                                                    spinLoader ?
                                                                        <img className='checkIconLoader' src='/Loader/spin.gif' alt='' />
                                                                        :
                                                                        <CheckIcon className='partTitleInputSubmit' onClick={() => handleSaveTrainingName(training.id)} />
                                                                }
                                                                {/* <CheckIcon className='partTitleInputSubmit' onClick={() => handleSaveTrainingName(training.id)} /> */}
                                                                <ClearIcon className='partTitleInputCancel' onClick={() => {
                                                                    setSpinLoader(false);
                                                                    setEditingTrainingId(null);
                                                                    courseDetails();
                                                                }} />
                                                            </div>
                                                        </div>
                                                        :
                                                        <h3 className='changePartTitle' onClick={() => setEditingTrainingId(training.id)} >{trainingTitles[training.id] || training.name} </h3>
                                                }
                                            </div>
                                            <div className='moduleButtonDiv'>
                                                <Button type='primary' onClick={() => handleShowModule(training.module, training.type)} >Preview</Button>
                                                <Button className='changeModuleBtn' onClick={() => handleShowDraggerModal(training.id)}>Change Topic</Button>
                                            </div>
                                        </Card>

                                    ))
                                }
                            </div>
                        </Card>
                    ))
                }
            </div>
            {
                saveLoading ?
                    <div className='lottieDivClass'>
                        <Lottie
                            animationData={loadingJson}
                            autoplay={true}
                            style={{ width: 60 }}
                            className='lottieJsonClass'
                        />
                    </div>
                    :
                    <Button type='primary' block onClick={handleSaveChanges} >Save Changes</Button>

            }
        </div>
    )
}

export default ManageCourse