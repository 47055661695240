import React, { useState } from 'react';
import './Settings.css';
import { Card, Row, Col, Input, Button, message, Upload, Form, notification } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import { BaseUrl } from '../Constants/Constants';
// import { useStateValue } from '../../Reducer/reducer';
import { Navigate, useNavigate } from 'react-router';

const Settings = () => {
    // const [{ UserID }, dispatch] = useStateValue();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [FullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [EmailStatus, setEmailStatus] = useState('');
    const [MobileNum, setMobileNum] = useState('');
    const [Designation, setDesignation] = useState('');
    const [password, setPassword] = useState('');
    const [cPassword, setcPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [pwdStatus, setPwdStatus] = useState('');
    const [currentPwdStatus, setCurrentPwdStatus] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const [isPasswordFormVisible, setIsPasswordFormVisible] = useState(false);
    const Profile = localStorage.getItem("Profile");
    const ParsedProfile = JSON.parse(Profile);
    const Navigate = useNavigate();

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
        

        const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
        const isValidPassword = passwordRegex.test(value);
        setPwdStatus(isValidPassword ? 'success' : 'error');
    };

    const handlePasswordChange1 = (e) => {
        const value = e.target.value;
        setcPassword(value);

        const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
        const isValidPassword = passwordRegex.test(value);
        setPwdStatus(isValidPassword ? 'success' : 'error');
    };

    const handleCurrentPasswordChange = (e) => {
        const value = e.target.value;
        setCurrentPassword(value);
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(value);
        setEmailStatus(isValidEmail ? 'success' : 'error');
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    const openNotificationWithIconSuccess = (type) => {
        api['success']({
            message: "Success!!!",
            description: type,
        });
    };

    const openNotificationWithIconWarning = (type) => {
        api['warning']({
            message: "Warning!!!",
            description: type,
        });
    };

    const HandleChange = () => {
        // Check the current password
        axios.post(`${BaseUrl}change-password/check`, { currentPassword, email: ParsedProfile.email })
            .then((res) => {
                if (res.data.valid) {
                    if (password === cPassword) {
                        let body = {
                            currentPassword: currentPassword,
                            newPassword: cPassword,
                            email: ParsedProfile.email,
                        };
                        // Update the password
                        axios
                            .post(`${BaseUrl}change-password`, body)
                            .then((res) => {
                                openNotificationWithIconSuccess('Password Changed Successfully');
                                setTimeout(() => {
                                    Navigate('/')
                                  }, 500);
                                
                            })
                            .catch((err) => {
                                openNotificationWithIconWarning('Error changing password');
                            });
                    } else {
                        setPwdStatus('error');
                    }
                } else {
                    setCurrentPwdStatus('error');
                }
            })
            .catch((err) => {
                openNotificationWithIconWarning('Error validating current password');
            });
    };

    return (
        <div className='Settings-main'>
            {contextHolder}
            <Card
                className='Settings-card'
                title="Personal Details"
                bordered={false}
                style={{ width: 800 }}
            >
                <Row>
                    <Col span={8}>
                        <Upload
                            name="avatar"
                            listType="picture-circle"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{ width: '100%' }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </Col>
                    <Col span={16}>
                        <Input
                            className='loginInput'
                            onChange={(e) => setFullName(e.target.value)}
                            placeholder="Full Name"
                        />
                        <Input
                            className='loginInput'
                            placeholder='Email ID'
                            onChange={handleEmailChange}
                            status={!email ? EmailStatus : ''}
                        />
                        <Input
                            className='loginInput'
                            placeholder='Mobile'
                            onChange={(e) => setMobileNum(e.target.value)}
                        />
                        <Input
                            className='loginInput'
                            placeholder='Designation'
                            onChange={(e) => setDesignation(e.target.value)}
                        />
                    </Col>
                </Row>
            </Card>
            <Card
                title={<span onClick={() => setIsPasswordFormVisible(!isPasswordFormVisible)}>Change Password</span>}
                className='Settings-card'
                bordered={false}
                style={{ width: 800 }}
            >
                {isPasswordFormVisible && (
                    <Row style={{ marginTop: 20 }}>
                        <Col span={16}>
                            <Form.Item className='PasswordForm' hasFeedback validateStatus={currentPwdStatus}>
                                <Input.Password
                                    className='loginInput'
                                    style={{ marginTop: 0 }}
                                    placeholder='Current Password'
                                    type='password'
                                    onChange={handleCurrentPasswordChange}
                                    status={!currentPassword ? currentPwdStatus : ''}
                                />
                            </Form.Item>
                            <Form.Item className='PasswordForm' hasFeedback validateStatus={pwdStatus}>
                                <Input.Password
                                    id='passwordInputField'
                                    className='loginInput'
                                    style={{ marginTop: 0 }}
                                    placeholder='New Password'
                                    type='password'
                                    onChange={handlePasswordChange}
                                    status={!password ? pwdStatus : ''}
                                />
                            </Form.Item>
                            <Form.Item className='PasswordForm' hasFeedback validateStatus={pwdStatus}>
                                <Input.Password
                                    id='passwordInputField'
                                    className='loginInput'
                                    style={{ marginTop: 0 }}
                                    placeholder='Confirm Password'
                                    type='password'
                                    onChange={handlePasswordChange1}
                                    status={!password ? pwdStatus : ''}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button onClick={HandleChange} type='primary'>
                                    Save
                                </Button>
                                <Button className='cancel-button' onClick={() => setIsPasswordFormVisible(false)}>
                                    Cancel
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                )}
            </Card>
        </div>
    );
};

export default Settings;
